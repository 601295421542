import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  acceptUsersChatRequestActions,
  acceptUsersChatsRequestActionTypes,
  addBlockedUserActions,
  addBlockedUserActionTypes,
  cancelRejectUsersChatRequestActions,
  cancelRejectUsersChatsRequestActionTypes,
  closeUsersChatRequestActions,
  closeUsersChatsRequestActionTypes,
  createUserToAdminChatRequestActions,
  createUserToAdminChatsRequestActionTypes,
  deleteBlockedUserActions,
  deleteBlockedUserActionTypes,
  deleteUserToUserChatActions,
  deleteUserToUserChatActionTypes,
  deleteUserToUserChatMessageActions,
  deleteUserToUserChatMessageActionTypes,
  getBlockedUsersActions,
  getBlockedUsersActionTypes,
  getReceivedUsersChatsRequestsActions,
  getReceivedUsersChatsRequestsActionTypes,
  getRejectedIncomingUsersChatsActions,
  getRejectedIncomingUsersChatsActionTypes,
  getRejectedOutgoingUsersChatsActions,
  getRejectedOutgoingUsersChatsActionTypes,
  getUsersChatMessagesActions,
  getUsersChatMessagesActionTypes,
  getUsersChatRequestsActions,
  getUsersChatRequestsActionTypes,
  getUsersChatsActions,
  getUsersChatsActionTypes,
  getUserToAdminChatDetailsActions,
  getUserToAdminChatDetailsActionTypes,
  getUserToAdminChatIsOnlineActions,
  getUserToAdminChatIsOnlineActionTypes,
  getUserToAdminChatMessagesActions,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatStatusActions,
  getUserToAdminChatStatusActionTypes,
  rejectUsersChatRequestActions,
  rejectUsersChatsRequestActionTypes,
  resetBlockedUsersActions,
  resetBlockedUsersActionTypes,
  resetUserToAdminChatDetailsActions,
  resetUserToAdminChatDetailsActionTypes,
} from './types';
import MessageCenterService from 'MessageCenter/services/MessageCenterService';
import {
  IServerUsersChatMessageRequest,
  IServerUserToAdminChatMessageRequest,
} from 'MessageCenter/services/types/messageCenter';

export const getReceivedUsersChatsRequests =
  (): ActionResult<IAppState, void, getReceivedUsersChatsRequestsActions> => async (dispatch) => {
    try {
      dispatch(action(getReceivedUsersChatsRequestsActionTypes.REQUEST));
      const res = await MessageCenterService.getReceivedUsersChatsRequests();
      dispatch(action(getReceivedUsersChatsRequestsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getReceivedUsersChatsRequestsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUsersChats = (): ActionResult<IAppState, void, getUsersChatsActions> => async (dispatch) => {
  try {
    dispatch(action(getUsersChatsActionTypes.REQUEST));
    const res = await MessageCenterService.getUsersChats();
    dispatch(action(getUsersChatsActionTypes.SUCCESS, res.data));
  } catch (error) {
    dispatch(action(getUsersChatsActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const getUsersChatRequests =
  (): ActionResult<IAppState, void, getUsersChatRequestsActions> => async (dispatch) => {
    try {
      dispatch(action(getUsersChatRequestsActionTypes.REQUEST));
      const res = await MessageCenterService.getUsersChatRequests();
      dispatch(action(getUsersChatRequestsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUsersChatRequestsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getRejectedIncomingChatRequests =
  (): ActionResult<IAppState, void, getRejectedIncomingUsersChatsActions> => async (dispatch) => {
    try {
      dispatch(action(getRejectedIncomingUsersChatsActionTypes.REQUEST));
      const res = await MessageCenterService.getRejectedIncomingChatRequests();
      dispatch(action(getRejectedIncomingUsersChatsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getRejectedIncomingUsersChatsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getRejectedOutgoingChatRequests =
  (): ActionResult<IAppState, void, getRejectedOutgoingUsersChatsActions> => async (dispatch) => {
    try {
      dispatch(action(getRejectedOutgoingUsersChatsActionTypes.REQUEST));
      const res = await MessageCenterService.getRejectedOutgoingChatRequests();
      dispatch(action(getRejectedOutgoingUsersChatsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getRejectedOutgoingUsersChatsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUsersChatMessages =
  (params: IServerUsersChatMessageRequest): ActionResult<IAppState, void, getUsersChatMessagesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUsersChatMessagesActionTypes.REQUEST));
      const res = await MessageCenterService.getUsersChatMessages(params);
      dispatch(action(getUsersChatMessagesActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUsersChatMessagesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatMessages =
  (params: IServerUserToAdminChatMessageRequest): ActionResult<IAppState, void, getUserToAdminChatMessagesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatMessagesActionTypes.REQUEST));
      const res = await MessageCenterService.getUserToAdminChatMessages(params);
      dispatch(action(getUserToAdminChatMessagesActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUserToAdminChatMessagesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const acceptUsersChatRequest =
  (chatRequestId: number): ActionResult<IAppState, void, acceptUsersChatRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(acceptUsersChatsRequestActionTypes.REQUEST));
      await MessageCenterService.acceptUsersChatRequest(chatRequestId);
      dispatch(action(acceptUsersChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(acceptUsersChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const closeUsersChatRequest =
  (chatRequestId: number): ActionResult<IAppState, void, closeUsersChatRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(closeUsersChatsRequestActionTypes.REQUEST));
      await MessageCenterService.closetUsersChatRequest(chatRequestId);
      dispatch(action(closeUsersChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(closeUsersChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const rejectUsersChatRequest =
  (chatRequestId: number): ActionResult<IAppState, void, rejectUsersChatRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(rejectUsersChatsRequestActionTypes.REQUEST));
      await MessageCenterService.rejectUsersChatRequest(chatRequestId);
      dispatch(action(rejectUsersChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(rejectUsersChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const cancelRejectUsersChatRequest =
  (chatRequestId: number): ActionResult<IAppState, void, cancelRejectUsersChatRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(cancelRejectUsersChatsRequestActionTypes.REQUEST));
      await MessageCenterService.cancelRejectUsersChatRequest(chatRequestId);
      dispatch(action(cancelRejectUsersChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(cancelRejectUsersChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatDetails =
  (): ActionResult<IAppState, void, getUserToAdminChatDetailsActions> => async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatDetailsActionTypes.REQUEST));
      const res = await MessageCenterService.getUserToAdminChatDetails();
      dispatch(action(getUserToAdminChatDetailsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUserToAdminChatDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUserToAdminChatRequest =
  (): ActionResult<IAppState, void, createUserToAdminChatRequestActions> => async (dispatch) => {
    try {
      dispatch(action(createUserToAdminChatsRequestActionTypes.REQUEST));
      await MessageCenterService.createUserToAdminChatRequest();
      dispatch(action(createUserToAdminChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createUserToAdminChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getBlockedUsers = (): ActionResult<IAppState, void, getBlockedUsersActions> => async (dispatch) => {
  try {
    dispatch(action(getBlockedUsersActionTypes.REQUEST));
    const res = await MessageCenterService.getBlockedUsers();
    dispatch(action(getBlockedUsersActionTypes.SUCCESS, res.data));
  } catch (error) {
    dispatch(action(getBlockedUsersActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const addBlockedUser =
  (userId: number): ActionResult<IAppState, void, addBlockedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(addBlockedUserActionTypes.REQUEST));
      await MessageCenterService.addBlockedUser(userId);
      dispatch(action(addBlockedUserActionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(addBlockedUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteBlockedUser =
  (userId: number): ActionResult<IAppState, void, deleteBlockedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteBlockedUserActionTypes.REQUEST));
      await MessageCenterService.deleteBlockedUser(userId);
      dispatch(action(deleteBlockedUserActionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(deleteBlockedUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatIsOnline =
  (): ActionResult<IAppState, void, getUserToAdminChatIsOnlineActions> => async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatIsOnlineActionTypes.REQUEST));
      const res = await MessageCenterService.getUserToAdminChatIsOnline();
      dispatch(action(getUserToAdminChatIsOnlineActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserToAdminChatIsOnlineActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatStatus =
  (): ActionResult<IAppState, void, getUserToAdminChatStatusActions> => async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatStatusActionTypes.REQUEST));
      const res = await MessageCenterService.getUserToAdminChatStatus();
      dispatch(action(getUserToAdminChatStatusActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserToAdminChatStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteUserToUserChat =
  (chatId: number): ActionResult<IAppState, void, deleteUserToUserChatActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteUserToUserChatActionTypes.REQUEST));
      await MessageCenterService.deleteUserToUserChat(chatId);
      dispatch(action(deleteUserToUserChatActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteUserToUserChatActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteUserToUserChatMessage =
  (messageId: number): ActionResult<IAppState, void, deleteUserToUserChatMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteUserToUserChatMessageActionTypes.REQUEST));
      await MessageCenterService.deleteUserToUserChatMessage(messageId);
      dispatch(action(deleteUserToUserChatMessageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteUserToUserChatMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetBlockedUsers = (): ActionResult<IAppState, void, resetBlockedUsersActions> => (dispatch) => {
  dispatch(action(resetBlockedUsersActionTypes.RESET));
};

export const resetUserToAdminChatDetails =
  (): ActionResult<IAppState, void, resetUserToAdminChatDetailsActions> => (dispatch) => {
    dispatch(action(resetUserToAdminChatDetailsActionTypes.RESET));
  };
