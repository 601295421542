import {ADMIN_NAVIGATION_ROUTES, BUSINESS_PORTAL_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import {INavigation} from 'Common/models/INavigation';

const navLinksItems: INavigation[] = [
  {label: 'Dashboard', value: '/'},
  {label: 'My profile', value: '/user/user-profile/:userId/horses', needUserId: true},
  {label: 'Build-a-Horse', value: '/build-a-horse'},
  {label: 'Find-a-Horse', value: '/find-a-horse'},
  {label: 'Home', value: 'http://www.etalondx.com/', isExternalUrl: true},
];

const userMenuLinks: INavigation[] = [
  {
    value: '/message-center',
    label: 'Message center',
    isMenuItemAsLink: true,
    divided: true,
  },
  {
    value: '/notifications',
    label: 'Notifications',
    isMenuItemAsLink: true,
  },
  {
    value: '/account-details/personal-details',
    label: 'Edit profile',
    isMenuItemAsLink: true,
    divided: true,
  },
  {
    value: '/account-details/privacy-settings',
    label: 'Change visibility',
    isMenuItemAsLink: true,
  },
  {
    value: '/account-details/subscriptions',
    label: 'Subscriptions',
    isMenuItemAsLink: true,
  },
  {
    value: '/account-details/notification-settings',
    label: 'Notification settings',
    isMenuItemAsLink: true,
  },
  {
    value: '/account-details/change-password',
    label: 'Change password',
    isMenuItemAsLink: true,
  },
];

function getMenuNavLinks(links: INavigation[], currentUserId?: number, currentUserPermissions?: string[]) {
  return links.map(({label, needUserId, value, isExternalUrl, isMenuItemAsLink, divided}) => {
    return {
      label,
      value: needUserId ? value?.replace(':userId', `${currentUserId}`) : value,
      isExternalUrl,
      isMenuItemAsLink: isMenuItemAsLink !== undefined ? isMenuItemAsLink : false,
      divided,
    };
  });
}

export function getUserMenuLinks(currentUserId?: number): INavigation[] {
  return getMenuNavLinks(userMenuLinks, currentUserId);
}

export function getHeadersNavLinks(currentUserId?: number, currentUserPermissions?: string[]): INavigation[] {
  return getMenuNavLinks(navLinksItems, currentUserId, currentUserPermissions);
}

export function getHeadersNavMenu(currentUserPermissions?: string[], isDevelopmentAdmin?: boolean): INavigation[] {
  return ADMIN_NAVIGATION_ROUTES.filter(
    (item) =>
      (!item.onlyForDevelopmentAdmin || (item.onlyForDevelopmentAdmin && isDevelopmentAdmin)) &&
      item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
  ).map(({value, label}) => {
    return {value, label, isMenuItemAsLink: true};
  });
}

export function getBusinessPortalHeadersNavMenu(currentUserPermissions?: string[]): INavigation[] {
  const routes = BUSINESS_PORTAL_NAVIGATION_ROUTES.filter((route) => !!route.icon && !!route.order)
    .sort((a, b) => a.order! - b.order!)
    .filter(
      (item) =>
        !item.permissions || item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
    );

  const bottomRoutes = BUSINESS_PORTAL_NAVIGATION_ROUTES.filter((route) => !!route.icon && !!route.orderBottom)
    .sort((a, b) => a.orderBottom! - b.orderBottom!)
    .filter(
      (item) =>
        !item.permissions || item.permissions?.every((permission) => currentUserPermissions?.includes(permission))
    );

  return routes.concat(bottomRoutes);
}
