import React, {memo} from 'react';

import {ChatRoot, ChatText} from '../styled';

function EmptyChat() {
  return (
    <ChatRoot className="d-flex flex-column">
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>To start conversation click user name on the left panel.</ChatText>
      </div>
    </ChatRoot>
  );
}

export default memo(EmptyChat);
