import {IModule} from 'redux-dynamic-modules';

import {IUserMessageCenterState} from './types';
import {reducer} from './reducer';

export interface IUserMessageCenterModuleState {
  userMessageCenter: IUserMessageCenterState;
}

export const MessageCenterModule: IModule<IUserMessageCenterModuleState> = {
  id: 'userMessageCenter',
  reducerMap: {
    userMessageCenter: reducer,
  },
};
