import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import {ToastOptions} from 'react-toastify';

import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';
import {ProfileType} from 'Common/constants/ProfileType';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import {parseHorseHeight} from 'Common/helpers/parseHorseHeight';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {PageError} from 'Common/components/StyledComponents/StyledComponents';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {Label} from 'HorseProfile/components/shared/StyledComponents';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {VisitorType} from 'Common/constants/VisitorType';
import Owner from './parts/Owner/Owner';
import Parents from './parts/Parents';
import AlleleSummary from './parts/AlleleSummary';
import HorseValueTile from './parts/HorseValueTile';
import OrderInfoHorse from './parts/OrderInfoHorse';
import HorseCharacteristics from './parts/HorseCharacteristics/HorseCharacteristics';
import HorseLocation from './parts/HorseLocation';
import HorseReport from './parts/HorseReport';
import BreedPanel from './parts/BreedPanel';
import {horseProfileVisitorDistributor, IDispatch, IState} from './visitorDistributor';
import HorseProfileHeader from './parts/ProfileHeader/HorseProfileHeader';
import {getCommonErrors, getErrorCode} from 'Common/helpers/ErrorHelper';
import HorseProfileTypeSwitcher, {IHorseProfileTypeSwitcherProps} from './parts/HorseProfileTypeSwitcher';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import AncestryHorseProfile from './parts/Ancestry/AncestryHorseProfile';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';
import Transfer from './parts/Transfer/Transfer';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';
import {useSystemNotification} from 'SignalR/hooks/useSystemNotification';
import {NotificationEventType} from 'Notifications/const/NotificationEventType';
import {useToast} from 'Common/helpers/hooks/useToast';

const ACCESS_DENIED_ERROR_CODE = 'AccessDenied';

const parseToString = (items: Array<{name: string}>) => (items.length < 1 ? '-' : items.map((x) => x.name).join(', '));

const HorseHeightIcon: IconProps = {name: IconName.HorseHeight, size: 56};

const toastOptions: ToastOptions = {autoClose: false};

const HeaderBg = styled.div`
  width: 100%;
  height: 964px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${ColorPalette.black1};

  @media ${breakpoints.sm} {
    height: 723px;
  }
  @media ${breakpoints.md} {
    height: 300px;
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1116px;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
  z-index: 1;
  padding: 0 16px;

  @media ${breakpoints.md} {
    padding: 0 40px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContentLayout = styled.div`
  width: 100%;
  min-width: 0;
  @media ${breakpoints.md} {
    flex-grow: 1;
  }
`;

const HorseContent = styled.div`
  padding-bottom: 32px;

  @media ${breakpoints.sm} {
    padding: 0 16px 56px;
  }
`;

const SidebarLayout = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media ${breakpoints.sm} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
  }

  @media ${breakpoints.md} {
    flex-direction: column;
    justify-content: start;
    width: 372px;
    margin-left: 24px;
    margin-top: 0;
  }
`;

const SubSidebar = styled.div`
  width: 100%;
  margin-right: 14px;
`;

const OwnerComments = styled.div`
  line-height: 175%;
  color: ${ColorPalette.black1};
  font-size: ${Typography.size.size13};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size16};
  }
  @media ${breakpoints.md} {
    margin-top: 24px;
  }
`;

const ParamsWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const AlleleSummaryWrapper = styled.div`
  margin-top: 24px;
`;

const HorseCharacteristicsWrapper = styled.div`
  margin-top: 24px;
`;

const HorseDescription = styled.span`
  word-break: break-word;
`;

const SimpleText = styled.div`
  word-break: break-word;
  font-family: ${Typography.family.roboto};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
`;

const PreclinicalNotes = styled.div`
  margin-top: 10px;
`;

interface IExternalProps {
  disableBlackPillowForHeader?: boolean;
  type: VisitorType;
}

type RouteParams = {id: string};

type AllProps = IExternalProps & IState & IDispatch;

function HorseProfile(props: AllProps) {
  const {
    type: visitorType,
    disableBlackPillowForHeader,
    horse,
    horseMedia,
    horseMediaLoading,
    horseLastOrder,
    getHorseDetails,
    getHorseMedia,
    getHorseParentage,
    getHorseLastOrder,
    horseLastOrderLoading,
    horseLoading,
    getHorse,
    resetHorse,
    getGenotypeGroups,
    genotypesLoading,
    updateProfileType,
    updateAncestryProfileType,
    currentUserId,
    genotypeGroups,
    abilities,
    abilitiesLoading,
    getHorseAbilities,
    parantageReport,
    horseParantageReportLoading,
    getHorseParantageReport,
    horseBreedPanels,
    horseBreedPanelLoading,
    getHorseBreedPanel,
    resetHorseDetails,
    resetHorseMedia,
    resetHorseParentage,
    resetGenotypeGroups,
    resetHorseParantageReport,
    resetHorseAbilities,
    resetHorseBreedPanel,
    resetHorseLastOrder,
  } = props;

  const {id} = useParams<RouteParams>();
  const horseId = Number(id);

  const isSimpleUser = visitorType === VisitorType.User;
  const isAdmin = visitorType === VisitorType.Admin;
  const isAssociationAdmin = visitorType === VisitorType.AssociationAdmin;
  const isBusinessPortalProfile = [VisitorType.AssociationAdmin, VisitorType.AssociationEmployee].includes(visitorType);

  const navigate = useNavigate();
  const location = useLocation();
  const {hasPermission} = usePermissions();
  const {isDesktop, isTablet, isMobile} = useMediaQuery();
  const {addToast} = useToast();
  const [isTriggeredUpdateProfileType, setIsTriggeredUpdateProfileType] = useState(false);
  const [isTriggeredUpdateAncestryProfileType, setIsTriggeredUpdateAncestryProfileType] = useState(false);
  const [ownerId, setOwnerId] = useState<number>(0);
  const [onlineReportType, setOnlineReportType] = useState<OnlineReportType>(OnlineReportType.User);
  const [isAncestryError, setIsAncestryError] = useState<boolean>();
  const [isUpdateHorseByNotification, setIsUpdateHorseByNotification] = useState(false);
  const [isUpdateLastOrderByNotification, setIsUpdateLastOrderByNotification] = useState(false);

  const isOwnHorse = currentUserId === ownerId;

  useEffect(() => {
    if (props.horse?.id !== horseId) {
      reloadHorse();
    }
  }, [location]);

  useEffect(() => {
    if (
      isSimpleUser &&
      horse &&
      !horse?.isGeneticDataHidden &&
      !isTriggeredUpdateProfileType &&
      !isTriggeredUpdateAncestryProfileType
    ) {
      getGenotypeGroups(horseId);
      getHorseAbilities(horseId);
      getHorseBreedPanel(horseId);
    }
  }, [
    getGenotypeGroups,
    getHorseAbilities,
    getHorseBreedPanel,
    horse,
    horseId,
    isSimpleUser,
    isTriggeredUpdateAncestryProfileType,
    isTriggeredUpdateProfileType,
  ]);

  useEffect(() => {
    if (
      isSimpleUser &&
      horse &&
      !horse?.isGeneticDataHidden &&
      !isTriggeredUpdateProfileType &&
      !isTriggeredUpdateAncestryProfileType
    ) {
      currentUserId === ownerId && getHorseParantageReport(horseId);
    }
  }, [
    currentUserId,
    getHorseParantageReport,
    horse,
    horseId,
    isSimpleUser,
    isTriggeredUpdateAncestryProfileType,
    isTriggeredUpdateProfileType,
    ownerId,
  ]);

  useEffect(() => {
    if (!isSimpleUser) {
      getGenotypeGroups(horseId);
      getHorseAbilities(horseId);
      getHorseBreedPanel(horseId);
      getHorseParantageReport(horseId);
    }
  }, [getGenotypeGroups, getHorseAbilities, getHorseBreedPanel, getHorseParantageReport, horseId, isSimpleUser]);

  useEffect(() => {
    // Set OnlineReportType manually for getting Ancestry data from Online service
    let onlineReportTypeDef = OnlineReportType.User;
    switch (visitorType) {
      case VisitorType.Admin: {
        onlineReportTypeDef = OnlineReportType.Admin;
        break;
      }
      case VisitorType.AssociationAdmin: {
        onlineReportTypeDef = OnlineReportType.AdminAssociation;
        break;
      }
      case VisitorType.AssociationEmployee: {
        onlineReportTypeDef = OnlineReportType.Association;
        break;
      }
    }
    setOnlineReportType(onlineReportTypeDef);
    setOnlineReportTypeToStorage(onlineReportTypeDef);
  }, [visitorType]);

  const reloadHorse = useCallback(() => {
    resetHorse();
    resetHorseDetails();
    resetHorseMedia();
    resetHorseParentage();
    resetGenotypeGroups();
    resetHorseParantageReport();
    resetHorseAbilities();
    resetHorseBreedPanel();
    resetHorseLastOrder();
    getHorse(horseId);
    getHorseDetails(horseId);
    getHorseMedia(horseId, isAdmin);
    getHorseParentage(horseId);
  }, [
    resetHorse,
    resetHorseDetails,
    resetHorseMedia,
    resetHorseParentage,
    resetGenotypeGroups,
    resetHorseParantageReport,
    resetHorseAbilities,
    resetHorseBreedPanel,
    resetHorseLastOrder,
    getHorse,
    horseId,
    getHorseDetails,
    getHorseMedia,
    isAdmin,
    getHorseParentage,
  ]);

  const reloadHorseLastReport = useCallback(() => {
    if ((isSimpleUser || isBusinessPortalProfile) && isOwnHorse) {
      getHorseLastOrder(horseId);
    }
  }, [getHorseLastOrder, horseId, isBusinessPortalProfile, isOwnHorse, isSimpleUser]);

  useEffect(() => {
    reloadHorse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadHorseLastReport();
  }, [reloadHorseLastReport]);

  const {details: orderReloadNotificationDetails} = useSystemNotification({
    systemNotificationTypes: [
      NotificationEventType.OrderPlaced,
      NotificationEventType.PaymentConfirmed,
      NotificationEventType.SampleReceived,
      NotificationEventType.NewSampleRequested,
    ],
    onSuccess: () => setIsUpdateLastOrderByNotification(true),
  });

  const {details: horseReloadNotificationDetails} = useSystemNotification({
    systemNotificationTypes: [
      NotificationEventType.HorseTransferredFromUser,
      NotificationEventType.HorseTransferredToUser,
      NotificationEventType.ResultsReady,
      NotificationEventType.ResultsUpdated,
      NotificationEventType.ReportUpdated,
    ],
    onSuccess: () => setIsUpdateHorseByNotification(true),
  });

  useEffect(() => {
    if (
      isUpdateHorseByNotification &&
      horseReloadNotificationDetails &&
      horseReloadNotificationDetails.horseId !== horseId
    ) {
      return;
    }
    reloadHorse();

    if (horseReloadNotificationDetails && horseReloadNotificationDetails.horseId === horseId) {
      addToast('Horse data was updated.', 'info', toastOptions);
    }

    setIsUpdateHorseByNotification(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateHorseByNotification]);

  useEffect(() => {
    if (
      isUpdateLastOrderByNotification &&
      orderReloadNotificationDetails &&
      orderReloadNotificationDetails.horseId !== horseId
    ) {
      return;
    }
    reloadHorseLastReport();
    setIsUpdateLastOrderByNotification(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLastOrderByNotification]);

  const getOwnerId = useCallback((id: number) => {
    setOwnerId(id);
  }, []);

  const isLoading = [
    horseLoading,
    horseLastOrderLoading,
    genotypesLoading,
    abilitiesLoading,
    horseBreedPanelLoading,
  ].some((i) => i.isRequesting);

  const diagnosticErrorCodes = [
    genotypesLoading.error && getErrorCode(genotypesLoading.error),
    abilitiesLoading.error && getErrorCode(abilitiesLoading.error),
  ];

  const horseLastOrderError = getCommonErrors(horseLastOrderLoading.error);

  const onUpdateProfileType = useCallback(() => {
    setIsTriggeredUpdateProfileType(true);
    updateProfileType({
      horseId,
      profileType: horse?.profileType === ProfileType.Private ? ProfileType.Public : ProfileType.Private,
    });
  }, [horse, horseId, updateProfileType]);

  const onUpdateAncestryProfileType = useCallback(() => {
    setIsTriggeredUpdateAncestryProfileType(true);
    updateAncestryProfileType({
      horseId,
      profileType: horse?.ancestryProfileType === ProfileType.Private ? ProfileType.Public : ProfileType.Private,
    });
  }, [horse, horseId, updateAncestryProfileType]);

  const onAncestryHasError = useCallback((isError: boolean) => {
    setIsAncestryError(isError);
  }, []);

  useOnSuccessCommunication(horseLoading, () => {
    if (!horse) {
      navigate('/not-found', {replace: true});
    }
  });

  if (horseLoading.error) {
    return <PageError>Error on loading horse data</PageError>;
  }

  const isHorseFirstLoad = isLoading && horse?.id !== horseId;
  if (isHorseFirstLoad || !horse) {
    return <Loading />;
  }

  const isHorseUpdating = isLoading;

  const {height, heightUnits, temperament, availabilityCriteria} = horse;

  const disciplines = parseToString(horse.disciplines || []);
  const markings = parseToString(horse.markings || []);
  const colors = parseToString(horse.colors || []);
  const horseHeight = height && heightUnits ? parseHorseHeight(height, heightUnits) : '-';

  const hasAvailablePermission = hasPermission(Permission.BuildHorse);

  const isAccessDenied = diagnosticErrorCodes.some((x) => x === ACCESS_DENIED_ERROR_CODE);

  const horseProfileTypeSwitcherProps: IHorseProfileTypeSwitcherProps = {
    isDesktop: true,
    isOwnHorse,
    profileType: horse.profileType,
    ancestryProfileType: horse.ancestryProfileType,
    onUpdateProfileType,
    onUpdateAncestryProfileType,
  };

  const renderHorseHeader = () => {
    return (
      <HorseProfileHeader
        horse={horse}
        horseMedia={horseMedia}
        horseMediaLoading={horseMediaLoading}
        horseLoading={horseLoading}
        onSuccessEdit={reloadHorse}
        isOwnHorse={isOwnHorse}
        hasAccessToBah={hasAvailablePermission}
        criteriaToBah={availabilityCriteria}
      />
    );
  };

  const renderHorseInfo = () => {
    return (
      <HorseContent>
        {isDesktop && <HorseProfileTypeSwitcher {...horseProfileTypeSwitcherProps} />}
        {horse.comments && (
          <OwnerComments>
            <Scrollbar maxHeight={200}>
              <HorseDescription>{horse.comments}</HorseDescription>
            </Scrollbar>
          </OwnerComments>
        )}

        {!isAccessDenied && (
          <AlleleSummaryWrapper>
            <AlleleSummary genotypeGroups={genotypeGroups} isAncestryError={isAncestryError} />
          </AlleleSummaryWrapper>
        )}

        {horseBreedPanels
          .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
          .map((panel, index) => (
            <BreedPanel key={index} horseBreedPanel={panel} />
          ))}

        {!isAccessDenied && (
          <HorseCharacteristicsWrapper>
            <HorseCharacteristics
              temperament={temperament || 0}
              abilities={abilities}
              isShowTemperament={!isBusinessPortalProfile}
            />
          </HorseCharacteristicsWrapper>
        )}

        <AncestryHorseProfile
          horseId={horseId.toString()}
          reportType={onlineReportType}
          onHasError={onAncestryHasError}
        />

        <ParamsWrapper>
          <HorseValueTile label="Height" value={horseHeight} isHalfWidth={!isMobile} icon={HorseHeightIcon} />
          <HorseValueTile label="Discipline" value={disciplines} isHalfWidth={!isMobile} />
          <HorseValueTile label="Color" value={colors} isHalfWidth={!isMobile} />
          <HorseValueTile label="Markings" value={markings} isHalfWidth={!isMobile} />
          <HorseValueTile label="Passport" value={horse?.passportNumber || '-'} isHalfWidth={!isMobile} />
          <HorseValueTile label="Microchip" value={horse?.microchipNumber || '-'} isHalfWidth={!isMobile} />
        </ParamsWrapper>
        {(isOwnHorse || hasPermission(Permission.Admin)) && (
          <PreclinicalNotes className="d-flex flex-column">
            <Label>Preclinical notes</Label>
            <SimpleText>{horse.preclinicalNotes || '-'}</SimpleText>
          </PreclinicalNotes>
        )}
      </HorseContent>
    );
  };

  const renderSidebar = () => {
    const sidebarOrder: Record<'reports' | 'orderInfo' | 'map' | 'owner', string> = isDesktop
      ? {
          reports: 'order-1',
          orderInfo: 'order-2',
          map: 'order-3',
          owner: 'order-4',
        }
      : {
          reports: 'order-1',
          orderInfo: 'order-2',
          owner: 'order-3',
          map: 'order-4 w-100',
        };

    const renderOrderInfo = () => {
      return (
        (isSimpleUser || isBusinessPortalProfile) &&
        isOwnHorse && (
          <OrderInfoHorse
            order={horseLastOrder}
            horseId={horse.id}
            className={sidebarOrder.orderInfo}
            ownerId={ownerId}
            visitorType={visitorType}
            orderLoadingError={horseLastOrderError}
          />
        )
      );
    };

    const renderOwner = () => {
      return (
        <Owner
          horseId={horse.id}
          className={sidebarOrder.owner}
          type={visitorType}
          getOwnerId={getOwnerId}
          isOwnHorse={isOwnHorse}
        />
      );
    };

    const renderHorseReports = () => {
      const isReportsAvailable =
        ((isSimpleUser || isBusinessPortalProfile) && isOwnHorse) || isAdmin || isAssociationAdmin;

      return (
        isReportsAvailable && (
          <HorseReport
            className={sidebarOrder.reports}
            parantageReport={parantageReport}
            horseName={horse.name}
            horseId={horse.id}
            isGeneticDataHidden={horse.isGeneticDataHidden}
            isAncestryReportHidden={horse.isAncestryReportHidden}
            isReportsLoading={horseParantageReportLoading.isRequesting}
            visitorType={visitorType}
          />
        )
      );
    };

    return (
      <SidebarLayout>
        {!isTablet && (
          <>
            {renderHorseReports()}
            {renderOrderInfo()}
            {renderOwner()}
          </>
        )}
        {isTablet && (
          <SubSidebar className="d-flex flex-column">
            {renderHorseReports()}
            {renderOrderInfo()}
            {renderOwner()}
          </SubSidebar>
        )}
        <HorseLocation
          className={sidebarOrder.map}
          position={horse?.location}
          address={horse?.address ? getStringifyAddress(horse?.address) : ''}
        />
        {isDesktop && <Parents parents={horse.parentage} className="order-4" />}
        {isOwnHorse && !isBusinessPortalProfile && (
          <Transfer avatar={horse.avatar} horseId={horse.id} horseName={horse.name} className="order-5"></Transfer>
        )}
      </SidebarLayout>
    );
  };

  return (
    <>
      <Helmet>
        <title>Horse profile</title>
      </Helmet>
      <div className="d-flex flex-column position-relative">
        {!disableBlackPillowForHeader && <HeaderBg />}
        {isHorseUpdating && <Loading />}
        {isDesktop ? (
          <LayoutWrapper>
            <ContentLayout className="d-flex flex-column">
              {renderHorseHeader()}
              {renderHorseInfo()}
            </ContentLayout>
            {renderSidebar()}
          </LayoutWrapper>
        ) : isTablet ? (
          <LayoutWrapper>
            {renderHorseHeader()}
            <HorseProfileTypeSwitcher {...horseProfileTypeSwitcherProps} isDesktop={false} />
            {renderSidebar()}
            {renderHorseInfo()}
          </LayoutWrapper>
        ) : (
          isMobile && (
            <>
              {renderHorseHeader()}
              <LayoutWrapper>
                <HorseProfileTypeSwitcher {...horseProfileTypeSwitcherProps} isDesktop={false} />
                {renderSidebar()}
                {renderHorseInfo()}
              </LayoutWrapper>
            </>
          )
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state: IAppState, externalProps: IExternalProps) =>
  horseProfileVisitorDistributor[externalProps.type!].state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, undefined, any>, externalProps: IExternalProps) =>
  bindActionCreators(horseProfileVisitorDistributor[externalProps.type!].dispatch as any, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HorseProfile) as unknown as (props: IExternalProps) => JSX.Element;
const Exported = (externalProps: IExternalProps) => {
  const visitorType = externalProps.type;
  const modules = horseProfileVisitorDistributor[visitorType].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} type={visitorType!} />
    </DynamicModuleLoader>
  );
};

export default Exported;
