import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {
  actions as associationOrdersAction,
  selectors as associationOrdersSelectors,
} from 'Admin/AdminAssociations/store/adminOrders/index';
import {AdminAssociationOrdersModule} from 'Admin/AdminAssociations/store/adminOrders/adminAssociationOrdersModule';
import {
  actions as paymentDetailsActions,
  selectors as paymentDetailsSelectors,
} from 'Admin/AdminAssociations/store/adminPaymentUpdate/index';
import {AdminAssociationPaymentUpdateModule} from 'Admin/AdminAssociations/store/adminPaymentUpdate/adminAssociationPaymentUpdateModule';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/index';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';

type IConnected = ConnectedProps<typeof connector>;

export type IWithOrderActionsProps = IConnected;

function withOrderActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  orderDeleting: associationOrdersSelectors.selectCommunication(state, 'orderDeleting'),
  setSampleStatusRequesting: associationOrdersSelectors.selectCommunication(state, 'setSampleStatusRequesting'),
  newSampleRequesting: associationOrdersSelectors.selectCommunication(state, 'newSampleRequesting'),
  downloadReportsRequesting: associationOrdersSelectors.selectCommunication(state, 'downloadReportsRequesting'),
  downloadFormRequesting: associationOrdersSelectors.selectCommunication(state, 'downloadSubmissionFormRequesting'),
  paymentDetailsUpdating: paymentDetailsSelectors.selectCommunication(state, 'paymentDetailsUpdating'),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
});

const mapDispatchToProps = {
  deleteOrder: associationOrdersAction.deleteOrder,
  setSampleStatus: associationOrdersAction.setSampleStatus,
  requestNewSample: associationOrdersAction.requestNewSample,
  downloadReports: associationOrdersAction.downloadReports,
  downloadSubmissionForm: associationOrdersAction.downloadOrderSubmissionForm,
  updatePaymentDetails: paymentDetailsActions.updatePaymentDetails,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithOrderActionsModules() {
  return [AdminAssociationOrdersModule, AdminAssociationPaymentUpdateModule, AdminOnlineReportModule];
}

export {getWithOrderActionsModules, withOrderActions as default};
