import React, {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {SubTitle, SubTitleContainer} from '../styled';
import ChatActions, {ChatActionsProps} from './Actions/ChatActions';
import {breakpoints} from 'Common/constants/Breakpoints';

const Header = styled(SubTitleContainer)`
  padding: 0 16px 0 48px;

  @media ${breakpoints.sm} {
    padding: 0 24px;
  }
`;

const Titles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.sm} {
    display: block;
  }
`;

const SubTitleHeader = styled(SubTitle)`
  font-size: ${Typography.size.size12};
  line-height: 12px;
  text-align: center;

  @media ${breakpoints.sm} {
    text-align: left;
    font-size: ${Typography.size.size14};
    line-height: 16px;

    ::before {
      content: ' - ';
    }
  }
`;

interface IProps {
  title: string;
  subtitle?: string;
  isShowActions?: boolean;
}

type Props = ChatActionsProps & IProps;

function ChatHeader(props: Props) {
  const {
    isShowActions = true,
    title,
    subtitle,
    onBlockUser,
    onDeleteChat,
    showBlockUserMenuItem,
    showDeleteChatMenuItem,
  } = props;

  return (
    <Header className="justify-content-between">
      <Titles>
        <SubTitle>{title}</SubTitle>
        {subtitle && <SubTitleHeader>{subtitle}</SubTitleHeader>}
      </Titles>
      {isShowActions && onBlockUser && (
        <ChatActions
          onDeleteChat={onDeleteChat}
          onBlockUser={onBlockUser}
          showBlockUserMenuItem={showBlockUserMenuItem}
          showDeleteChatMenuItem={showDeleteChatMenuItem}
        />
      )}
    </Header>
  );
}

export default memo(ChatHeader);
