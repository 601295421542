import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import {InjectedStripeProps, withStripe} from 'Common/helpers/withStripe';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Payment/store/orderPayment';

import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared';
import {withCurrency} from 'Common/helpers/withCurrency';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';

import Loading from 'Loading/components/Loading';
import {getStripeSubmitHandler} from 'Payment/shared/getStripeSubmitHandler';
import StripeFields from './PaymentFields/StripeFields';
import {useLogger} from 'Common/helpers/hooks/useLogger';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';

const PAYMENT_LOADING_ERROR = 'Error on loading data for payment';
const STRIPE_LOADING_SCRIPT_ERROR = 'Error on loading Stripe components. Please refresh the page.';

const FormRoot = styled.div`
  position: relative;
  padding: 0 40px 24px;
`;

const SubmitErrorMessage = styled(ErrorMessage)`
  margin-top: 16px;
`;

type IConnected = ConnectedProps<typeof connector>;

interface IOwnProps {
  token?: string;
  amount: number;
  creatingOrder?: boolean;
  createOrder?(): void;
  onSuccess(): void;
  isBusiness?: boolean;
}

type Props = IConnected & IOwnProps & InjectedStripeProps;

function StripeForm(props: Props) {
  const {
    stripe,
    elements,
    paymentIntentId,
    getPaymentId,
    token,
    onSuccess,
    amount,
    paymentIntentIdLoading,
    createOrder,
    creatingOrder,
    isBusiness,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState('');
  const [isPayNow, setIsPayNow] = useState(false);
  const [isCardNumberEmpty, setIsCardNumberEmpty] = useState(true);
  const [isCardExpiryEmpty, setIsCardExpiryEmpty] = useState(true);
  const [isCardCVCEmpty, setIsCardCVCEmpty] = useState(true);

  const {executeRecaptcha} = useGoogleReCaptcha();

  const isPreloading = paymentIntentIdLoading.isRequesting || creatingOrder || isSubmitting;
  const isDisableSubmit =
    isPreloading || !!paymentIntentIdLoading.error || isCardNumberEmpty || isCardExpiryEmpty || isCardCVCEmpty;
  const isAllowToPay = !!token && !!paymentIntentId && isPayNow;

  const stripeLoadingScriptError = (!stripe || !elements) && STRIPE_LOADING_SCRIPT_ERROR;

  const error =
    submittingError ||
    stripeLoadingScriptError ||
    (paymentIntentIdLoading.error && (paymentIntentIdLoading.error?.errors?.[0]?.description || PAYMENT_LOADING_ERROR));

  useEffect(() => {
    if (error) {
      console.log('test deploy');
      setIsPayNow(false);
    }
  }, [error]);

  useEffect(() => {
    const fetchRecaptchaToken = async (token: string) => {
      if (!executeRecaptcha) {
        console.warn('Execute recaptcha not available yet');
        return;
      }

      const captchaToken = await executeRecaptcha('getStripePaymentIntent');
      getPaymentId(token, captchaToken, isBusiness);
    };
    token && fetchRecaptchaToken(token);
  }, [token, getPaymentId, isBusiness, executeRecaptcha]);

  useEffect(() => {
    isAllowToPay &&
      paymentIntentId &&
      getStripeSubmitHandler({
        onSuccess,
        setError: setSubmittingError,
        paymentId: paymentIntentId,
        setIsLoading: setIsSubmitting,
        stripe,
        elements,
      })();
  }, [isAllowToPay, elements, onSuccess, token, paymentIntentId, stripe]);

  const {logger} = useLogger();
  const onError = useCallback(() => {
    logger.error('PaymentIntentId loading error', convertToLoggerContext(paymentIntentIdLoading.error));
  }, [logger, paymentIntentIdLoading]);
  useOnErrorCommunication(paymentIntentIdLoading, onError);

  const onSubmit = useCallback(() => {
    createOrder && createOrder();
    setIsPayNow(true);
  }, [createOrder]);

  const handleOnCardNumberChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setIsCardNumberEmpty(event.empty);
  }, []);

  const handleOnCardExpiryChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setIsCardExpiryEmpty(event.empty);
  }, []);

  const handleOnCardCVCChange = useCallback((event: stripe.elements.ElementChangeResponse) => {
    setIsCardCVCEmpty(event.empty);
  }, []);

  return (
    <div>
      <ModalWindowHeader>Pay with credit card</ModalWindowHeader>
      <div>
        <FormRoot>
          {isPreloading && <Loading />}
          <StripeFields
            onCardNumberChange={handleOnCardNumberChange}
            onCardExpiryChange={handleOnCardExpiryChange}
            onCardCVCChange={handleOnCardCVCChange}
          />
          {error && <SubmitErrorMessage>{error}</SubmitErrorMessage>}
        </FormRoot>
        <ModalWindowFooter className="justify-content-center">
          <ModalWindowButton
            disabled={isDisableSubmit}
            isLoading={isSubmitting}
            style={{minWidth: 50, width: '70%'}}
            onClick={onSubmit}
          >
            {`Pay ${withCurrency(amount)}`}
          </ModalWindowButton>
        </ModalWindowFooter>
      </div>
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  paymentIntentId: selectors.selectPaymentIntentId(state),
  paymentIntentIdLoading: selectors.selectCommunication(state, 'paymentIntentIdLoading'),
});

const mapDispatchToProps = {
  getPaymentId: actions.getPaymentIntentId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStripe(memo(StripeForm)));
export type IStripeFormProps = IOwnProps;
