import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {CSSObjectWithLabel} from 'react-select/dist/declarations/src/types';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {Divider} from 'Order/components/CreateOrder/common/styled';
import {IOrderHorse} from 'Order/models/IOrderHorse';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {FieldArray, FieldArrayRenderProps} from 'formik';
import {castToOption} from 'Common/helpers/OptionHelper';
import {sortByName} from 'Common/helpers/sortByName';
import {SelectField} from 'Common/components/FormFields';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IHorseTest} from 'Order/models/IHorseTest';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import HorseDataService from 'Horse/services/HorseDataService';

const selectStyles = {
  placeholder: () => ({
    color: ColorPalette.black1,
  }),
  control: (base: CSSObjectWithLabel, state: any) => ({
    border: state.isFocused || state.hasValue ? `1px solid ${Theme.color.primary}` : `1px solid ${ColorPalette.black1}`,
    ':hover': {
      borderColor: state.isFocused || state.hasValue ? Theme.color.primary : ColorPalette.black1,
    },
  }),
};

const Root = styled.div`
  width: 90%;
  min-height: 400px;
  max-height: 600px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  box-shadow: 0 4px 11px 0 ${ColorPalette.gray1};
  background-color: ${ColorPalette.white0};
  z-index: 1;
`;

const DoneButton = styled(PrimaryButton)`
  min-width: 80px;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size18};
  font-weight: ${Typography.weight.semiBold600};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ColorPalette.black1};
`;

const SelectWrapper = styled.div`
  margin-top: 16px;
`;

const CartIcon = styled(ColoredIcon)`
  margin-right: 8px;
`;

const RemoveButtonHorseWrapper = styled.div`
  padding-top: 10px;
  display: block;
`;

const RemoveButton = styled(IconButton)`
  bottom: 30px;
  margin-left: 16px;
`;

const AddHorseButton = styled(PrimaryButton)`
  margin-top: 24px;
`;

const PackageErrorWrapper = styled.div`
  color: ${ColorPalette.red1};
  margin-bottom: 1rem;
  p {
    margin-bottom: 0px;
  }
`;

const OrderValidationInfo = styled.div`
  p {
    text-align: center;
    color: ${ColorPalette.red14};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

interface IProps {
  packageId: number;
  horses: IOrderHorse[];
  tests: IHorseTest[];
  onSuccess(): void;
  onClose(horses: number[]): void;
}

function HorsesCard(props: IProps) {
  const {onSuccess, onClose, horses, tests, packageId} = props;
  const [validatedIdList, setValidatedIdList] = useState<{id: number; hasError: boolean}[]>([]);

  const uniqueHorses = useMemo(
    () =>
      castToOption(
        horses
          .map((horse) =>
            tests.find(
              (t) =>
                t.packageId === packageId && t.horses.length > 0 && t.horses.findIndex((x) => x === horse.id) !== -1
            )
              ? {...horse, isInvisible: true}
              : horse
          )
          .sort(sortByName)
      ),
    [horses, packageId, tests]
  );

  const filteredTests = useMemo(() => {
    return tests.find((x) => x.packageId === packageId);
  }, [packageId, tests]);

  const recordIndex = tests.findIndex((x) => x.packageId === packageId);
  const isHorsesSelect = tests[recordIndex].horses.filter((x) => x !== 0).length > 0;

  const renderRemoveButton = useCallback((arrayHelper: FieldArrayRenderProps, index: number) => {
    return (
      <RemoveButton
        onClick={() => arrayHelper.remove(index)}
        name={IconName.Clear}
        color={Theme.color.primary}
        size={14}
        fill={true}
        stroke={false}
        hoverColor={Theme.color.control.active}
      />
    );
  }, []);

  const onDoneClick = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  // TODO: Receive ids through backend call. Set-up generic system for rules.
  // OUTDATED -> TEST APHA package ids = [50123, 50062] || PROD ENV APHA package ids = [50123, 50171]
  // APHA Complete Pkg = 50250, APHA Complete Pkg Upgrade = 50316
  const APHA_PACKAGE_IDS = useMemo(() => {
    return [50250, 50316];
  }, []);

  const isAPHAPackage = useMemo(() => {
    return APHA_PACKAGE_IDS.includes(packageId);
  }, [APHA_PACKAGE_IDS, packageId]);

  const selectedHorses = useMemo(() => {
    return validatedIdList.filter((horse) => filteredTests?.horses.indexOf(horse.id) !== -1);
  }, [validatedIdList, filteredTests?.horses]);

  const selectedHorsesHaveError = useMemo(() => {
    return selectedHorses.some((item) => item.hasError === true);
  }, [selectedHorses]);

  const onCloseClick = useCallback(() => {
    if (isAPHAPackage && selectedHorsesHaveError) {
      const newHorseValues = tests[recordIndex].horses.filter((horseId) =>
        selectedHorses.some((item) => item.id === horseId && item.hasError === false)
      );
      onClose(newHorseValues);
    } else {
      onSuccess();
    }
  }, [isAPHAPackage, selectedHorses, selectedHorsesHaveError, tests, recordIndex, onClose, onSuccess]);

  // Validation for APHA orders. Horse must have both parents and DoB to be valid.
  const APHAValidation = (horse: IHorseProfile) => {
    if (!horse) return;

    const hasBothParents = !horse.parentage.some((parent) => parent.name === '');
    const hasDOB = !(horse.dateOfBirth == null);

    const hasAPHAError = !(hasBothParents && hasDOB);

    setValidatedIdList((prevState) => {
      if (prevState.some((item) => item.id === horse.id)) return prevState;
      return [...prevState, {id: horse.id, hasError: hasAPHAError}];
    });
  };

  useEffect(() => {
    if (isAPHAPackage) {
      tests[recordIndex].horses.forEach((horseId) => {
        if (horseId === 0 || validatedIdList.some((item) => item.id === horseId)) return;
        HorseDataService.getHorse(horseId).then((res) => {
          APHAValidation(res!);
        });
      });
    }
  }, [isAPHAPackage, tests, recordIndex, validatedIdList]);

  const noOptionsMessageContent = (userInput: any) => {
    if (uniqueHorses.length === 0) {
      return 'To start testing you will need to add your horse(s) to your profile.';
    }
    return `'${userInput?.inputValue}' does not match any of the horses on your profile.`;
  };

  return (
    <Root className="d-flex flex-column justify-content-between position-absolute">
      <div>
        <div>
          <Container className="d-flex align-items-center">
            <CartIcon name={IconName.ShoppingCartAdd} color={Theme.color.primary} stroke={false} fill={true} />
            <Title>Add to cart for:</Title>
            <IconButton
              className="ml-auto"
              name={IconName.Close}
              onClick={onCloseClick}
              color={ColorPalette.gray31}
              hoverColor={ColorPalette.black2}
              fill={true}
              stroke={false}
              size={16}
            />
          </Container>

          <Divider />
        </div>

        {isAPHAPackage && (
          <OrderValidationInfo>
            <p>
              <strong>APHA Orders require Sire, Dam and date of birth.</strong>
            </p>
          </OrderValidationInfo>
        )}

        <Container className="d-flex flex-column" style={{paddingTop: 0}}>
          <Scrollbar minHeight={250} maxHeight={370}>
            <FieldArray
              name={`tests[${recordIndex}].horses`}
              render={(arrayHelper) => (
                <div className="flex-column">
                  {filteredTests?.horses &&
                    filteredTests?.horses.length > 0 &&
                    filteredTests?.horses.map((horseId, index, arr) => (
                      <React.Fragment key={index}>
                        <SelectWrapper className="d-flex">
                          <div className="w-100">
                            <SelectField
                              style={{marginBottom: 0}}
                              name={`tests[${recordIndex}].horses[${index}]`}
                              options={uniqueHorses}
                              isRequired={true}
                              menuPortalTarget={document.body}
                              placeholder="Select Horse"
                              styles={selectStyles}
                              noOptionsMessage={(userInput: any) => noOptionsMessageContent(userInput)}
                            />
                          </div>
                          <RemoveButtonHorseWrapper className="align-self-start">
                            {renderRemoveButton(arrayHelper, index)}
                          </RemoveButtonHorseWrapper>
                        </SelectWrapper>
                        {validatedIdList.some((item) => item.id === horseId && item.hasError === true) && (
                          <PackageErrorWrapper>
                            <p>Please add the missing information for this horse.</p>
                          </PackageErrorWrapper>
                        )}
                      </React.Fragment>
                    ))}
                  <AddHorseButton
                    disabled={filteredTests && !(filteredTests.horses.length < uniqueHorses.length)}
                    size="small"
                    variant="outlined"
                    onClick={() => arrayHelper.push(0)}
                  >
                    + Additional Horses
                  </AddHorseButton>
                </div>
              )}
            />
          </Scrollbar>
        </Container>
      </div>

      <div>
        <Divider />
        <Container>
          <DoneButton onClick={onDoneClick} size="small" disabled={!isHorsesSelect || selectedHorsesHaveError}>
            Done
          </DoneButton>
        </Container>
      </div>
    </Root>
  );
}

export default memo(HorsesCard);
