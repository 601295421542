import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {AdminChatStatus} from 'MessageCenter/constants/AdminChatStatus';
import {IBlockedUser} from 'MessageCenter/models/IBlockedUser';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {IUsersChatMessage} from 'MessageCenter/models/IUsersChatMessage';
import {IUserToAdminChatDetails} from 'MessageCenter/models/IUserToAdminChatDetails';
import {IUserToAdminChatMessage} from 'MessageCenter/models/IUserToAdminChatMessage';
import {
  convertBlockedUserToClient,
  convertReceivedUsersChatRequestToClient,
  convertRejectedIncomingUsersChatRequestToClient,
  convertRejectedOutgoingUsersChatRequestToClient,
  convertUsersChatMessageToClient,
  convertUsersChatRequestToClient,
  convertUsersChatToClient,
  convertUserToAdminChatMessageToClient,
  convertUserToAdminDetailsToClient,
} from './converters/messageCenter';
import {
  IServerBlockedUser,
  IServerReceivedUsersChatRequest,
  IServerRejectedIncomingUsersChatRequest,
  IServerRejectedOutgoingUsersChatRequest,
  IServerUsersChat,
  IServerUsersChatMessage,
  IServerUsersChatMessageRequest,
  IServerUsersChatRequest,
  IServerUserToAdminChatMessage,
  IServerUserToAdminChatMessageRequest,
} from './types/messageCenter';

const getUsersChats = async (): Promise<IListResponse<IMessageCenterUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUsersChat>>(`/UserToUserChatsRead`);
  return {...response.data, data: response.data.data.map(convertUsersChatToClient)};
};

const getReceivedUsersChatsRequests = async (): Promise<IListResponse<IMessageCenterUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerReceivedUsersChatRequest>>(
    `/ReceivedUserToUserChatsRequestsRead`
  );
  return {...response.data, data: response.data.data.map(convertReceivedUsersChatRequestToClient)};
};

const getUsersChatRequests = async (): Promise<IListResponse<IMessageCenterUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUsersChatRequest>>(`/UserToUserChatRequestsRead`);
  return {...response.data, data: response.data.data.map(convertUsersChatRequestToClient)};
};

const getRejectedIncomingChatRequests = async (): Promise<IListResponse<IMessageCenterUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerRejectedIncomingUsersChatRequest>>(
    `/RejectedUserToUserChatRequestsRead`
  );
  return {...response.data, data: response.data.data.map(convertRejectedIncomingUsersChatRequestToClient)};
};

const getRejectedOutgoingChatRequests = async (): Promise<IListResponse<IMessageCenterUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerRejectedOutgoingUsersChatRequest>>(
    `/RejectedUserToUserChatInitiatedRequestsRead`
  );
  return {...response.data, data: response.data.data.map(convertRejectedOutgoingUsersChatRequestToClient)};
};

const getUsersChatMessages = async (
  params: IServerUsersChatMessageRequest
): Promise<IListResponse<IUsersChatMessage>> => {
  const response = await axiosWrapper.get<IListResponse<IServerUsersChatMessage>>(`/UserToUserChatMessagesRead`, {
    params,
  });
  return {...response.data, data: response.data.data.map(convertUsersChatMessageToClient)};
};

const acceptUsersChatRequest = async (chatRequestId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/UserToUserChatRequestsAccept`, chatRequestId);
};

const closetUsersChatRequest = async (chatRequestId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/UserToUserChatRequestsClose`, chatRequestId);
};

const rejectUsersChatRequest = async (chatRequestId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/UserToUserChatRequestsReject`, chatRequestId);
};

const cancelRejectUsersChatRequest = async (chatRequestId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/UserToUserChatRequestsRejectCancellation`, chatRequestId);
};

const getUserToAdminChatMessages = async (
  params: IServerUserToAdminChatMessageRequest
): Promise<IObjectResponse<IUserToAdminChatMessage>> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserToAdminChatMessage>>(
    `/UserToAdminChatMessagesRead`,
    {
      params,
    }
  );
  return {...response.data, data: convertUserToAdminChatMessageToClient(response.data.data)};
};

const getUserToAdminChatDetails = async (): Promise<IObjectResponse<IUserToAdminChatDetails>> => {
  const response = await axiosWrapper.get<IObjectResponse<IUserToAdminChatDetails>>(`/UserToAdminChatDetailsRead`);
  return {...response.data, data: convertUserToAdminDetailsToClient(response.data.data)};
};

const createUserToAdminChatRequest = async (): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<number>>(`/UserToAdminChatsCreate`);
};

const getBlockedUsers = async (): Promise<IListResponse<IBlockedUser>> => {
  const response = await axiosWrapper.get<IListResponse<IServerBlockedUser>>(`/BlockedUsersRead`);
  return {...response.data, data: response.data.data.map(convertBlockedUserToClient)};
};

const addBlockedUser = async (userId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/BlockedUsersCreate/${userId}`);
};

const deleteBlockedUser = async (userId: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/BlockedUsersDelete/${userId}`);
};

const getUserToAdminChatIsOnline = async (): Promise<boolean> => {
  const response = await axiosWrapper.get<IObjectResponse<boolean>>(`/UserToAdminChatIsOnline`);
  return response.data.data;
};

const getUserToAdminChatStatus = async (): Promise<AdminChatStatus> => {
  const response = await axiosWrapper.get<IObjectResponse<AdminChatStatus>>(`/UserToAdminChatsStatusRead`);
  return response.data.data;
};

const deleteUserToUserChat = async (chatId: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/UserToUserChatsDelete/${chatId}`);
};

const deleteUserToUserChatMessage = async (messageId: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/UserToUserChatMessagesDelete/${messageId}`);
};

export default {
  getReceivedUsersChatsRequests,
  getUsersChats,
  getUsersChatRequests,
  getUsersChatMessages,
  getUserToAdminChatMessages,
  getRejectedIncomingChatRequests,
  getRejectedOutgoingChatRequests,
  acceptUsersChatRequest,
  closetUsersChatRequest,
  rejectUsersChatRequest,
  cancelRejectUsersChatRequest,
  getUserToAdminChatDetails,
  createUserToAdminChatRequest,
  getBlockedUsers,
  addBlockedUser,
  deleteBlockedUser,
  getUserToAdminChatIsOnline,
  getUserToAdminChatStatus,
  deleteUserToUserChat,
  deleteUserToUserChatMessage,
};
