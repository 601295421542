import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {DropDownMenu, IAvailabilityMenuItem} from 'Common/components/DropDown';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

const Root = styled.div`
  margin-left: auto;
`;

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  showBlockUserMenuItem?: boolean;
  showDeleteChatMenuItem?: boolean;

  onBlockUser?(): void;
  onDeleteChat?(): void;
}

function ChatActions(props: IProps) {
  const {onBlockUser, onDeleteChat, showBlockUserMenuItem = true, showDeleteChatMenuItem = true} = props;

  const menuItems: IAvailabilityMenuItem[] = [
    {
      id: 0,
      value: 'blockUserHandler',
      label: 'Block user',
      icon: {name: IconName.AccountBlock, color: ColorPalette.red7, size: 20},
      iconStyle: {marginRight: 14},
      availabilityKey: showBlockUserMenuItem,
    },
    {
      id: 1,
      value: 'deleteHandler',
      label: 'Delete chat history',
      icon: {name: IconName.Garbage, color: ColorPalette.red7},
      availabilityKey: showDeleteChatMenuItem,
    },
  ];

  const shownMenuItems = menuItems.filter((item) => item.availabilityKey).sort((a, b) => sortByStringKey(a, b, 'id'));

  const actionsHandler = useMemo(
    () => ({
      blockUserHandler: onBlockUser,
      deleteHandler: onDeleteChat,
    }),
    [onBlockUser, onDeleteChat]
  );

  const onSelectHandler = useCallback((type) => actionsHandler[type](), [actionsHandler]);

  return (
    <Root className="d-flex align-items-center justify-content-end">
      <DropDownMenu items={shownMenuItems} onSelect={onSelectHandler}>
        {({isOpen}) => (
          <MoreIcon
            name={IconName.More}
            color={isOpen ? ColorPalette.black1 : ''}
            hoverColor={ColorPalette.black1}
            fill={true}
            stroke={false}
          />
        )}
      </DropDownMenu>
    </Root>
  );
}

export default memo(ChatActions);
export type ChatActionsProps = IProps;
