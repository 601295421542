import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormikBag, FormikProps, withFormik} from 'formik';

import {InjectedStripeProps} from 'Common/helpers/withStripe';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'BusinessPortal/store/orderPayment';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import Loading from 'Loading/components/Loading';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {getCommonErrors, getErrorCode} from 'Common/helpers/ErrorHelper';
import {parseUrlParams} from 'Common/helpers/url';
import Summary from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/Summary';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Payment from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/parts/Payment';
import {SummaryBackground, SummaryContainer} from 'BusinessPortal/components/shared/StyledComponents';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {IFormValues, initialValues} from './validation';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {PaymentError} from 'Payment/constants/PaymentError';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {StripeForm} from 'Payment/components';
import {BusinessPortalOrderPaymentModule} from 'BusinessPortal/store/orderPayment/businessPortalOrderPaymentModule';
import {OrderPaymentModule} from 'Payment/store/orderPayment/orderPaymentModule';
import {PaymentStatus} from 'Admin/AdminDashboard/models/IPaymentDetails';

const PAYMENT_LOADING_ERROR = 'Error on loading data for payment';
const SUMMARY_ERROR = 'Error on getting order summary';
const HORSES_ERROR = 'Error on getting order summary';

const loadingStyle: React.CSSProperties = {zIndex: 1000};

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.md} {
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  @media ${breakpoints.sm} {
    height: auto;
  }
`;

const PaymentForm = styled.div`
  width: 100%;
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  max-width: 448px;

  @media ${breakpoints.sm} {
    margin: 40px 72px;
  }
`;

const Header = styled.div`
  color: ${Theme.color.black};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size32};
  line-height: 56px;

  @media ${breakpoints.sm} {
    margin-bottom: 8px;
  }
`;

const PayButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 448px;
  margin-top: 32px;
`;

const SubmitErrorMessage = styled(ErrorMessage)`
  margin-top: 64px;
  text-align: center;
`;

const HorseInfo = styled.div`
  background: ${ColorPalette.gray49};
  padding: 8px 16px 0;
  margin-bottom: 16px;
`;

const HorseInfoTitle = styled.span`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
`;

const HorseInfoValue = styled.span`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
`;

const HorseInfoLine = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  padding-bottom: 8px;
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & InjectedStripeProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function OrderPayment(props: AllProps) {
  const {horses, horsesLoading, summary, summaryLoading, getPaymentSummary, getPaymentHorses} = props;

  const {values} = props;

  const navigate = useNavigate();
  const location = useLocation();
  const {isMobile} = useMediaQuery();

  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isOpenPaymentForm, setIsOpenPaymentForm] = useState(false);

  const {token} = parseUrlParams<{token?: string}>(location.search);

  const isLoading = summaryLoading.isRequesting || horsesLoading.isRequesting;

  const invalidTokenError = isInvalidToken && PAYMENT_LOADING_ERROR;
  const summaryError = summaryLoading.error && (getCommonErrors(summaryLoading.error) || SUMMARY_ERROR);
  const horsesError = horsesLoading.error && (getCommonErrors(horsesLoading.error) || HORSES_ERROR);
  const orderPaidError =
    summaryLoading.error &&
    getErrorCode(summaryLoading.error) === PaymentError.OrderPaid &&
    getCommonErrors(summaryLoading.error);
  const error = invalidTokenError || orderPaidError;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (!token) {
      setIsInvalidToken(true);
      return;
    }

    getPaymentHorses(token);
    getPaymentSummary(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (summary) {
      setAmount(summary.totalAmount);
    }
  }, [summary]);

  const openPaymentForm = useCallback(async () => {
    if (!token) {
      setIsInvalidToken(true);
      return;
    }

    setIsOpenPaymentForm(true);
  }, [token]);

  const closePaymentForm = useCallback(() => {
    setIsOpenPaymentForm(false);
  }, []);

  const redirectToSuccessPayment = useCallback(() => {
    navigate(`/business-portal/order-placed/${token}/payment`, {replace: true});
  }, [navigate, token]);

  const layoutProps = {isBackButtonDenied: true, withoutPaddings: true, hideFooter: isMobile, fixedHeight: isMobile};

  if (error) {
    return (
      <BusinessPortalLayout {...layoutProps}>
        <SubmitErrorMessage>{error || PAYMENT_LOADING_ERROR}</SubmitErrorMessage>
      </BusinessPortalLayout>
    );
  }

  return (
    <BusinessPortalLayout {...layoutProps}>
      <ModalWindow isOpen={isOpenPaymentForm} onClose={closePaymentForm} maxWidth="500px">
        <StripeForm token={token} amount={amount} onSuccess={redirectToSuccessPayment} isBusiness />
      </ModalWindow>

      <Root>
        {isLoading && <Loading style={loadingStyle} />}

        <PaymentForm>
          <Header>Secure order payment</Header>
          <div>
            <HorseInfo>
              <HorseInfoLine>
                <HorseInfoTitle>Horse</HorseInfoTitle>
                <HorseInfoTitle>Packages and tests</HorseInfoTitle>
                <HorseInfoTitle className="d-flex justify-content-end">Summary</HorseInfoTitle>
              </HorseInfoLine>
              {horses.map((horse, i) => (
                <HorseInfoLine key={i}>
                  <HorseInfoValue>{horse.name}</HorseInfoValue>
                  <HorseInfoValue>{horse.packages.concat(horse.tests).sort().join(', ')}</HorseInfoValue>
                  <HorseInfoValue className="d-flex justify-content-end">
                    {horse.status === PaymentStatus.Ready ? PaymentStatus.Ready : `$${horse.price}`}
                  </HorseInfoValue>
                </HorseInfoLine>
              ))}
            </HorseInfo>

            <Payment excludeInvoice={true} />
          </div>
          <PayButton onClick={openPaymentForm}>Pay now</PayButton>
        </PaymentForm>

        <SummaryContainer>
          <SummaryBackground />
          <Summary
            summary={summary}
            error={summaryError || horsesError}
            paymentMethod={values.paymentMethod}
            isSummaryLoading={summaryLoading.isRequesting}
          />
        </SummaryContainer>
      </Root>
    </BusinessPortalLayout>
  );
}

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  try {
    formikBag.setSubmitting(true);
  } finally {
    formikBag.setSubmitting(false);
  }
};

const OrderPaymentWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValues,
  handleSubmit,
  enableReinitialize: true,
})(memo(OrderPayment));

const mapStateToProps = (state: IAppState) => ({
  horses: selectors.selectPaymentHorses(state),
  summary: selectors.selectPaymentSummary(state),
  horsesLoading: selectors.selectCommunication(state, 'paymentHorsesLoading'),
  summaryLoading: selectors.selectCommunication(state, 'paymentSummaryLoading'),
});

const mapDispatchToProps = {
  getPaymentHorses: actions.getPaymentHorses,
  getPaymentSummary: actions.getPaymentSummary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(OrderPaymentWithFormik), [
  BusinessPortalOrderPaymentModule,
  OrderPaymentModule,
]);
