import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {matchPath, useLocation, useNavigate, useParams} from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';

import Summary from './Summary/Summary';
import Tabs from 'Common/components/Tabs/Tabs';
import CoatColor from './CoatColor/CoatColor';
import HealthVariants from 'OnlineReport/components/HealthVariants/HealthVariants';
import PerformanceAbilities from './PerformanceAbilities/PerformanceAbilities';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import TabsContent from 'Common/components/Tabs/TabsContent';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IconName} from 'Icon/components/Icon';
import {IAppState} from 'Common/store/IAppState';
import {OnlineReportActions} from 'OnlineReport/store/diagnostic';
import {AdminOnlineReportActions} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic';
import Loading from 'Loading/components/Loading';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {OnlineReportModule} from 'OnlineReport/store/diagnostic/onlineReportModule';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import {OnlineReportType} from './shared/OnlineReportType';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';
import {zIndex} from 'Common/constants/ZIndex';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {onlineReportDistributor} from './distributor';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import PrintSelectionForm from './PrintSelectionForm/PrintSelectionForm';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {VisitorType} from 'Common/constants/VisitorType';
import Ancestry from 'OnlineReport/components/Ancestry/Ancestry';
import Actions from 'Common/components/Actions/Actions';
import {IAvailabilityMenuItemValued} from 'Common/models/IMenuItemValued';
import {breakpoints} from 'Common/constants/Breakpoints';
import {MutableFieldsGroupBase} from 'Common/components/StyledComponents/StyledComponents';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';
import ShortTandemRepeat from './ShortTandemRepeat/ShortTandemRepeat';

const tabs: ITab<IOnlineReportExternalProps>[] = [
  {section: 'summary', title: 'Summary', Component: Summary},
  {section: 'coat-color', title: 'Coat color', Component: CoatColor},
  {section: 'health-variants', title: 'Health Variants', Component: HealthVariants},
  {section: 'performance-abilities', title: 'Performance and Abilities', Component: PerformanceAbilities},
  {section: 'ancestry', title: 'Ancestry', Component: Ancestry},
];

const infoTab: ITab[] = [
  {section: 'general-info', title: 'General info', Component: GeneralInfo, icon: {name: IconName.Question, size: 16}},
];

const tabStyle: React.CSSProperties = {
  minWidth: 'min-content',
  width: 'unset',
  backgroundColor: ColorPalette.white0,
};

const linkStyle: React.CSSProperties = {
  fontFamily: Typography.family.sourceSansPro,
  backgroundColor: ColorPalette.white0,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '32px',
  color: Theme.color.gray,
  paddingLeft: 32,
  paddingRight: 32,
  textAlign: 'left',
};

const mobileLinkStyle: React.CSSProperties = {
  fontWeight: 400,
  fontSize: 20,
  lineHeight: '24px',
};

const tabRootStyle: React.CSSProperties = {
  backgroundColor: ColorPalette.white0,
  height: 64,
};

const tabNameMobileStyle: React.CSSProperties = {
  fontWeight: 400,
  fontSize: 20,
  color: Theme.color.black,
};

const infoTabStyle: React.CSSProperties = {
  ...tabStyle,
  fontWeight: 400,
  color: Theme.color.black,
};

const infoLinkStyle: React.CSSProperties = {
  ...linkStyle,
  fontWeight: 400,
  color: Theme.color.black,
};

enum OnlineReportActionValue {
  OpenPrintableOnlineReport = 'openPrintableOnlineReport',
  OpenPrintSelectionModal = 'openPrintSelectionModal',
  OpenPrintableAncestryReport = 'openPrintableAncestryReport',
}

const menuItems: IAvailabilityMenuItemValued<OnlineReportActionValue>[] = [
  {id: 1, value: OnlineReportActionValue.OpenPrintableOnlineReport, label: 'Print entire report'},
  {id: 2, value: OnlineReportActionValue.OpenPrintSelectionModal, label: 'Customize'},
  {id: 3, value: OnlineReportActionValue.OpenPrintableAncestryReport, label: 'Ancestry report'},
];

const Root = styled.div<{isReview: boolean}>`
  margin: ${({isReview}) => (isReview ? '24px 24px 200px 24px' : '24px 24px 12px')};

  @media ${breakpoints.sm} {
    margin: ${({isReview}) => (isReview ? '56px 56px 200px 56px' : '56px 56px 24px')};
  }

  @media ${breakpoints.md} {
    margin: ${({isReview}) => (isReview ? '65px 65px 200px 65px' : '65px 65px 45px')};
  }
`;

const ReportTitle = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 32px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }
`;

const Content = styled.div`
  border-top: 2px solid ${ColorPalette.white9};
  @media ${breakpoints.sm} {
    padding: 20px 0;
  }

  @media ${breakpoints.md} {
    padding: 40px 0;
  }
`;

const TabsContainer = styled(MutableFieldsGroupBase)`
  @media ${breakpoints.sm} {
    margin-top: 30px;
  }

  @media ${breakpoints.md} {
    margin-top: 0;
    margin-left: -30px;
  }
`;

const ReviewSection = styled.div`
  position: fixed;
  bottom: 64px;
  z-index: ${zIndex.footer + 1};
`;

const ReviewButtons = styled.div`
  cursor: pointer;
  width: 248px;
  padding: 16px 0;
  background: ${Theme.color.white};
  opacity: 0.95;
  box-shadow: 0px 6px 14px ${hexToRgba(ColorPalette.black0, 0.05)}, 0px 0px 4px ${hexToRgba(ColorPalette.black0, 0.02)};
  border-radius: 16px;
`;

const CompleteReviewButton = styled(PrimaryButton)`
  margin-bottom: 16px;
`;

const Download = styled.span`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  color: ${Theme.color.primary};
  margin-right: 16px;
`;

const DownloadWrapper = styled.div`
  padding-right: 0;

  @media ${breakpoints.sm} {
    padding-right: 24px;
  }
`;

const DownloadButton = styled.div`
  padding: 8px 0px;
  cursor: pointer;
  :hover * {
    color: ${ColorPalette.red11} !important;
    fill: ${ColorPalette.red11} !important;
    stroke-width: 0;
  }
`;

const TabsMain = styled(Tabs)`
  flex-direction: column;
  align-items: flex-start;

  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const TabsInfo = styled(Tabs)`
  justify-content: flex-end;

  @media ${breakpoints.md} {
    justify-content: center;
  }
`;

const BackPrimaryButton = styled(PrimaryButton)<{isHover: boolean}>`
  opacity: ${({isHover}) => (isHover ? 1 : 0.3)};
`;

interface IExternalProps {
  horseId: string;
  orderId: string;
  reportType: OnlineReportType;
}

type RouteParams = {horseId: string; orderId: string; reportType: OnlineReportType};

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IExternalProps & IConnected;

function OnlineReport(props: AllProps) {
  const {
    getHorseInfo,
    horseInfo,
    horseInfoLoading,
    completeReviewRequesting,
    completeReview,
    resetOnlineReportResults,
    horseId,
    orderId,
    reportType,
  } = props;

  const isLoading = horseInfoLoading.isRequesting;

  const [hasChildError, setHasChildError] = useState(false);
  const [isSetReportType, setIsSetReportType] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const {isMobile, isTablet} = useMediaQuery();

  const isTabsCollapsed = isMobile || isTablet;

  const isReview =
    reportType === OnlineReportType.ReviewAdmin || reportType === OnlineReportType.ReviewAdminAssociation;

  useEffect(() => {
    setOnlineReportTypeToStorage(reportType);
    setIsSetReportType(true);
  }, [reportType]);

  useEffect(() => {
    if (+horseId) {
      getHorseInfo(+horseId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horseId]);

  const [isPrintSelectionModalOpen, setIsPrintSelectionModalOpen] = useState(false);
  const openPrintSelectionModal = useCallback(() => setIsPrintSelectionModalOpen(true), []);
  const closePrintSelectionModal = useCallback(() => setIsPrintSelectionModalOpen(false), []);

  const openPrintableOnlineReport = useCallback(
    () => navigate(location.pathname.replace('online-report', 'online-report-printable')),
    [location.pathname, navigate]
  );
  const openPrintableAncestryReport = useCallback(
    () => navigate(location.pathname.replace('online-report', 'ancestry-report-printable')),
    [location.pathname, navigate]
  );

  useEffect(() => {
    return () => {
      const match = matchPath(
        {
          path: '/:reportType/online-report-printable/:horseId/:orderId',
        },
        location.pathname
      );
      if (!match) {
        resetOnlineReportResults && resetOnlineReportResults();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [onButtonsHover, setOnButtonsHover] = useState(false);

  const setOnButtonsHoverTrue = useCallback(() => setOnButtonsHover(true), []);
  const setOnButtonsHoverFalse = useCallback(() => setOnButtonsHover(false), []);

  const redirectToDashboard = useCallback(() => navigate(-1), [navigate]);

  const onCompleteReview = useCallback(() => {
    if (isReview && parseInt(orderId) && parseInt(horseId)) {
      completeReview && completeReview({horseId: +horseId, orderId: +orderId, reviewStatus: OrderStatusField.Ready});
    }
  }, [completeReview, horseId, isReview, orderId]);

  useOnSuccessCommunication(completeReviewRequesting, redirectToDashboard);
  useErrorCommunicationToToast(completeReviewRequesting);

  const onHasChildError = useCallback((hasError: boolean) => setHasChildError(hasError), []);

  const horseProfileLink = useMemo(() => {
    if (reportType === OnlineReportType.Admin || reportType === OnlineReportType.ReviewAdmin) {
      return `${PREFIX_VISITOR_TYPE[VisitorType.Admin]}/horse/${horseId}`;
    }
    if (reportType === OnlineReportType.AdminAssociation || reportType === OnlineReportType.ReviewAdminAssociation) {
      return `${PREFIX_VISITOR_TYPE[VisitorType.AssociationAdmin]}/horse/${horseId}`;
    }
    if (reportType === OnlineReportType.Association) {
      return `${PREFIX_VISITOR_TYPE[VisitorType.AssociationEmployee]}/horse/${horseId}`;
    }
    return `/horse/${horseId}`;
  }, [horseId, reportType]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Online Report</title>
      </Helmet>

      {isSetReportType && (
        <Root isReview={isReview}>
          <ModalWindow isOpen={isPrintSelectionModalOpen} onClose={closePrintSelectionModal}>
            <PrintSelectionForm horseId={+horseId} reportType={reportType} />
          </ModalWindow>
          <MutableFieldsGroupBase pointOfChange="sm" className="d-flex">
            <div className="d-flex">
              <AvatarCell
                type="horse"
                label=""
                profileUrl={horseProfileLink}
                avatarUrl={horseInfo?.avatar?.url}
                size="52px"
              />
              <ReportTitle className="align-self-center">{horseInfo?.name}’s health report</ReportTitle>
            </div>
            {!isReview && (
              <DownloadWrapper className="align-self-center ml-auto position-relative">
                <Actions<OnlineReportActionValue>
                  menuItems={menuItems}
                  openPrintSelectionModal={openPrintSelectionModal}
                  openPrintableOnlineReport={openPrintableOnlineReport}
                  openPrintableAncestryReport={openPrintableAncestryReport}
                >
                  <DownloadButton>
                    <Download>Download or print</Download>
                    <ColoredIcon
                      name={IconName.ArrowDownload}
                      color={Theme.color.primary}
                      size={24}
                      stroke={false}
                      fill={true}
                    />
                  </DownloadButton>
                </Actions>
              </DownloadWrapper>
            )}
          </MutableFieldsGroupBase>
          {horseInfo && (
            <>
              <TabsContainer pointOfChange="md" className="d-flex justify-content-between">
                <TabsMain
                  className="h-100 flex-wrap"
                  tabs={isTabsCollapsed ? tabs.concat(infoTab) : tabs}
                  style={tabRootStyle}
                  tabStyle={tabStyle}
                  linkStyle={isTabsCollapsed ? {...linkStyle, ...mobileLinkStyle} : linkStyle}
                  isTabsCollapsedToMenu={isTabsCollapsed}
                  tabNameMobileStyle={tabNameMobileStyle}
                />
                {!isTabsCollapsed && (
                  <TabsInfo
                    className="h-100 flex-wrap"
                    tabs={infoTab}
                    style={tabRootStyle}
                    tabStyle={infoTabStyle}
                    linkStyle={infoLinkStyle}
                  />
                )}
              </TabsContainer>

              <Content>
                <TabsContent
                  basePath={location.pathname}
                  tabs={tabs.concat(infoTab)}
                  horseId={horseId}
                  orderId={orderId}
                  reportType={reportType}
                  onHasError={onHasChildError}
                />

                {isReview && (
                  <ReviewSection className="d-flex w-100 justify-content-center">
                    <ReviewButtons
                      onMouseEnter={setOnButtonsHoverTrue}
                      onMouseLeave={setOnButtonsHoverFalse}
                      className="d-flex flex-column justify-content-center align-items-center align-self-center"
                    >
                      {!hasChildError && (
                        <CompleteReviewButton onClick={onCompleteReview}>Complete review</CompleteReviewButton>
                      )}
                      <BackPrimaryButton
                        isHover={onButtonsHover}
                        onClick={redirectToDashboard}
                        variant="outlined"
                        size="small"
                      >
                        Back to dashboard
                      </BackPrimaryButton>
                    </ReviewButtons>
                  </ReviewSection>
                )}
              </Content>
            </>
          )}
        </Root>
      )}
    </>
  );
}

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {reportType} = externalProps;

  const distributor = onlineReportDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions | AdminOnlineReportActions>,
  externalProps: IExternalProps
) => {
  const {reportType} = externalProps;

  const distributor = onlineReportDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = withDynamicModules(connector(OnlineReport), [OnlineReportModule, AdminOnlineReportModule]);

const Exported = (externalProps: IExternalProps) => {
  const {horseId, orderId, reportType} = useParams<RouteParams>() as RouteParams;

  const isReview =
    reportType === OnlineReportType.ReviewAdmin || reportType === OnlineReportType.ReviewAdminAssociation;
  const isAssociation = reportType === OnlineReportType.Association;
  const {isTablet} = useMediaQuery();

  return isAssociation ? (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} isBackButtonDenied={false}>
      <div className="position-relative d-flex flex-column">
        <Connected {...externalProps} horseId={horseId} orderId={orderId} reportType={reportType} />
      </div>
    </BusinessPortalLayout>
  ) : (
    <BaseLayout
      hideFooter={isReview}
      withoutPaddings={true}
      widthByContent={true}
      backButtonStyle={isTablet ? {marginLeft: 36, marginTop: 18} : {marginLeft: 62, marginTop: 26}}
    >
      <div className="position-relative d-flex flex-column flex-grow-1">
        <Connected {...externalProps} horseId={horseId} orderId={orderId} reportType={reportType} />
      </div>
    </BaseLayout>
  );
};

export default Exported;
