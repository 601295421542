import {memo} from 'react';

import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {ChatRoot, ChatText} from '../../styled';
import OutgoingMessage from '../Messages/OutgoingMessage';

interface IProps {
  rejectedUserRequest: IMessageCenterUser;
}

function RejectedOutgoingRequest(props: IProps) {
  const {rejectedUserRequest} = props;

  return (
    <ChatRoot className="d-flex flex-column">
      <OutgoingMessage message={rejectedUserRequest.message} url={rejectedUserRequest.user.avatar?.url} />
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>User has canceled your request to conversation.</ChatText>
      </div>
    </ChatRoot>
  );
}

export default memo(RejectedOutgoingRequest);
