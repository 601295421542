import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import * as R from 'ramda';
import {memo, useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {HorseFilters} from 'Filters/components';

import {actions, selectors} from 'BuildHorse/store';
import {Pagination} from 'Common/components/Controls';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IAppState} from 'Common/store/IAppState';
import {
  convertHorseFiltersFormValues,
  IHorseFiltersFormValues,
  initialHorseFiltersValues,
} from 'Filters/components/HorseFilters/formConfig';

import {BuildHorseModule} from 'BuildHorse/store/module';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Header from 'Common/components/Header/Header';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import {VisitorType} from 'Common/constants/VisitorType';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {useOnRequestCommunication} from 'Common/helpers/hooks/useOnRequestCommunication';
import {useQueryParams} from 'Common/helpers/hooks/useQueryParams';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import ExcludePanel from 'ExcludeHorse/components/ExcludePanel';
import {useExcludeListModal} from 'ExcludeHorse/hooks/useExcludeListModal';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Icon, {IconName} from 'Icon/components/Icon';
import LongTimeLoading from 'Loading/components/LongTimeLoading';
import PotentialFoals from './PotentialFoals/PotentialFoals';
import {BuildHorsePlaceholder} from './shared/placeholders/placeholders';
import {Filters, FiltersContainer, HeaderFiltersToggleIcon, PlaceholderContainer} from './styled';

const COULD_NOT_FIND_PARTNERS = `Sorry. We couldn't find matching horses`;
const UNKNOWN_ERROR = `Error on loading results`;

const Root = styled.div`
  flex-grow: 1;
  display: flex;
`;

const FoalsContainer = styled.div<{isHidden?: boolean}>`
  flex-grow: 1;
  width: 100%;
  padding: 32px;
  display: ${(p) => (p.isHidden ? 'none' : 'block')};

  @media ${breakpoints.sm} {
    display: block;
  }
`;

const FoalsList = styled.div`
  flex-grow: 1;
  max-width: 1030px;
`;

const EmptyFoalsMessage = styled.div`
  text-align: center;
  margin-top: 32px;
  font-size: ${Typography.size.size18};
`;

const LoaderContainer = styled.div`
  margin-top: 100px;
`;

const FiltersToggle = styled.div<{isFiltersOpen?: boolean}>`
  position: absolute;
  top: 24px;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Theme.color.primary};
  cursor: pointer;

  @media (min-width: ${size.sm}px) and (max-width: ${size.md}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(p) =>
      p.isFiltersOpen
        ? `
    transform: rotate(90deg);
    right: -20px; 
    `
        : `
    right: -56px;
    `};
  }
`;

const ApplyButton = styled(PrimaryButton)`
  margin-top: 40px;
  width: 100%;
`;

const FixedWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 28px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  background: ${Theme.color.white};

  ${ApplyButton} {
    margin: 0;
  }
`;

const BuildFoalLink = styled.div`
  cursor: pointer;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  text-decoration-line: underline;
  color: ${ColorPalette.blue2};
`;

interface IFormValues extends IHorseFiltersFormValues {
  currentPage?: number;
}

type RouteParams = {horseId?: string};

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IWithFavoriteHorseActionsProps;

type Props = OuterProps & FormikProps<IFormValues>;

function BuildHorse(props: Props) {
  const {foals, pagination, foalsLoading, favoriteHorseAdding, favoriteHorseDeleting} = props;
  const {values, isSubmitting, setValues, setFieldValue, submitCount, submitForm} = props;
  const {horseId} = useParams<RouteParams>();
  const {isMobile, isTablet, currentSize} = useMediaQuery();

  const [params, setParams] = useQueryParams<IFormValues>();

  const [isFiltersOpen, setIsFiltersOpen] = useState(true);
  const [isShowExcluded, setIsShowExcluded] = useState(values.isHideExcludedHorses);

  const navigate = useNavigate();
  const {currentVisitorType} = useVisitorTypeService();

  useOnRequestCommunication(foalsLoading, () => {
    if (isMobile && isFiltersOpen) {
      setIsFiltersOpen(false);
    }
  });

  useLayoutEffect(
    () => {
      const {currentPage, ...filters} = params;
      setValues({
        ...initialHorseFiltersValues,
        horseId: horseId ? Number(horseId) : undefined,
        ...filters,
        currentPage: currentPage || 1,
      });

      if (R.isEmpty(filters)) {
        return;
      }
      submitForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setParams({...values});
  }, [values, setParams]);

  useEffect(() => {
    if (isSubmitting) {
      setIsShowExcluded(values.isHideExcludedHorses);
    }
  }, [isSubmitting, values.isHideExcludedHorses]);

  const handlePageSelect = useCallback(
    (currentPage: number) => {
      scrollToTop();
      setFieldValue('currentPage', currentPage);
      submitForm();
    },
    [setFieldValue, submitForm]
  );

  const {excludeListModal, openExcludeListModal} = useExcludeListModal({});

  const isLoading =
    [favoriteHorseDeleting, favoriteHorseAdding, foalsLoading].some((i) => i.isRequesting) || isSubmitting;

  const isShowMoreColumns = !isFiltersOpen && isTablet;

  const hasSubmitted = submitCount !== 0;

  const toggleFilters = useCallback(() => {
    setIsFiltersOpen(!isFiltersOpen);
  }, [isFiltersOpen]);

  const redirectToBuildFoal = useCallback(() => {
    navigate(`/build-a-horse`);
    navigate(`${PREFIX_VISITOR_TYPE[currentVisitorType]}/foal`);
  }, [currentVisitorType, navigate]);

  const showExcluded = useCallback(() => {
    setFieldValue('isHideExcludedHorses', true);
    submitForm();
  }, [setFieldValue, submitForm]);

  const filtersToggle = useMemo(() => {
    return (
      <HeaderFiltersToggleIcon
        name={isFiltersOpen ? IconName.Close : IconName.FiltersToggle}
        size={isFiltersOpen ? 18 : 24}
        onClick={toggleFilters}
      />
    );
  }, [toggleFilters, isFiltersOpen]);

  const pageHeader = useMemo(() => {
    return <Header leftSection={filtersToggle} />;
  }, [filtersToggle]);

  const renderApplyButton = () => {
    const onSubmit = () => {
      setFieldValue('currentPage', 1);
      submitForm();
    };

    const button = (
      <ApplyButton onClick={onSubmit} isLoading={isLoading} type="button">
        Build
      </ApplyButton>
    );
    if (!isMobile) {
      return button;
    }
    if (isFiltersOpen) {
      return <FixedWrapper>{button}</FixedWrapper>;
    }
    return null;
  };

  const renderContent = (
    <>
      <Helmet>
        <title>Build a horse</title>
      </Helmet>
      <Root>
        {excludeListModal}

        <FiltersContainer isHidden={!isFiltersOpen} currentSize={currentSize}>
          <FiltersToggle onClick={toggleFilters} isFiltersOpen={isFiltersOpen}>
            <ColoredIcon
              name={isFiltersOpen ? IconName.ArrowDown : IconName.FiltersToggle}
              color={Theme.color.white}
              fill={true}
              stroke={false}
              size={isFiltersOpen ? 16 : 20}
            />
          </FiltersToggle>
          <Filters>
            <Form>
              <HorseFilters variant="bah" isLoading={isLoading} onOpenExcludeModal={openExcludeListModal} />
              {renderApplyButton()}
            </Form>
          </Filters>
        </FiltersContainer>
        <FoalsContainer isHidden={isFiltersOpen}>
          {(() => {
            if (foalsLoading.isRequesting) {
              return (
                <LoaderContainer>
                  <LongTimeLoading />
                </LoaderContainer>
              );
            }

            if (foalsLoading.error) {
              return <ErrorMessage>{getCommonErrors(foalsLoading.error) || UNKNOWN_ERROR}</ErrorMessage>;
            }

            if (foals.length > 0) {
              return (
                <FoalsList>
                  {!isShowExcluded && <ExcludePanel onShow={showExcluded} onOpenExcludeModal={openExcludeListModal} />}
                  <PotentialFoals foals={foals} showOneColumn={!isShowMoreColumns} />
                  {pagination.pageCount > 1 && <Pagination pagination={pagination} onPageSelect={handlePageSelect} />}
                </FoalsList>
              );
            }

            if (!hasSubmitted) {
              return (
                <PlaceholderContainer className="d-flex flex-column align-items-center">
                  <BuildFoalLink className="d-flex w-50 flex-column align-items-center" onClick={redirectToBuildFoal}>
                    <Icon name={IconName.GenderSymbol} size={32} color={ColorPalette.gray25} />
                    <>Want to choose parents manually?</>
                  </BuildFoalLink>
                  <BuildHorsePlaceholder />
                </PlaceholderContainer>
              );
            }

            return <EmptyFoalsMessage>{COULD_NOT_FIND_PARTNERS}</EmptyFoalsMessage>;
          })()}
        </FoalsContainer>
      </Root>
    </>
  );

  const {isVisitorType} = useVisitorTypeService();
  const isAssociation = isVisitorType(VisitorType.AssociationEmployee);

  return isAssociation ? (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} withoutPaddings={true} header={pageHeader}>
      {renderContent}
    </BusinessPortalLayout>
  ) : (
    <BaseLayout withoutPaddings={true} isBackButtonDenied={true} header={pageHeader}>
      {renderContent}
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  foals: selectors.selectFoals(state),
  foalsLoading: selectors.selectCommunication(state, 'foalsLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getFoals: actions.getFoals,
};

async function handleSubmit(values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) {
  const {props, setSubmitting} = formikBag;
  const {getFoals} = props;
  scrollToTop();

  const {currentPage, ...filters} = values;

  const convertedValues = convertHorseFiltersFormValues('bah', filters);

  await getFoals(convertedValues, {countPerPage: 10, currentPage: currentPage || 1});
  setSubmitting(false);
}

const BuildHorseFormik = withFormik<OuterProps, IFormValues>({
  handleSubmit,
})(memo(BuildHorse));

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(BuildHorseFormik);
export default withDynamicModules(withFavoriteHorseActions(Connected), [
  ...getWithFavoriteHorseActionsModules(),
  BuildHorseModule,
]);
