import React, {memo, useEffect, useMemo} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import {IOnlineReportGroupElementDetailed} from 'OnlineReport/models/shared/IOnlineReportGroupElementDetailed';
import {IOnlineReportGroupElement} from 'OnlineReport/models/shared/IOnlineReportGroupElement';
import {IAppState} from 'Common/store/IAppState';
import {actions, IOnlineReportState, OnlineReportActions, selectors} from 'OnlineReport/store/diagnostic/index';
import {
  actions as adminActions,
  IAdminOnlineReportState,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {OnlineReportModule} from 'OnlineReport/store/diagnostic/onlineReportModule';
import {OnlineReportGroupType} from 'OnlineReport/components/shared/OnlineReportGroupType';
import {Nullable} from 'Common/types';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';
import {breakpoints} from 'Common/constants/Breakpoints';

const mapTypeToCommunication: Record<
  OnlineReportGroupType,
  Partial<keyof IOnlineReportState['communications'] & keyof IAdminOnlineReportState['communications']>
> = {
  [OnlineReportGroupType.Color]: 'coatColorsDetailedLoading',
  [OnlineReportGroupType.HealthVariant]: 'healthVariantsDetailedLoading',
  [OnlineReportGroupType.Ability]: 'abilityDetailedLoading',
};

const mapTypeToSelector: Record<
  OnlineReportGroupType,
  (state: IAppState, isAdmin?: boolean) => Nullable<IOnlineReportGroupElementDetailed[]>
> = {
  [OnlineReportGroupType.Color]: (state, isAdmin) =>
    isAdmin ? adminSelectors.selectCoatColorDetailed(state) : selectors.selectCoatColorDetailed(state),
  [OnlineReportGroupType.HealthVariant]: (state, isAdmin) =>
    isAdmin ? adminSelectors.selectHealthVariantDetailed(state) : selectors.selectHealthVariantDetailed(state),
  [OnlineReportGroupType.Ability]: (state, isAdmin) =>
    isAdmin ? adminSelectors.selectAbilityDetailed(state) : selectors.selectAbilityDetailed(state),
};

const adminMapTypeToAction: Record<OnlineReportGroupType, any> = {
  [OnlineReportGroupType.Color]: adminActions.getCoatColorDetailed,
  [OnlineReportGroupType.HealthVariant]: (id: number, isAggregated?: boolean) =>
    adminActions.getHealthVariantDetailed(id, isAggregated),
  [OnlineReportGroupType.Ability]: (id: number, isAggregated?: boolean) =>
    adminActions.getAbilityDetailed(id, isAggregated),
};

const mapTypeToAction: Record<OnlineReportGroupType, any> = {
  [OnlineReportGroupType.Color]: actions.getCoatColorDetailed,
  [OnlineReportGroupType.HealthVariant]: (id: number, isAggregated?: boolean) =>
    actions.getHealthVariantDetailed(id, isAggregated),
  [OnlineReportGroupType.Ability]: (id: number, isAggregated?: boolean) => actions.getAbilityDetailed(id, isAggregated),
};

const InfoSection = styled.div`
  margin-bottom: 24px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 20px;
  color: ${ColorPalette.black0};
`;

const Bold = styled.span`
  font-weight: ${Typography.weight.medium500};

  @media ${breakpoints.sm} {
    white-space: nowrap;
  }
`;

interface IExternalProps {
  element: IOnlineReportGroupElement;
  isOpen?: boolean;
  type: OnlineReportGroupType;
  reportType: OnlineReportType;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const GroupElementBody = (props: AllProps) => {
  const {element, elementsDetailed, getElementDetailed, elementsDetailedLoading, isOpen = false} = props;
  const {id, aggregatorId, isAggregated} = element;

  const isAggregatedElement = useMemo(() => (!!aggregatorId && isAggregated) || false, [isAggregated, aggregatorId]);

  useEffect(() => {
    if (isOpen) {
      isAggregatedElement ? getElementDetailed(aggregatorId!, true) : getElementDetailed(id);
    }
  }, [isOpen, id, getElementDetailed, isAggregatedElement, aggregatorId]);

  const elementInfo = elementsDetailed
    ?.filter((x) => x !== null)
    .find((item) => (isAggregatedElement ? item.id === aggregatorId : item.id === id));

  const geneNames = elementInfo?.traits.map((i) => i.geneName) || [];

  const notNullableGeneNameList = geneNames.filter((i) => i && i?.length > 0);
  const nullableGeneNamesCount = geneNames.filter((i) => !i || i?.length === 0).length;
  const hasNullableGeneNames = nullableGeneNamesCount > 0;

  const geneListTooltipInfo =
    notNullableGeneNameList.length > 0
      ? notNullableGeneNameList.join(', ') + (hasNullableGeneNames ? ` and ${nullableGeneNamesCount} Not Defined` : '')
      : hasNullableGeneNames
      ? `${nullableGeneNamesCount} Not Defined`
      : undefined;

  if (elementsDetailedLoading.isRequesting && !elementInfo) {
    return (
      <div className="d-flex w-100 justify-content-center align-items-center">
        <IconedLoading />
      </div>
    );
  }

  if ((elementsDetailedLoading.isSuccess && elementInfo) || elementInfo) {
    return (
      <>
        {geneListTooltipInfo && geneListTooltipInfo.length > 0 && (
          <InfoSection>
            Gene or region: <Bold>{geneListTooltipInfo}</Bold>
          </InfoSection>
        )}
        <InfoSection>{elementInfo?.description}</InfoSection>
      </>
    );
  }

  return null;
};

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => ({
  elementsDetailed: mapTypeToSelector[externalProps.type](state, externalProps.reportType !== OnlineReportType.User),
  elementsDetailedLoading: (externalProps.reportType !== OnlineReportType.User
    ? adminSelectors
    : selectors
  ).selectCommunication(state, mapTypeToCommunication[externalProps.type]),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions>,
  externalProps: IExternalProps
) => ({
  getElementDetailed: (id: number, isAggregated?: boolean) =>
    dispatch(
      (externalProps.reportType !== OnlineReportType.User ? adminMapTypeToAction : mapTypeToAction)[externalProps.type](
        id,
        isAggregated
      )
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(GroupElementBody));
export default withDynamicModules(Connected, [AdminOnlineReportModule, OnlineReportModule]);
