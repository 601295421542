import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';

export interface IBlockUserActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(userId: number): void;
}

export interface IDeleteChatActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(chatId: number): void;
}

interface IChatActionsProps {
  blockUserAction: IBlockUserActionProps;
  deleteChatAction: IDeleteChatActionProps;
}

interface IProps {
  data: IChatActionsProps;
}

export function useChatActions(props: IProps) {
  const {
    data: {blockUserAction, deleteChatAction},
  } = props;

  const {confirmModal: addBlockedUserConfirmModal, openConfirmModal: openAddBlockedUserConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to block the user?',
    confirmCommunication: blockUserAction.communication,
    successMessage: 'User successfully blocked',
    onConfirmAction: blockUserAction.action,
    confirmId: 'addBlockedUserConfirm',
    onSuccess: blockUserAction.onSuccess,
  });

  const {confirmModal: deleteChatConfirmModal, openConfirmModal: openDeleteChatConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to delete the chat?',
    confirmCommunication: deleteChatAction.communication,
    successMessage: 'Chat history successfully deleted',
    onConfirmAction: deleteChatAction.action,
    confirmId: 'deleteChatConfirm',
    onSuccess: deleteChatAction.onSuccess,
  });

  const chatActionsModal = (
    <>
      {addBlockedUserConfirmModal}
      {deleteChatConfirmModal}
    </>
  );

  return {chatActionsModal, openAddBlockedUserConfirmModal, openDeleteChatConfirmModal};
}
