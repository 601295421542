import {memo} from 'react';
import styled from 'styled-components';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Loading from 'Loading/components/Loading';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {ChatRoot, ChatText} from '../../styled';
import IncomingMessage from '../Messages/IncomingMessage';
import {breakpoints} from 'Common/constants/Breakpoints';

const Buttons = styled.div`
  gap: 16px;
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

interface IProps {
  incomingUserRequest: IMessageCenterUser;
  isLoading: boolean;

  onAcceptRequest(chatRequestId: number): void;
  onRejectRequest(chatRequestId: number): void;
}

function IncomingRequest(props: IProps) {
  const {incomingUserRequest, onAcceptRequest, onRejectRequest, isLoading} = props;

  const onAcceptRequestClick = () => {
    onAcceptRequest(incomingUserRequest.id);
  };
  const onRejectRequestClick = () => {
    onRejectRequest(incomingUserRequest.id);
  };

  return (
    <ChatRoot className="d-flex flex-column">
      {isLoading && <Loading />}

      <IncomingMessage message={incomingUserRequest.message} url={incomingUserRequest.user.avatar?.url} />
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>You have incoming request to start conversation. Would you like to chat with this user?</ChatText>

        <Buttons className="d-flex align-items-center">
          <PrimaryButton size="small" onClick={onAcceptRequestClick}>
            Accept request
          </PrimaryButton>
          <PrimaryButton size="small" onClick={onRejectRequestClick}>
            Reject request
          </PrimaryButton>
        </Buttons>
      </div>
    </ChatRoot>
  );
}

export default memo(IncomingRequest);
