import {Nullable} from 'Common/types';
import {IUsersChatMessage} from 'MessageCenter/models/IUsersChatMessage';
import {IUserToAdminChatMessage} from 'MessageCenter/models/IUserToAdminChatMessage';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';

export function convertUserChatMesssageToIMessageQueue(
  value: IUsersChatMessage,
  currentUserId: number,
  chatUserId: number
): IMessageQueue {
  return {
    id: value.id,
    fromUserId: value.userId === currentUserId ? currentUserId : chatUserId,
    toUserId: value.userId === currentUserId ? chatUserId : currentUserId,
    message: value.message,
    createDate: value.createDate,
  };
}

export function filterMessages(
  value: IMessageQueue[],
  currentUserId: Nullable<number>,
  chatUserId: Nullable<number>
): IMessageQueue[] {
  return value.filter((x) => x.fromUserId === chatUserId && x.toUserId === currentUserId);
}

export function convertUserToAdminChatMesssageToIMessageQueue(
  value: IUserToAdminChatMessage,
  currentUserId: number
): IMessageQueue[] {
  const userMessages: IMessageQueue[] = value.userMessages.map((x) => {
    return {
      id: x.id,
      fromUserId: x.userId,
      message: x.message,
      toUserId: null,
      createDate: x.createDate,
      images: x.images,
      isRead: x.isRead,
    };
  });

  const adminMessages: IMessageQueue[] = value.adminMessages.map((x) => ({
    id: x.id,
    fromUserId: null,
    message: x.message,
    toUserId: currentUserId,
    createDate: x.createDate,
    images: x.images,
    isRead: x.isRead,
  }));

  const dataToSave = userMessages.concat(adminMessages).sort((a, b) => {
    const aDate = a.createDate ? new Date(a.createDate).getTime() : 0;
    const bDate = b.createDate ? new Date(b.createDate).getTime() : 0;
    return aDate - bDate;
  });

  return dataToSave;
}
