import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {AdminChatStatus} from 'MessageCenter/constants/AdminChatStatus';
import {IBlockedUser} from 'MessageCenter/models/IBlockedUser';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {IUsersChatMessage} from 'MessageCenter/models/IUsersChatMessage';
import {IUserToAdminChatDetails} from 'MessageCenter/models/IUserToAdminChatDetails';
import {IUserToAdminChatMessage} from 'MessageCenter/models/IUserToAdminChatMessage';

const STATE_NAME = 'userMessageCenter';

export interface IUserMessageCenterState {
  data: {
    messageCenterUsers: IMessageCenterUser[];
    usersChatMessages: IUsersChatMessage[];
    userToAdminChatMessages: Nullable<IUserToAdminChatMessage>;
    userToAdminChatDetails: Nullable<IUserToAdminChatDetails>;
    blockedUsers: IBlockedUser[];
    userToAdminChatIsOnline: boolean;
    userToAdminChatStatus: AdminChatStatus;
  };
  communications: {
    receivedUsersChatsRequestsLoading: ICommunication;
    usersChatLoading: ICommunication;
    usersChatRequestsLoading: ICommunication;
    rejectedIncomingUsersChatRequestsLoading: ICommunication;
    rejectedOutgoingUsersChatRequestsLoading: ICommunication;
    usersChatRequestAccepting: ICommunication;
    usersChatRequestClosing: ICommunication;
    usersChatRequestRejecting: ICommunication;
    usersChatMessagesLoading: ICommunication;
    usersChatRequestRejectCancelling: ICommunication;
    userToAdminChatMessagesLoading: ICommunication;
    userToAdminChatDetailsLoading: ICommunication;
    userToAdminChatCreating: ICommunication;
    blockedUsersLoading: ICommunication;
    blockedUserAdding: ICommunication;
    blockedUserDeleting: ICommunication;
    userToAdminChatIsOnlineLoading: ICommunication;
    userToAdminChatStatusLoading: ICommunication;
    userToUserChatDeleting: ICommunication;
    userToUserChatMessageDeleting: ICommunication;
  };
}

export const getUsersChatsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USERS_CHATS');
export type getUsersChatsActions = MakeCommunicationActions<
  typeof getUsersChatsActionTypes,
  {success: IMessageCenterUser[]}
>;

export const getReceivedUsersChatsRequestsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_RECEIVED_USERS_CHATS_REQUESTS'
);
export type getReceivedUsersChatsRequestsActions = MakeCommunicationActions<
  typeof getReceivedUsersChatsRequestsActionTypes,
  {success: IMessageCenterUser[]}
>;

export const getUsersChatRequestsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USERS_CHAT_REQUESTS');
export type getUsersChatRequestsActions = MakeCommunicationActions<
  typeof getUsersChatRequestsActionTypes,
  {success: IMessageCenterUser[]}
>;

export const getRejectedIncomingUsersChatsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_REJECTED_INCOMING_USERS_CHATS'
);
export type getRejectedIncomingUsersChatsActions = MakeCommunicationActions<
  typeof getRejectedIncomingUsersChatsActionTypes,
  {success: IMessageCenterUser[]}
>;

export const getRejectedOutgoingUsersChatsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_REJECTED_OUTGOING_USERS_CHATS'
);
export type getRejectedOutgoingUsersChatsActions = MakeCommunicationActions<
  typeof getRejectedOutgoingUsersChatsActionTypes,
  {success: IMessageCenterUser[]}
>;

export const getUsersChatMessagesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USERS_CHAT_MESSAGES');
export type getUsersChatMessagesActions = MakeCommunicationActions<
  typeof getUsersChatMessagesActionTypes,
  {success: IUsersChatMessage[]}
>;

export const getUserToAdminChatMessagesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_MESSAGES'
);
export type getUserToAdminChatMessagesActions = MakeCommunicationActions<
  typeof getUserToAdminChatMessagesActionTypes,
  {success: IUserToAdminChatMessage}
>;

export const acceptUsersChatsRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'ACCEPT_USERS_CHAT_REQUEST');
export type acceptUsersChatRequestActions = MakeCommunicationActions<typeof acceptUsersChatsRequestActionTypes, {}>;

export const closeUsersChatsRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'CLOSE_USERS_CHAT_REQUEST');
export type closeUsersChatRequestActions = MakeCommunicationActions<typeof closeUsersChatsRequestActionTypes, {}>;

export const rejectUsersChatsRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'REJECT_USERS_CHAT_REQUEST');
export type rejectUsersChatRequestActions = MakeCommunicationActions<typeof rejectUsersChatsRequestActionTypes, {}>;

export const cancelRejectUsersChatsRequestActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CANCEL_REJECT_USERS_CHAT_REQUEST'
);
export type cancelRejectUsersChatRequestActions = MakeCommunicationActions<
  typeof cancelRejectUsersChatsRequestActionTypes,
  {}
>;

export const getUserToAdminChatDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_DETAILS'
);
export type getUserToAdminChatDetailsActions = MakeCommunicationActions<
  typeof getUserToAdminChatDetailsActionTypes,
  {success: IUserToAdminChatDetails}
>;

export const createUserToAdminChatsRequestActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'CREATE_USER_TO_ADMIN_CHAT_REQUEST'
);
export type createUserToAdminChatRequestActions = MakeCommunicationActions<
  typeof createUserToAdminChatsRequestActionTypes,
  {}
>;

export const getBlockedUsersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_BLOCKED_USERS');
export type getBlockedUsersActions = MakeCommunicationActions<
  typeof getBlockedUsersActionTypes,
  {success: IBlockedUser[]}
>;

export const addBlockedUserActionTypes = makeCommunicationActionType(STATE_NAME, 'ADD_BLOCKED_USER');
export type addBlockedUserActions = MakeCommunicationActions<typeof addBlockedUserActionTypes, {success: number}>;

export const deleteBlockedUserActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_BLOCKED_USER');
export type deleteBlockedUserActions = MakeCommunicationActions<typeof deleteBlockedUserActionTypes, {success: number}>;

export const getUserToAdminChatIsOnlineActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_ISONLINE'
);
export type getUserToAdminChatIsOnlineActions = MakeCommunicationActions<
  typeof getUserToAdminChatIsOnlineActionTypes,
  {success: boolean}
>;

export const getUserToAdminChatStatusActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_STATUS'
);
export type getUserToAdminChatStatusActions = MakeCommunicationActions<
  typeof getUserToAdminChatStatusActionTypes,
  {success: AdminChatStatus}
>;

export const deleteUserToUserChatActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_USER_TO_USER_CHAT');
export type deleteUserToUserChatActions = MakeCommunicationActions<typeof deleteUserToUserChatActionTypes, {}>;

export const deleteUserToUserChatMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DELETE_USER_TO_USER_CHAT_MESSAGE'
);
export type deleteUserToUserChatMessageActions = MakeCommunicationActions<
  typeof deleteUserToUserChatMessageActionTypes,
  {}
>;

export const resetBlockedUsersActionTypes = makeResetCommunicationActionType(STATE_NAME, 'BLOCKED_USERS');
export type resetBlockedUsersActions = MakeResetCommunicationActions<typeof resetBlockedUsersActionTypes>;

export const resetUserToAdminChatDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'USER_TO_ADMIN_CHAT_DETAILS'
);
export type resetUserToAdminChatDetailsActions = MakeResetCommunicationActions<
  typeof resetUserToAdminChatDetailsActionTypes
>;

export type Actions =
  | getReceivedUsersChatsRequestsActions
  | getUsersChatsActions
  | getUsersChatRequestsActions
  | getUsersChatMessagesActions
  | getUserToAdminChatMessagesActions
  | getRejectedIncomingUsersChatsActions
  | getRejectedOutgoingUsersChatsActions
  | acceptUsersChatRequestActions
  | closeUsersChatRequestActions
  | rejectUsersChatRequestActions
  | cancelRejectUsersChatRequestActions
  | getUserToAdminChatDetailsActions
  | createUserToAdminChatRequestActions
  | getBlockedUsersActions
  | addBlockedUserActions
  | deleteBlockedUserActions
  | getUserToAdminChatStatusActions
  | getUserToAdminChatIsOnlineActions
  | deleteUserToUserChatActions
  | deleteUserToUserChatMessageActions
  | resetBlockedUsersActions
  | resetUserToAdminChatDetailsActions;
