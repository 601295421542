import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import {generatePath, useLocation, useNavigate, useParams} from 'react-router-dom';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import ButtonAsLink from 'Common/components/Controls/Buttons/ButtonAsLink';
import {ExpansionPanel, Section} from 'Common/components/ExpansionPanel/ExpansionPanel';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import {SectionHint} from 'Filters/components/HorseFilters/parts/shared';
import Icon, {IconName} from 'Icon/components/Icon';
import Loading from 'Loading/components/Loading';
import {ChatType} from 'MessageCenter/constants/ChatType';
import {useChatActions} from 'MessageCenter/hooks/useChatActions';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {actions, selectors} from 'MessageCenter/store';
import {MessageCenterModule} from 'MessageCenter/store/module';
import {connect, ConnectedProps} from 'react-redux';
import {selectors as userToAdminSelectors} from 'SignalR/store/userToAdmin';
import {actions as userToUserActions, selectors as userToUserSelectors} from 'SignalR/store/userToUser';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import BlockedUsers from './parts/BlockedUsers/BlockedUsers';
import ChatForm from './parts/ChatForm';
import ChatHeader from './parts/ChatHeader';
import ChatSupport from './parts/ChatSupport/ChatSupport';
import ChatUser from './parts/ChatUser';
import EmptyChat from './parts/EmptyChat';
import EmptyRequest from './parts/Requests/EmptyRequest';
import IncomingRequest from './parts/Requests/IncomingRequest';
import OutgoingRequest from './parts/Requests/OutgoingRequest';
import RejectedIncomingRequest from './parts/Requests/RejectedIncomingRequest';
import RejectedOutgoingRequest from './parts/Requests/RejectedOutgoingRequest';
import {SubTitle, SubTitleContainer} from './styled';
import Theme from 'Common/constants/Theme';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import {INotificationMessage} from 'SignalR/models/INotificationMessage';
import {NotificationEventType} from 'Notifications/const/NotificationEventType';
import {mapChatTypeToRoute} from 'MessageCenter/helpers/mapChatTypeToRoute';

const INCOMING_REQUESTS_HINT = 'Incoming requests to conversation from another users.';
const OUTGOING_REQUESTS_HINT = 'Outgoing requests to conversation to another users.';
const SUPPORT_SUBTITLE = 'Please note the hours of operation are Monday-Friday 9am-5pm PST';

const supportUser: IChatUser = {id: 0, name: 'Support', isOnline: false, avatar: null};

const Root = styled.div`
  width: 100%;
  padding: 8px 8px;

  @media ${breakpoints.md} {
    padding: 48px 48px 0;
  }

  @media ${breakpoints.lg} {
    width: 80%;
  }
`;

const PageHeader = styled.div`
  margin-bottom: 16px;
  @media ${breakpoints.md} {
    margin-bottom: 32px;
  }
`;

export const PageTitle = styled.div`
  color: ${ColorPalette.black0};
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size24};
  line-height: 33px;
`;

const ChatWrapper = styled.div`
  position: relative;
  border: 1px solid ${ColorPalette.gray48};
  border-radius: 6px;
  box-shadow: 0px 6px 14px ${ColorPalette.transparent5}, 0px -6px 14px ${ColorPalette.transparent5};

  min-height: 48px;
  height: 75vh;
  overflow: hidden;

  @media ${breakpoints.md} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    overflow: auto;
  }
`;

const UserListContainer = styled.div<{isVisible: boolean}>`
  width: 100%;
  height: 100%;
  padding: 0 8px 8px;
  border-right: 1px solid ${ColorPalette.gray48};
  background: ${ColorPalette.gray38};
  border-radius: 6px;

  @media (max-width: ${size.sm}px) {
    position: absolute;
    z-index: 2;
    left: 0;
    transition: left 0.2s;
    ${(props) => !props.isVisible && `left: -100%`}
  }

  @media ${breakpoints.sm} {
    padding: 0 16px 16px;
    border-radius: 6px 0 0 6px;
  }
`;

const ChatContent = styled.div<{isVisible: boolean}>`
  display: grid;
  grid-template-rows: 56px auto;
  width: 100%;
  height: 100%;

  @media ${breakpoints.sm} {
    grid-template-rows: 48px auto;
  }

  @media (max-width: ${size.sm}px) {
    position: absolute;
    z-index: 1;
  }
`;

const UserListToggle = styled.div<{isUserListOpen?: boolean}>`
  position: absolute;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Theme.color.primary};
  z-index: 3;
  cursor: pointer;

  @media (max-width: ${size.sm}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    transition: left 0.2s;
    ${(p) =>
      p.isUserListOpen ? `transform: rotate(90deg); left: calc(100% - 50px);` : `transform: rotate(-90deg); left: 6px`};
  }
`;

const ChatItem = styled.div<{isActive: boolean}>`
  gap: 16px;
  padding: 0 8px;
  cursor: pointer;

  ${(props) => props.isActive && `background-color: ${ColorPalette.gray50};`};

  &:hover {
    background-color: ${ColorPalette.gray50};
  }

  @media ${breakpoints.sm} {
    padding: 0 16px;
  }
`;

const Chat = styled.div`
  min-height: 48px;
  margin-bottom: 8px;

  @media ${breakpoints.sm} {
    margin-bottom: 20px;
  }
`;

const RequestsContainer = styled.div`
  gap: 8px;

  @media ${breakpoints.sm} {
    gap: 16px;
  }
`;

type RouteParams = {chatType?: string; userId?: string};

type IConnected = ConnectedProps<typeof connector>;

type IProps = IConnected;

function MessageCenter(props: IProps) {
  const {
    getReceivedUsersChatsRequests,
    getUsersChats,
    getUsersChatRequests,
    getRejectedIncomingChatRequests,
    getRejectedOutgoingChatRequests,
    acceptUsersChatRequest,
    rejectUsersChatRequest,
    closeUsersChatRequest,
    cancelRejectUsersChatRequest,
    messageCenterUsers,
    rejectedIncomingUsersChatRequestsLoading,
    rejectedOutgoingUsersChatRequestsLoading,
    receivedUsersChatsRequestsLoading,
    usersChatLoading,
    usersChatRequestsLoading,
    usersChatRequestRejecting,
    usersChatRequestAccepting,
    usersChatRequestClosing,
    usersChatRequestRejectCancelling,
    currentUser,
    connectedUsers,
    messageQueue,
    isConnectedAdmin,
    sendMessage,
    setConnectedUser,
    deleteMessagesFromQueue,
    addBlockedUser,
    blockedUserAdding,
    getUserToAdminChatIsOnline,
    userToAdminChatIsOnline,
    userToAdminChatIsOnlineLoading,
    deleteUserToUserChat,
    userToUserChatDeleting,
  } = props;

  const [ownUser, setOwnUser] = useState<IChatUser>();
  const [selectedMessageCenterUser, setSelectedMessageCenterUser] = useState<Nullable<IMessageCenterUser>>();
  const [selectedChatType, setSelectedChatType] = useState<Nullable<ChatType>>();
  const [isOpenBlockedUsersModal, setIsOpenBlockedUsersModal] = useState(false);
  const [isSupportUserOnline, setIsSupportUserOnline] = useState<boolean>(false);
  const [isUserListOpen, setIsUserListOpen] = useState(true);
  const [isIncomingRequestOpened, setIsIncomingRequestOpened] = useState(false);
  const [isOutgoingRequestOpened, setIsOutgoingRequestOpened] = useState(false);

  const {isMobile} = useMediaQuery();
  const {listen} = useEventBus();
  const navigate = useNavigate();
  const {chatType, userId} = useParams<RouteParams>();
  const location = useLocation();

  const usersChats = useMemo(
    () => messageCenterUsers.filter((x) => x.chatType === ChatType.Messages),
    [messageCenterUsers]
  );
  const incomingUsersChatRequests = useMemo(
    () => messageCenterUsers.filter((x) => x.chatType === ChatType.IncomingRequest),
    [messageCenterUsers]
  );
  const outgoingUsersChatRequests = useMemo(
    () => messageCenterUsers.filter((x) => x.chatType === ChatType.OutgoingRequest),
    [messageCenterUsers]
  );
  const rejectedIncomingUsersChatRequests = useMemo(
    () => messageCenterUsers.filter((x) => x.chatType === ChatType.RejectedIncomingRequest),
    [messageCenterUsers]
  );
  const rejectedOutgoingUsersChatRequests = useMemo(
    () => messageCenterUsers.filter((x) => x.chatType === ChatType.RejectedOutgoingRequest),
    [messageCenterUsers]
  );

  const defaultConnected = useMemo(
    () =>
      messageCenterUsers
        .filter((x) => x.user.isOnline)
        .map((y) => y.user.id)
        .filter((value, idx, self) => self.indexOf(value) === idx),
    [messageCenterUsers]
  );

  const reloadChats = useCallback(
    (chatType?: ChatType[]) => {
      if (!chatType || chatType.includes(ChatType.Messages)) {
        getUsersChats();
      }
      if (!chatType || chatType.includes(ChatType.IncomingRequest)) {
        getReceivedUsersChatsRequests();
      }
      if (!chatType || chatType.includes(ChatType.OutgoingRequest)) {
        getUsersChatRequests();
      }
      if (!chatType || chatType.includes(ChatType.RejectedIncomingRequest)) {
        getRejectedIncomingChatRequests();
      }
      if (!chatType || chatType.includes(ChatType.RejectedOutgoingRequest)) {
        getRejectedOutgoingChatRequests();
      }
      if (!chatType || chatType.includes(ChatType.Support)) {
        getUserToAdminChatIsOnline();
      }
    },
    [
      getReceivedUsersChatsRequests,
      getRejectedIncomingChatRequests,
      getRejectedOutgoingChatRequests,
      getUserToAdminChatIsOnline,
      getUsersChatRequests,
      getUsersChats,
    ]
  );

  const reloadChatsByNotification = useCallback(
    (message: INotificationMessage) => {
      const {code: type} = message;

      switch (type) {
        case NotificationEventType.ChatRequestAccepted: {
          reloadChats([ChatType.OutgoingRequest, ChatType.Messages]);
          break;
        }
        case NotificationEventType.ChatRequestCreated: {
          reloadChats([ChatType.OutgoingRequest]);
          break;
        }
        case NotificationEventType.ChatRequestReceived: {
          reloadChats([ChatType.IncomingRequest]);
          break;
        }
        case NotificationEventType.ChatRequestRejected: {
          reloadChats([ChatType.OutgoingRequest, ChatType.RejectedOutgoingRequest]);
          break;
        }
        case NotificationEventType.ContactRequested: {
          reloadChats([ChatType.IncomingRequest]);
          break;
        }
      }
    },
    [reloadChats]
  );

  useEffect(() => {
    reloadChats();
    setOwnUser({id: currentUser!.id, avatar: currentUser!.avatar, name: currentUser!.name!, isOnline: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Route props to open chat after reload page (:chatType, :userId)
  useEffect(() => {
    if (!chatType) {
      return;
    }

    if (chatType.toLowerCase() === ChatType.Support.toLowerCase()) {
      setSelectedMessageCenterUser(null);
      setSelectedChatType(ChatType.Support);
      return;
    }

    if (!userId) {
      return;
    }

    const findChatItem = messageCenterUsers.find((x) => x.user.id === +userId);
    if (findChatItem) {
      setSelectedMessageCenterUser(findChatItem);
      setSelectedChatType(findChatItem.chatType);

      if (
        findChatItem.chatType === ChatType.IncomingRequest ||
        findChatItem.chatType === ChatType.RejectedIncomingRequest
      ) {
        setIsIncomingRequestOpened(true);
      }

      if (
        findChatItem.chatType === ChatType.OutgoingRequest ||
        findChatItem.chatType === ChatType.RejectedOutgoingRequest
      ) {
        setIsOutgoingRequestOpened(true);
      }
    }
  }, [chatType, messageCenterUsers, userId]);

  // Set new Route after open chat
  useEffect(() => {
    if (selectedChatType) {
      const newChatTypeRoute = mapChatTypeToRoute[selectedChatType];
      const newUserId = selectedMessageCenterUser?.user.id.toString();
      const newParams =
        selectedChatType === ChatType.Support
          ? {chatType: newChatTypeRoute}
          : {chatType: newChatTypeRoute, userId: newUserId};
      const newPath = generatePath(location.pathname, newParams);
      navigate(newPath, {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatType, selectedMessageCenterUser?.user.id]);

  useEffect(() => {
    const notificationReceived = listen(EventName.SystemNotificationReceived, reloadChatsByNotification);

    return () => notificationReceived.stopListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultConnected && defaultConnected.length > 0) {
      defaultConnected.forEach((x) => setConnectedUser(x));
    }
  }, [defaultConnected, setConnectedUser]);

  useEffect(() => {
    setIsSupportUserOnline(userToAdminChatIsOnline);
  }, [userToAdminChatIsOnline]);

  useEffect(() => {
    setIsSupportUserOnline(!!isConnectedAdmin);
  }, [isConnectedAdmin]);

  useOnSuccessCommunication(usersChatRequestAccepting, () => {
    reloadChats([ChatType.Messages, ChatType.IncomingRequest]);
    setSelectedChatType(ChatType.Messages);
  });
  useOnSuccessCommunication(usersChatRequestRejecting, () => {
    reloadChats([ChatType.IncomingRequest, ChatType.RejectedIncomingRequest]);
    setSelectedChatType(ChatType.RejectedIncomingRequest);
  });
  useOnSuccessCommunication(usersChatRequestClosing, () => {
    reloadChats([ChatType.OutgoingRequest, ChatType.RejectedOutgoingRequest]);
    setSelectedMessageCenterUser(null);
  });
  useOnSuccessCommunication(usersChatRequestRejectCancelling, () => {
    reloadChats([ChatType.IncomingRequest, ChatType.RejectedIncomingRequest]);
    setSelectedChatType(ChatType.IncomingRequest);
  });

  const onToggleUserList = useCallback(() => {
    setIsUserListOpen(!isUserListOpen);
  }, [isUserListOpen]);

  const onSupportClick = useCallback(() => {
    setSelectedMessageCenterUser(null);
    setSelectedChatType(ChatType.Support);
    onToggleUserList();
  }, [onToggleUserList]);

  const isSupportActive = selectedChatType === ChatType.Support;

  const onBlockUserSuccess = useCallback(() => {
    setSelectedMessageCenterUser(null);
    setSelectedChatType(null);
    reloadChats();
  }, [reloadChats]);

  const onDeleteChatSuccess = useCallback(() => {
    setSelectedMessageCenterUser(null);
    setSelectedMessageCenterUser(selectedMessageCenterUser);
  }, [selectedMessageCenterUser]);

  const {chatActionsModal, openAddBlockedUserConfirmModal, openDeleteChatConfirmModal} = useChatActions({
    data: {
      blockUserAction: {action: addBlockedUser, communication: blockedUserAdding, onSuccess: onBlockUserSuccess},
      deleteChatAction: {
        action: deleteUserToUserChat,
        communication: userToUserChatDeleting,
        onSuccess: onDeleteChatSuccess,
      },
    },
  });

  const isLoading = [
    receivedUsersChatsRequestsLoading,
    usersChatLoading,
    usersChatRequestsLoading,
    rejectedIncomingUsersChatRequestsLoading,
    rejectedOutgoingUsersChatRequestsLoading,
    blockedUserAdding,
    userToAdminChatIsOnlineLoading,
  ].some((x) => x.isRequesting);

  const openBlockedUserstModal = useCallback(() => setIsOpenBlockedUsersModal(true), []);
  const closeBlockedUsersModal = useCallback(() => setIsOpenBlockedUsersModal(false), []);

  const isShowEmptyChat = !selectedMessageCenterUser && !selectedChatType;

  return (
    <BaseLayout withoutPaddings={isMobile}>
      <Helmet>
        <title>Message center</title>
      </Helmet>

      {chatActionsModal}

      <ModalWindow isOpen={isOpenBlockedUsersModal} onClose={closeBlockedUsersModal}>
        <BlockedUsers onSuccess={reloadChats} />
      </ModalWindow>

      <Root className="d-flex flex-column flex-grow-1 ">
        <PageHeader className="d-flex align-items-center justify-content-between">
          <PageTitle>Message Center</PageTitle>
          <ButtonAsLink className="ml-auto" style={{marginRight: 12}} onClick={openBlockedUserstModal}>
            Blocked users
          </ButtonAsLink>
        </PageHeader>

        <ChatWrapper>
          <UserListToggle onClick={onToggleUserList} isUserListOpen={isUserListOpen}>
            <ColoredIcon name={IconName.ArrowDown} color={Theme.color.white} fill={true} stroke={false} size={16} />
          </UserListToggle>

          <UserListContainer className="d-flex flex-column" isVisible={isUserListOpen}>
            {isLoading && <Loading />}

            <SubTitleContainer>
              <SubTitle>Support</SubTitle>
            </SubTitleContainer>
            <Chat className="d-flex flex-column">
              <ChatItem className="d-flex align-items-center" onClick={onSupportClick} isActive={isSupportActive}>
                <ChatUser user={supportUser} isOnline={isSupportUserOnline} isSupport={true} />
                <Icon name={IconName.Chat} size={28} className="ml-auto" />
              </ChatItem>
            </Chat>

            <SubTitleContainer>
              <SubTitle>Messages</SubTitle>
            </SubTitleContainer>
            <Chat className="d-flex flex-column">
              <Scrollbar
                maxHeight="100%"
                autoHeight={true}
                renderView={({style, ...scrollbarProps}: any) => (
                  <div style={{...style, paddingRight: 0}} {...scrollbarProps} />
                )}
              >
                {usersChats.length === 0 && <EmptyRequest />}
                {usersChats.map((item, i) => {
                  const isUserOnline = connectedUsers.includes(item.user.id);
                  const isActive =
                    selectedChatType === ChatType.Messages && item.user.id === selectedMessageCenterUser?.user.id;
                  const hasMessages = messageQueue.some((x) => x.fromUserId === item.user.id);
                  const onOpenUserChat = () => {
                    setSelectedMessageCenterUser(item);
                    setSelectedChatType(ChatType.Messages);
                    onToggleUserList();
                  };
                  return (
                    <ChatItem
                      key={i}
                      className="d-flex align-items-center"
                      isActive={isActive}
                      onClick={onOpenUserChat}
                    >
                      <ChatUser user={item.user} isOnline={isUserOnline} />
                      {hasMessages ? (
                        <Icon name={IconName.ChatUnread} size={28} className="ml-auto" />
                      ) : (
                        <Icon name={IconName.Chat} size={28} className="ml-auto" />
                      )}
                    </ChatItem>
                  );
                })}
              </Scrollbar>
            </Chat>

            <RequestsContainer className="mt-auto d-flex flex-column">
              <ExpansionPanel
                title="Incoming requests"
                headerContent={<SectionHint count={incomingUsersChatRequests.length} hint={INCOMING_REQUESTS_HINT} />}
                headerStyle={isMobile ? {padding: '8px 16px 6px'} : {padding: '16px 16px 12px'}}
                isOpenManually={isIncomingRequestOpened}
              >
                <Section>
                  <Chat className="d-flex flex-column">
                    <Scrollbar
                      maxHeight="100%"
                      autoHeight={true}
                      renderView={({style, ...scrollbarProps}: any) => (
                        <div style={{...style, paddingRight: 0}} {...scrollbarProps} />
                      )}
                    >
                      {incomingUsersChatRequests.length === 0 && rejectedIncomingUsersChatRequests.length === 0 && (
                        <EmptyRequest />
                      )}
                      {incomingUsersChatRequests.map((item, i) => {
                        const isUserOnline = connectedUsers.includes(item.user.id);
                        const isActive =
                          selectedChatType === ChatType.IncomingRequest &&
                          item.user.id === selectedMessageCenterUser?.user.id;
                        const onClick = () => {
                          setSelectedMessageCenterUser(item);
                          setSelectedChatType(ChatType.IncomingRequest);
                          onToggleUserList();
                        };
                        return (
                          <ChatItem key={i} className="d-flex align-items-center" isActive={isActive} onClick={onClick}>
                            <ChatUser user={item.user} isOnline={isUserOnline} />
                            <Icon name={IconName.ChatIncoming} size={28} className="ml-auto" />
                          </ChatItem>
                        );
                      })}
                      {rejectedIncomingUsersChatRequests.map((item, i) => {
                        const isUserOnline = connectedUsers.includes(item.user.id);
                        const isActive =
                          selectedChatType === ChatType.RejectedIncomingRequest &&
                          item.user.id === selectedMessageCenterUser?.user.id;
                        const onClick = () => {
                          setSelectedMessageCenterUser(item);
                          setSelectedChatType(ChatType.RejectedIncomingRequest);
                          onToggleUserList();
                        };
                        return (
                          <ChatItem key={i} className="d-flex align-items-center" isActive={isActive} onClick={onClick}>
                            <ChatUser user={item.user} isOnline={isUserOnline} />
                            <Icon name={IconName.ChatIncomingLock} size={28} className="ml-auto" />
                          </ChatItem>
                        );
                      })}
                    </Scrollbar>
                  </Chat>
                </Section>
              </ExpansionPanel>

              <ExpansionPanel
                title="Outgoing requests"
                headerContent={<SectionHint count={outgoingUsersChatRequests.length} hint={OUTGOING_REQUESTS_HINT} />}
                headerStyle={isMobile ? {padding: '8px 16px 6px'} : {padding: '16px 16px 12px'}}
                isOpenManually={isOutgoingRequestOpened}
              >
                <Section>
                  <Chat className="d-flex flex-column">
                    <Scrollbar
                      maxHeight="100%"
                      autoHeight={true}
                      renderView={({style, ...scrollbarProps}: any) => (
                        <div style={{...style, paddingRight: 0}} {...scrollbarProps} />
                      )}
                    >
                      {outgoingUsersChatRequests.length === 0 && rejectedOutgoingUsersChatRequests.length === 0 && (
                        <EmptyRequest />
                      )}
                      {outgoingUsersChatRequests.map((item, i) => {
                        const isUserOnline = connectedUsers.includes(item.user.id);
                        const isActive =
                          selectedChatType === ChatType.OutgoingRequest &&
                          item.user.id === selectedMessageCenterUser?.user.id;
                        const onClick = () => {
                          setSelectedMessageCenterUser(item);
                          setSelectedChatType(ChatType.OutgoingRequest);
                          onToggleUserList();
                        };
                        return (
                          <ChatItem key={i} className="d-flex align-items-center" isActive={isActive} onClick={onClick}>
                            <ChatUser user={item.user} isOnline={isUserOnline} />
                            <Icon name={IconName.ChatOutgoing} size={28} className="ml-auto" />
                          </ChatItem>
                        );
                      })}
                      {rejectedOutgoingUsersChatRequests.map((item, i) => {
                        const isUserOnline = connectedUsers.includes(item.user.id);
                        const isActive =
                          selectedChatType === ChatType.RejectedOutgoingRequest &&
                          item.user.id === selectedMessageCenterUser?.user.id;
                        const onClick = () => {
                          setSelectedMessageCenterUser(item);
                          setSelectedChatType(ChatType.RejectedOutgoingRequest);
                          onToggleUserList();
                        };
                        return (
                          <ChatItem key={i} className="d-flex align-items-center" isActive={isActive} onClick={onClick}>
                            <ChatUser user={item.user} isOnline={isUserOnline} />
                            <Icon name={IconName.ChatOutgoingLock} size={28} className="ml-auto" />
                          </ChatItem>
                        );
                      })}
                    </Scrollbar>
                  </Chat>
                </Section>
              </ExpansionPanel>
            </RequestsContainer>
          </UserListContainer>

          <ChatContent isVisible={!isUserListOpen}>
            {ownUser && selectedChatType === ChatType.Messages && selectedMessageCenterUser && (
              <>
                <ChatHeader
                  title={selectedMessageCenterUser.user.name}
                  onBlockUser={() => openAddBlockedUserConfirmModal(selectedMessageCenterUser.user.id)}
                  onDeleteChat={() => openDeleteChatConfirmModal(selectedMessageCenterUser.id)}
                />
                <ChatForm
                  messageQueue={messageQueue}
                  chatUser={selectedMessageCenterUser}
                  currentUser={ownUser}
                  onSendMessage={sendMessage}
                  deleteMessagesFromQueue={deleteMessagesFromQueue}
                />
              </>
            )}

            {selectedChatType === ChatType.IncomingRequest && selectedMessageCenterUser && (
              <>
                <ChatHeader
                  title={selectedMessageCenterUser.user.name}
                  subtitle="Incoming request"
                  onBlockUser={() => openAddBlockedUserConfirmModal(selectedMessageCenterUser.user.id)}
                  showDeleteChatMenuItem={false}
                />
                <IncomingRequest
                  incomingUserRequest={selectedMessageCenterUser}
                  isLoading={usersChatRequestRejecting.isRequesting || usersChatRequestAccepting.isRequesting}
                  onAcceptRequest={acceptUsersChatRequest}
                  onRejectRequest={rejectUsersChatRequest}
                />
              </>
            )}

            {selectedChatType === ChatType.OutgoingRequest && selectedMessageCenterUser && (
              <>
                <ChatHeader
                  title={selectedMessageCenterUser.user.name}
                  subtitle="Outgoing request"
                  onBlockUser={() => openAddBlockedUserConfirmModal(selectedMessageCenterUser.user.id)}
                  showDeleteChatMenuItem={false}
                />
                <OutgoingRequest
                  outgoingUserRequest={selectedMessageCenterUser}
                  isLoading={usersChatRequestClosing.isRequesting}
                  onCloseRequest={closeUsersChatRequest}
                />
              </>
            )}

            {selectedChatType === ChatType.RejectedIncomingRequest && selectedMessageCenterUser && (
              <>
                <ChatHeader
                  title={selectedMessageCenterUser.user.name}
                  subtitle="Rejected incoming request"
                  onBlockUser={() => openAddBlockedUserConfirmModal(selectedMessageCenterUser.user.id)}
                  showDeleteChatMenuItem={false}
                />
                <RejectedIncomingRequest
                  rejectedUserRequest={selectedMessageCenterUser}
                  isLoading={usersChatRequestRejectCancelling.isRequesting}
                  onCancelRejectRequest={cancelRejectUsersChatRequest}
                />
              </>
            )}

            {selectedChatType === ChatType.RejectedOutgoingRequest && selectedMessageCenterUser && (
              <>
                <ChatHeader
                  title={selectedMessageCenterUser.user.name}
                  subtitle="Rejected outgoing request"
                  onBlockUser={() => openAddBlockedUserConfirmModal(selectedMessageCenterUser.user.id)}
                  showDeleteChatMenuItem={false}
                />
                <RejectedOutgoingRequest rejectedUserRequest={selectedMessageCenterUser} />
              </>
            )}

            {selectedChatType === ChatType.Support && ownUser && (
              <>
                <ChatHeader title="Support" subtitle={SUPPORT_SUBTITLE} isShowActions={false} />
                <ChatSupport currentUser={ownUser} />
              </>
            )}

            {isShowEmptyChat && <EmptyChat />}
          </ChatContent>
        </ChatWrapper>
      </Root>
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  messageCenterUsers: selectors.selectMessageCenterUsers(state),
  receivedUsersChatsRequestsLoading: selectors.selectCommunication(state, 'receivedUsersChatsRequestsLoading'),
  usersChatLoading: selectors.selectCommunication(state, 'usersChatLoading'),
  rejectedIncomingUsersChatRequestsLoading: selectors.selectCommunication(
    state,
    'rejectedIncomingUsersChatRequestsLoading'
  ),
  rejectedOutgoingUsersChatRequestsLoading: selectors.selectCommunication(
    state,
    'rejectedOutgoingUsersChatRequestsLoading'
  ),
  usersChatRequestsLoading: selectors.selectCommunication(state, 'usersChatRequestsLoading'),
  usersChatRequestAccepting: selectors.selectCommunication(state, 'usersChatRequestAccepting'),
  usersChatRequestClosing: selectors.selectCommunication(state, 'usersChatRequestClosing'),
  usersChatRequestRejecting: selectors.selectCommunication(state, 'usersChatRequestRejecting'),
  currentUser: userSelectors.selectCurrentUser(state),
  connectedUsers: userToUserSelectors.selectConnectedUsers(state),
  messageQueue: userToUserSelectors.selectMessageQueue(state),

  isConnectedAdmin: userToAdminSelectors.selectIsConnectedAdmin(state),
  usersChatRequestRejectCancelling: selectors.selectCommunication(state, 'usersChatRequestRejectCancelling'),
  blockedUserAdding: selectors.selectCommunication(state, 'blockedUserAdding'),
  userToAdminChatIsOnline: selectors.selectUserToAdminChatIsOnline(state),
  userToAdminChatIsOnlineLoading: selectors.selectCommunication(state, 'userToAdminChatIsOnlineLoading'),
  userToUserChatDeleting: selectors.selectCommunication(state, 'userToUserChatDeleting'),
});

const mapDispatchToProps = {
  getReceivedUsersChatsRequests: actions.getReceivedUsersChatsRequests,
  getUsersChats: actions.getUsersChats,
  getUsersChatRequests: actions.getUsersChatRequests,
  getRejectedIncomingChatRequests: actions.getRejectedIncomingChatRequests,
  getRejectedOutgoingChatRequests: actions.getRejectedOutgoingChatRequests,
  acceptUsersChatRequest: actions.acceptUsersChatRequest,
  closeUsersChatRequest: actions.closeUsersChatRequest,
  rejectUsersChatRequest: actions.rejectUsersChatRequest,
  sendMessage: userToUserActions.sendMessage,
  setConnectedUser: userToUserActions.setConnectedUser,
  deleteMessagesFromQueue: userToUserActions.deleteMessagesFromQueue,
  cancelRejectUsersChatRequest: actions.cancelRejectUsersChatRequest,
  addBlockedUser: actions.addBlockedUser,
  getUserToAdminChatIsOnline: actions.getUserToAdminChatIsOnline,
  deleteUserToUserChat: actions.deleteUserToUserChat,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(MessageCenter));
export default withDynamicModules(Connected, MessageCenterModule);
