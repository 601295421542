import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {IconName} from 'Icon/components/Icon';
import BlockButton from './BlockButton';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${ColorPalette.gray48};
  margin-bottom: 16px;
`;

const UserName = styled.div`
  color: ${Theme.color.black};
  margin-left: 16px;
  overflow: hidden;
`;

const Deleted = styled.span`
  color: ${Theme.color.primary};

  @media ${breakpoints.sm} {
    margin-right: 32px;
  }
`;

const Restored = styled.div`
  flex-direction: column;
  align-items: end;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

interface IProps {
  blockedUser: IChatUser;

  onReload(id: number): void;
  onDelete(id: number): void;
  onSuccess(): void;
}

function BlockedUsertem(props: IProps) {
  const {blockedUser, onDelete, onReload, onSuccess} = props;

  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteIconColor, setDeleteIconColor] = useState(ColorPalette.white9);

  const onDeleteClick = useCallback(async () => {
    setIsDeleted(true);
    blockedUser.isDeleted = true;
    await onDelete(blockedUser.id);
    onSuccess();
  }, [blockedUser, onDelete, onSuccess]);

  const onReloadClick = useCallback(async () => {
    setIsDeleted(false);
    blockedUser.isDeleted = false;
    await onReload(blockedUser.id);
    onSuccess();
  }, [blockedUser, onReload, onSuccess]);

  const handleOnMouseEnter = useCallback(() => {
    setDeleteIconColor(ColorPalette.black3);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setDeleteIconColor(ColorPalette.gray18);
  }, []);

  return (
    <Root
      className="d-flex justify-content-between align-items-center"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="d-flex align-items-center">
        <Avatar
          avatarSize={AvatarSize.Custom}
          customSizeAvatar={40}
          customSizeIcon={24}
          defaultAvatarProfile={DefaultAvatarProfile.User}
          url={blockedUser.avatar && blockedUser.avatar.url}
          style={{opacity: isDeleted ? 0.2 : 1}}
        />
        <UserName>{blockedUser.name}</UserName>
      </div>
      <div className="d-flex align-items-center">
        {isDeleted ? (
          <Restored className="d-flex">
            <Deleted>Restored</Deleted>
            <BlockButton
              onClick={onReloadClick}
              color={deleteIconColor}
              iconProps={{
                name: IconName.AccountBlock,
                color: deleteIconColor,
                hoverColor: Theme.color.primary,
              }}
            />
          </Restored>
        ) : (
          <BlockButton
            label="Restore"
            onClick={onDeleteClick}
            color={deleteIconColor}
            iconProps={{name: IconName.Reload, size: 16, color: deleteIconColor, hoverColor: Theme.color.primary}}
          />
        )}
      </div>
    </Root>
  );
}

export default memo(BlockedUsertem);
