import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

import HorseImage from './horse.svg';

const Root = styled.div`
  padding-top: 32px;
  display: flex;
  grid-gap: 64px;
  font-family: ${Theme.font.secondary};
  margin: auto;
  max-width: 90%;
  width: 100%;
  color: ${Theme.color.black};
  align-items: center;

  flex-direction: column-reverse;
  @media ${breakpoints.md} {
    flex-direction: row;
    max-width: 60%;
    padding-top: 48px;
  }
`;

const Main = styled.div`
  text-align: center;
  @media ${breakpoints.md} {
    text-align: left;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-size: ${Typography.size.size32};
  line-height: 56px;
  font-weight: ${Typography.weight.bold700};
  color: ${ColorPalette.gray44};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size40};
  }
`;

const Description = styled.div`
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 32px;
  margin-top: 16px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size24};
    line-height: 40px;
    margin-top: 24px;
  }
`;

const Horse = styled.img`
  margin-top: 64px;
  animation: sway1 5s infinite;
  transform-origin: 50% 100% 0;

  width: 190px;
  height: 168px;

  @media ${breakpoints.sm} {
    width: 262px;
    height: 232px;
  }

  @media ${breakpoints.md} {
    margin-right: 96px;
    margin-top: 0;
  }

  @keyframes sway1 {
    0%,
    100% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(15deg);
    }
  }
`;

const Actions = styled.div`
  margin-top: 32px;
`;

function Maintain() {
  const navigate = useNavigate();
  const redirectToLoginPage = useCallback(() => navigate('/login'), [navigate]);

  return (
    <BaseLayout isBackButtonDenied={true} withoutPaddings={true} isEmptyHeader={true}>
      <Root>
        <div>
          <Horse src={HorseImage} />
        </div>
        <Main>
          <Title>Oops!</Title>
          <Description>
            Etalon Update in Progress: We are busy working on improvements and new features for you and your herd. We
            will be back up in just a few minutes. Thank you for your patience - Team Etalon
          </Description>
          <Actions>
            <PrimaryButton onClick={redirectToLoginPage} variant="outlined">
              Return to sign in
            </PrimaryButton>
          </Actions>
        </Main>
      </Root>
    </BaseLayout>
  );
}

export default memo(Maintain);
