export function getEnvParams() {
  return {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
    payPalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    dataDogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    dataDogDestination: process.env.REACT_APP_DATADOG_DESTINATION,
    dataDogTags: process.env.REACT_APP_DD_TAGS,
    googleMapKey: process.env.REACT_APP_GOOGLE_SCRIPT_KEY,
    fullstoryOrgId: process.env.REACT_APP_FULLSTORY_ORGID,
    recaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
    googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    imagesBaseUrl: process.env.REACT_APP_IMAGES_BASE_URL,
    signalRLogLevel: process.env.REACT_APP_SIGNALR_LOG_LEVEL,
    signalRFrontendLogLevel: process.env.REACT_APP_SIGNALR_FRONTEND_LOG_LEVEL,
  };
}
