import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.userMessageCenter;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectMessageCenterUsers(state: IAppState) {
  return selectState(state).data.messageCenterUsers;
}

export function selectUsersChatMessages(state: IAppState) {
  return selectState(state).data.usersChatMessages;
}

export function selectUserToAdminChatMessages(state: IAppState) {
  return selectState(state).data.userToAdminChatMessages;
}

export function selectUserToAdminChatDetails(state: IAppState) {
  return selectState(state).data.userToAdminChatDetails;
}

export function selectBlockedUsers(state: IAppState) {
  return selectState(state).data.blockedUsers;
}

export function selectUserToAdminChatIsOnline(state: IAppState) {
  return selectState(state).data.userToAdminChatIsOnline;
}

export function selectUserToAdminChatStatus(state: IAppState) {
  return selectState(state).data.userToAdminChatStatus;
}
