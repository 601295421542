import {IModule} from 'redux-dynamic-modules';

import {onlineReportReducer} from './reducer';
import {IAdminOnlineReportState} from './types';

export interface IAdminOnlineReportModuleState {
  adminOnlineReport: IAdminOnlineReportState;
}

export const AdminOnlineReportModule: IModule<IAdminOnlineReportModuleState> = {
  id: 'adminOnlineReport',
  reducerMap: {
    adminOnlineReport: onlineReportReducer,
  },
};
