import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {TextArea} from 'Common/components/Controls';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {Divider} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ImageUploader from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import {ImageModule} from 'Image/store/imageModule';
import {actions as imageActions, selectors as imageSelectors} from 'Image/store/index';
import Loading from 'Loading/components/Loading';
import {AdminChatStatus} from 'MessageCenter/constants/AdminChatStatus';
import {MAX_MESSAGE_LENGTH} from 'MessageCenter/constants/MaxMessageLength';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {actions, MessageCenterModule, selectors} from 'MessageCenter/store';
import {connect, ConnectedProps} from 'react-redux';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {ChatText} from '../../styled';
import {convertUserToAdminChatMesssageToIMessageQueue, filterMessages} from '../converters';
import IncomingMessage from '../Messages/IncomingMessage';
import OutgoingMessage from '../Messages/OutgoingMessage';
import AttachFilePanel from './AttachFilePanel';
import {actions as userToAdminActions, selectors as userToAdminSelectors} from 'SignalR/store/userToAdmin';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';

const textAreaStyle = {minHeight: 48, maxHeight: 96};
const imageUploaderStyle: React.CSSProperties = {
  background: ColorPalette.transparent0,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;

  padding: 0 8px;
  position: relative;

  @media ${breakpoints.sm} {
    padding: 0 24px;
  }
`;

const Footer = styled.div`
  width: 100%;
  gap: 16px;

  margin-top: 16px;
`;

const InputMessage = styled(TextArea)`
  width: 100%;
`;

const SendButton = styled(PrimaryButton)`
  min-width: 80px;

  @media ${breakpoints.sm} {
    min-width: 168px;
  }
`;

interface IProps {
  currentUser: IChatUser;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IProps & IConnected;

function ChatSupport(props: Props) {
  const {
    currentUser,
    getUserToAdminChatMessages,
    userToAdminChatMessages,
    userToAdminChatMessagesLoading,
    getUserToAdminChatDetails,
    createUserToAdminChatRequest,
    userToAdminChatCreating,
    userToAdminChatDetailsLoading,
    uploadImage,
    resetUserToAdminChatDetails,
    imageUploading,
    getUserToAdminChatStatus,
    userToAdminChatStatus,
    userToAdminChatStatusLoading,
    userToAdminMessageQueue: messageQueue,
    sendUserToAdminMessage: onSendMessage,
    deleteMessagesFromUserToAdminQueue: deleteMessagesFromQueue,
    adminMessageRead,
    sendAdminToUserMessageReadRequest,
    resetAdminToUserMessageRead,
  } = props;

  const [message, setMessage] = useState<string>('');
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageQueue[]>([]);
  const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);
  const [fileLoadError, setFileLoadError] = useState<string | null>(null);
  const [images, setImages] = useState<IImage[]>([]);
  const [isExistChat, setIsExistChat] = useState<Nullable<boolean>>(null);
  const [isReadMessageIds, setIsReadMessageIds] = useState<number[]>([]);

  const {isMobile} = useMediaQuery();

  useEffect(() => {
    getUserToAdminChatStatus();

    return () => resetUserToAdminChatDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsExistChat(userToAdminChatStatus === AdminChatStatus.IsExist);
  }, [userToAdminChatStatus]);

  useEffect(() => {
    setIsScrollToBottom(false);
    if (isExistChat) {
      getUserToAdminChatDetails();
      getUserToAdminChatMessages({});
    }
  }, [getUserToAdminChatDetails, getUserToAdminChatMessages, isExistChat]);

  useEffect(() => {
    // Delete messages from queue, because its are in history
    deleteMessagesFromQueue({userId: currentUser.id});

    const historyMessages = userToAdminChatMessages
      ? convertUserToAdminChatMesssageToIMessageQueue(userToAdminChatMessages, currentUser.id)
      : [];

    setMessages(historyMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToAdminChatMessages]);

  useEffect(() => {
    const newMessages = [
      ...messages,
      ...filterMessages(messageQueue, currentUser.id, null),
      ...filterMessages(messageQueue, null, currentUser.id),
    ];
    setMessages(newMessages);
    if (newMessages.length > 0) {
      setIsScrollToBottom(true);
    }
    setIsMessageSending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  useEffect(() => {
    // Delete messages from queue, because chat is opened
    const foundId = messageQueue.findIndex(
      (x) =>
        (x.fromUserId === null && x.toUserId === currentUser.id) ||
        (x.fromUserId === currentUser.id && x.toUserId === null)
    );

    if (foundId !== -1) {
      deleteMessagesFromQueue({userId: currentUser.id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  useEffect(() => {
    if (adminMessageRead && adminMessageRead?.messageIds.length > 0) {
      setIsReadMessageIds([...isReadMessageIds, ...adminMessageRead?.messageIds]);
      resetAdminToUserMessageRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminMessageRead]);

  useEffect(() => {
    if (isReadMessageIds.length > 0) {
      const newMessages = [...messages].map((x) => {
        if (x.id && isReadMessageIds.includes(x.id)) {
          return {...x, isRead: true};
        }
        return x;
      });

      setMessages(newMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadMessageIds]);

  const onSendImages = useCallback(async () => {
    const ids = [];
    try {
      for (const image of images) {
        if (image.file) {
          const formData = new FormData();
          formData.append('uploadedFile', image.file);
          const savedImageId = await uploadImage(formData);
          ids.push(savedImageId);
        }
      }
    } finally {
      setImages([]);
    }
    return ids;
  }, [images, uploadImage]);

  const onSendClick = useCallback(async () => {
    setIsMessageSending(true);
    if (images.length > 0) {
      const imageIds = await onSendImages();
      onSendMessage({message, imageIds});
    } else {
      onSendMessage({message});
    }
    setMessage('');
  }, [images.length, message, onSendImages, onSendMessage]);

  const onInputChange = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    setMessage(value);
  }, []);

  const onKeyPressInput = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault();
        onSendClick();
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `48px`;
      } else {
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
      }
    },
    [onSendClick]
  );

  const onCreateNewChat = useCallback(() => {
    setIsExistChat(false);
    createUserToAdminChatRequest();
  }, [createUserToAdminChatRequest]);

  const closeFileLoadErrorModal = useCallback(() => setFileLoadError(null), []);

  const uploadFile = useCallback(
    async (image: IImage) => {
      if (image.file) {
        setImages([...images, image]);
      }
    },
    [images]
  );

  const onDeleteFile = useCallback(
    (index: number) => {
      const newImages = images.filter((x, ind) => ind !== index);
      setImages(newImages);
    },
    [images]
  );

  const onMessageRead = useCallback(
    (id: number) => {
      // TODO: make sending bunch ids, not only one id
      sendAdminToUserMessageReadRequest({messageIds: [id]});
    },
    [sendAdminToUserMessageReadRequest]
  );

  const isMessageRead = useCallback(
    (id: number, isRead: boolean) => {
      return isReadMessageIds.includes(id) ? true : isRead;
    },
    [isReadMessageIds]
  );

  const isLoading = [
    userToAdminChatCreating,
    userToAdminChatDetailsLoading,
    userToAdminChatMessagesLoading,
    userToAdminChatStatusLoading,
  ].some((x) => x.isRequesting);

  if (isExistChat !== null && !isExistChat) {
    return (
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>This is very beginning chat to admin. Would you like to start it?</ChatText>

        <div className="d-flex align-items-center" style={{gap: 16}}>
          <PrimaryButton size="small" onClick={onCreateNewChat}>
            Yes, start new chat
          </PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <Root className="d-flex flex-column">
      <WarningModal
        isOpen={!!fileLoadError}
        modalType={ModalTypes.Alert}
        modalTitle="Alert"
        onClose={closeFileLoadErrorModal}
        onSuccess={closeFileLoadErrorModal}
      >
        {fileLoadError}
      </WarningModal>

      {isLoading && <Loading />}

      <Scrollbar
        maxHeight="100%"
        autoHeight={false}
        isScrollToBottom={isScrollToBottom}
        renderView={({style, ...scrollbarProps}) => (
          <div style={{...style, padding: isMobile ? '16px 16px 0 16px' : '16px 24px 0 24px'}} {...scrollbarProps} />
        )}
      >
        {messages.map((message, i, arr) => {
          if (message.fromUserId === null && message.toUserId === currentUser.id) {
            return (
              <IncomingMessage
                key={i}
                id={message.id}
                message={message.message}
                isSupport={true}
                createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
                images={message.images}
                isRead={message.isRead}
                onMessageRead={onMessageRead}
              />
            );
          }
          const isRead =
            message.id !== undefined && message.isRead !== undefined
              ? isMessageRead(message.id, message.isRead)
              : !!message.isRead;

          return (
            <OutgoingMessage
              key={i}
              message={message.message}
              url={currentUser.avatar?.url}
              createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
              images={message.images}
              isRead={isRead}
            />
          );
        })}
      </Scrollbar>

      <Divider />
      <Footer className="ml-auto d-flex">
        <ImageUploader
          width="36px"
          height="36px"
          value={null}
          onChange={uploadFile}
          title="Add image"
          showDeleteButton={false}
          inputStyle={imageUploaderStyle}
          renderEmptyState={() => (
            <ColoredIcon
              name={IconName.AddCircleFilled}
              size={36}
              stroke={false}
              fill={true}
              color={Theme.color.control.bg}
              hoverColor={Theme.color.control.hover}
            />
          )}
        />
        <div className="w-100">
          <InputMessage
            maxLength={MAX_MESSAGE_LENGTH}
            value={message}
            onChange={onInputChange}
            onKeyPress={onKeyPressInput}
            inputStyle={textAreaStyle}
          />

          {images.length > 0 && <AttachFilePanel images={images} onDelete={onDeleteFile} />}
        </div>
        <SendButton onClick={onSendClick} isLoading={imageUploading.isRequesting || isMessageSending}>
          Send
        </SendButton>
      </Footer>
    </Root>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userToAdminChatDetails: selectors.selectUserToAdminChatDetails(state),
  userToAdminChatDetailsLoading: selectors.selectCommunication(state, 'userToAdminChatDetailsLoading'),
  userToAdminChatMessagesLoading: selectors.selectCommunication(state, 'userToAdminChatMessagesLoading'),
  userToAdminChatMessages: selectors.selectUserToAdminChatMessages(state),
  userToAdminChatCreating: selectors.selectCommunication(state, 'userToAdminChatCreating'),
  imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
  userToAdminChatStatus: selectors.selectUserToAdminChatStatus(state),
  userToAdminChatStatusLoading: selectors.selectCommunication(state, 'userToAdminChatStatusLoading'),
  userToAdminMessageQueue: userToAdminSelectors.selectMessageQueue(state),
  adminMessageRead: userToAdminSelectors.selectAdminMessageRead(state),
});

const mapDispatchToProps = {
  getUserToAdminChatDetails: actions.getUserToAdminChatDetails,
  getUserToAdminChatMessages: actions.getUserToAdminChatMessages,
  createUserToAdminChatRequest: actions.createUserToAdminChatRequest,
  resetUserToAdminChatDetails: actions.resetUserToAdminChatDetails,
  uploadImage: imageActions.uploadImage,
  getUserToAdminChatStatus: actions.getUserToAdminChatStatus,
  sendUserToAdminMessage: userToAdminActions.sendUserToAdminMessage,
  deleteMessagesFromUserToAdminQueue: userToAdminActions.deleteMessagesFromQueue,
  sendAdminToUserMessageReadRequest: userToAdminActions.sendAdminToUserMessageReadRequest,
  resetAdminToUserMessageRead: userToAdminActions.resetAdminToUserMessageRead,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ChatSupport));
export default withDynamicModules(Connected, [MessageCenterModule, ImageModule]);
