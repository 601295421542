import React, {memo} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

import {SidebarComponent} from 'HorseProfile/components/shared/StyledComponents';
import {IHorseReport} from 'Horse/models/IUserHorse';
import {IconName} from 'Icon/components/Icon';
import {downloadByUrl} from 'Common/helpers/downloadHelper';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import Loading from 'Loading/components/Loading';
import {OrderReportType} from 'Common/constants/OrderReportType';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {VisitorType} from 'Common/constants/VisitorType';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';
import {mapReportStatusToLabel} from 'Common/constants/OrderReportStatus';
import {Nullable} from 'Common/types';

const Title = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.gray};
  margin-bottom: 16px;
`;

const ReportItem = styled.div`
  margin-bottom: 16px;
`;

const ReportName = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};
`;

const OrderLinkButton = styled(LinkButton)`
  width: 100%;
  margin-bottom: 16px;
`;

const ViewReportButton = styled(PrimaryButton)`
  min-width: 0;
`;

interface IProps {
  className?: string;
  parantageReport: Nullable<IHorseReport>;
  horseName: string;
  horseId: number;
  isGeneticDataHidden?: boolean;
  isAncestryReportHidden?: boolean;
  isReportsLoading?: boolean;
  visitorType: VisitorType;
}

function HorseReport(props: IProps) {
  const {hasPermission} = usePermissions();

  const {
    className,
    parantageReport,
    horseName,
    horseId,
    isGeneticDataHidden,
    isAncestryReportHidden,
    isReportsLoading,
    visitorType,
  } = props;

  const isBusinessPortalProfile = visitorType === VisitorType.AssociationEmployee;
  const isAdmin = [VisitorType.AssociationAdmin, VisitorType.Admin].includes(visitorType);

  const navigate = useNavigate();

  return (
    <SidebarComponent className={classNames(className, 'position-relative')}>
      {isReportsLoading && <Loading />}
      <Title>Horse DNA reports</Title>
      {Object.keys(OrderReportType).map((reportType, i) => {
        const isDiagnostic = reportType === OrderReportType.Diagnostic;
        const isDiagnosticAvailable = isAdmin
          ? true
          : isBusinessPortalProfile
          ? isDiagnostic && hasPermission(Permission.OnlineReport)
          : isDiagnostic && hasPermission(Permission.OnlineReport) && !isGeneticDataHidden;

        const isAncestry = reportType === OrderReportType.Ancestry;
        const isAncestryAvailable = isAdmin
          ? true
          : isBusinessPortalProfile
          ? isAncestry && hasPermission(Permission.OnlineReport)
          : isAncestry && hasPermission(Permission.OnlineReport) && !isAncestryReportHidden;

        const isParantage = reportType === OrderReportType.Parentage;
        const isParantageAvailable = isParantage && parantageReport?.url;

        const isUnavailable =
          (isDiagnostic && !isDiagnosticAvailable) ||
          (isAncestry && !isAncestryAvailable) ||
          (isParantage && !isParantageAvailable);

        const unavailableLabel = isUnavailable
          ? isParantage && parantageReport && parantageReport.status
            ? mapReportStatusToLabel[parantageReport.status]
            : 'Unavailable'
          : 'Unavailable';

        const handleDownloadResultClick = () => {
          if (parantageReport?.url) {
            downloadByUrl(parantageReport?.url, `${reportType} report (${horseName})`, '.pdf');
          }
        };

        const handleOpenOnlineReportClick = (isAncestry?: boolean) => {
          const ancestryTab = isAncestry ? '/ancestry' : '/summary';
          if (isAdmin || isBusinessPortalProfile) {
            navigate(`${PREFIX_VISITOR_TYPE[visitorType]}/online-report/${horseId}/0${ancestryTab}`);
            return;
          }

          navigate(`/user/online-report/${horseId}/0${ancestryTab}`);
        };

        return (
          <ReportItem key={i} className="d-flex justify-content-between">
            <ReportName>{reportType}</ReportName>
            {isDiagnostic && isDiagnosticAvailable && (
              <ViewReportButton
                icon={{
                  name: IconName.EyeShow,
                  color: Theme.color.primary,
                  fill: true,
                }}
                variant="outlined"
                size="small"
                onClick={() => handleOpenOnlineReportClick()}
              >
                View Report
              </ViewReportButton>
            )}

            {isAncestry && isAncestryAvailable && (
              <ViewReportButton
                icon={{
                  name: IconName.EyeShow,
                  color: Theme.color.primary,
                  fill: true,
                }}
                variant="outlined"
                size="small"
                onClick={() => handleOpenOnlineReportClick(true)}
              >
                View Report
              </ViewReportButton>
            )}

            {isParantage && isParantageAvailable && (
              <ViewReportButton
                icon={{
                  name: IconName.ArrowDownload,
                  color: Theme.color.primary,
                  fill: true,
                }}
                variant="outlined"
                size="small"
                onClick={handleDownloadResultClick}
              >
                Download Report
              </ViewReportButton>
            )}

            {isUnavailable && (
              <ViewReportButton variant="outlined" size="small" disabled={true}>
                {unavailableLabel}
              </ViewReportButton>
            )}
          </ReportItem>
        );
      })}

      {!isAdmin && (
        <OrderLinkButton to={`${PREFIX_VISITOR_TYPE[visitorType]}/order-tests/${horseId}`} size="small">
          Order Testing
        </OrderLinkButton>
      )}
    </SidebarComponent>
  );
}

export default memo(HorseReport);
