import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {useParams} from 'react-router-dom';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Header from 'OnlineReportPrintable/parts/Header';
import {IAppState} from 'Common/store/IAppState';
import HorseDetails from 'OnlineReportPrintable/parts/HorseDetails';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import Loading from 'Loading/components/Loading';
import ResultsSummary from 'OnlineReportPrintable/parts/ResultsSummary';
import {OnlineReportModule} from 'OnlineReport/store/diagnostic/onlineReportModule';
import {actions, OnlineReportActions, selectors} from 'OnlineReport/store/diagnostic';
import {
  actions as adminActions,
  AdminOnlineReportActions,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic';
import GenotypeGroups from 'OnlineReportPrintable/parts/GenotypeGroups';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';
import {PageBreak} from './common/styled';
import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import {ReactComponent as PdfTipIcon} from './img/pdf_tip.svg';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {useDeviceDetect} from 'Common/helpers/hooks/useDeviceDetect';
import {useFilterReportQuery} from './hooks/useFilterReportQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';

const Root = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 24px;

  @media ${breakpoints.sm} {
    padding: 80px 48px;
  }

  @media ${breakpoints.md} {
    padding: 30px 60px;
  }

  @media print {
    padding: 0 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div<{heightPercent?: number}>`
  padding: 0 ${({heightPercent}) => heightPercent}%;
`;

const PrintButton = styled.div`
  position: fixed;
  right: 60px;
  z-index: 1;

  @media print {
    display: none !important;
  }
`;

const SaveTip = styled.div`
  width: 168px;
  height: 158px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px ${hexToRgba(ColorPalette.black0, 0.05)}, 0px 0px 4px ${hexToRgba(ColorPalette.black0, 0.02)};
  border-radius: 4px;
  margin-top: 18px;
`;

const TipIcon = styled(PdfTipIcon)`
  margin: 12px;
`;

const TipText = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-weight: normal;
  font-size: ${Typography.size.size12};
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0 12px;
`;

/**
 * Unwraps a possibly undefined value, defaulting to `alternative` if the
 * value is null.
 */
const unwrapOr = <T,>(input: T | undefined, alternative: T): T => (input === undefined ? alternative : input);

interface IExternalProps {
  horseId: string;
  orderId: string;
  reportType: OnlineReportType;
}

type RouteParams = {horseId: string; orderId: string; reportType: OnlineReportType};

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const OnlineReportPrintable = (props: AllProps) => {
  const {
    getHorseDetails,
    getSummaryAbilities,
    getSummaryColors,
    getSummaryHealthIssues,
    getReviewSummaryAbilities,
    getReviewSummaryColors,
    getReviewSummaryHealthIssues,
    summaryAbilitiesLoading,
    summaryColorsLoading,
    summaryHealthIssuesLoading,
    reviewSummaryAbilitiesLoading,
    reviewSummaryColorsLoading,
    reviewSummaryHealthIssuesLoading,
    coatColorLoading,
    abilitiesLoading,
    horseDetails,
    horseDetailsLoading,
    summaryAbilities,
    summaryColors,
    summaryHealthIssues,
    geneticVariants,
    geneticVariantsLoading,
    getGeneticVariants,
    breedPanels,
    breedPanelsLoading,
    getBreedPanels,
    horseId,
    orderId,
    reportType,
  } = props;
  const {isMobile, isTablet} = useMediaQuery();
  const isMobileView = isMobile || isTablet;

  const [contentHeightPercent, setContentHeightPercent] = useState<number>(isMobileView ? 2 : 25);
  const [isSetReportType, setIsSetReportType] = useState(false);

  const filterReportQuery = useFilterReportQuery();

  // The summary will be visible if at least one value is true or not
  // set (which will default to `true`).
  const isSummaryVisible =
    !filterReportQuery.summary ||
    Object.values(filterReportQuery.summary).length === 0 ||
    Object.values(filterReportQuery.summary).some((x) => x || x === undefined);

  useEffect(() => {
    setOnlineReportTypeToStorage(reportType);
    setIsSetReportType(true);
  }, [reportType]);

  const openPrintWindow = useCallback((fromListener?: boolean) => {
    setContentHeightPercent(0);
    !fromListener && setTimeout(window.print, 100);
  }, []);

  const closePrintWindow = useCallback(() => {
    if (isMobileView) {
      setContentHeightPercent(2);
    } else {
      setContentHeightPercent(25);
    }
  }, [isMobileView]);

  useEffect(() => {
    window.onafterprint = closePrintWindow;
  }, [closePrintWindow]);

  useEffect(() => {
    window.onbeforeprint = () => openPrintWindow(true);
  }, [openPrintWindow]);

  const summary = summaryAbilities && summaryColors && summaryHealthIssues;
  const isSummaryLoading = [
    summaryAbilitiesLoading,
    summaryColorsLoading,
    summaryHealthIssuesLoading,
    geneticVariantsLoading,
    breedPanelsLoading,
  ].some((i) => i.isRequesting);
  const isReviewSummaryLoading = [
    reviewSummaryAbilitiesLoading,
    reviewSummaryColorsLoading,
    reviewSummaryHealthIssuesLoading,
  ].some((i) => i.isRequesting);

  useEffect(() => {
    const isCorrectHorseId = parseInt(horseId);

    if (isCorrectHorseId && !horseDetailsLoading.isRequesting) {
      getHorseDetails(+horseId);
    }

    if (isCorrectHorseId && !geneticVariantsLoading.isRequesting) {
      getGeneticVariants(+horseId);
    }

    if ((!breedPanels || breedPanels.length === 0) && isCorrectHorseId && !breedPanelsLoading.isRequesting) {
      getBreedPanels(+horseId);
    }

    if (isCorrectHorseId) {
      if (
        (reportType === OnlineReportType.ReviewAdmin || reportType === OnlineReportType.ReviewAdminAssociation) &&
        !isReviewSummaryLoading
      ) {
        getReviewSummaryAbilities(+horseId, +orderId);
        getReviewSummaryColors(+horseId, +orderId);
        getReviewSummaryHealthIssues(+horseId, +orderId);
        return;
      }

      if (
        reportType !== OnlineReportType.ReviewAdmin &&
        reportType !== OnlineReportType.ReviewAdminAssociation &&
        !isSummaryLoading
      ) {
        getSummaryAbilities(+horseId);
        getSummaryColors(+horseId);
        getSummaryHealthIssues(+horseId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {isFireFox} = useDeviceDetect();

  const isLoading =
    isSummaryLoading || [horseDetailsLoading, coatColorLoading, abilitiesLoading].some((i) => i.isRequesting);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BaseLayout
      hideFooter={true}
      hideHeader={true}
      withoutPaddings={true}
      widthByContent={false}
      backButtonStyle={{marginLeft: 62, marginTop: 26}}
    >
      {isSetReportType && (
        <Root>
          <Container>
            <PrintButton className="d-flex flex-column justify-content-end">
              <PrimaryButton size="small" onClick={() => openPrintWindow()}>
                Print
              </PrimaryButton>
              <SaveTip className="d-flex flex-column">
                <TipIcon className="align-self-center" />
                <TipText>
                  To save a copy to your device, click <strong>"Print"</strong> and choose{' '}
                  <strong>"Save as PDF"</strong> from your printer menu
                </TipText>
              </SaveTip>
            </PrintButton>

            <Content className="w-100 h-100" heightPercent={contentHeightPercent}>
              <Header horseDetails={horseDetails} />
              <HorseDetails horseDetails={horseDetails} />
              {!isFireFox && (
                <>
                  <PageBreak />
                </>
              )}

              {isSummaryVisible ? (
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        <Header horseDetails={horseDetails} isShort={true} />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <ResultsSummary
                          filter={{
                            variantSummary: unwrapOr(filterReportQuery.summary.variantSummary, true),
                            abilities: unwrapOr(filterReportQuery.summary.abilities, true),
                            coatColors: unwrapOr(filterReportQuery.summary.coatColors, true),
                            healthVariants: unwrapOr(filterReportQuery.summary.healthVariants, true),
                          }}
                          summaryAbilities={summaryAbilities}
                          summaryColors={summaryColors}
                          summaryHealthIssues={summaryHealthIssues}
                          geneticVariants={geneticVariants}
                          breedPanels={breedPanels}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}

              {!isFireFox && (
                <>
                  <PageBreak />
                </>
              )}

              <GenotypeGroups
                filter={filterReportQuery}
                horseId={horseId!}
                orderId={orderId!}
                reportType={reportType}
                horseDetails={horseDetails}
              />
            </Content>
          </Container>
        </Root>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {reportType} = externalProps;
  const selector =
    reportType !== OnlineReportType.User && reportType !== OnlineReportType.Association ? adminSelectors : selectors;

  return {
    horseDetails: selector.selectHorseDetails(state),
    summaryAbilities: selector.selectSummaryAbilities(state),
    summaryColors: selector.selectSummaryColors(state),
    summaryHealthIssues: selector.selectSummaryHealthIssues(state),
    horseDetailsLoading: selector.selectCommunication(state, 'horseDetailsLoading'),
    summaryAbilitiesLoading: selector.selectCommunication(state, 'summaryAbilitiesLoading'),
    summaryColorsLoading: selector.selectCommunication(state, 'summaryColorsLoading'),
    summaryHealthIssuesLoading: selector.selectCommunication(state, 'summaryHealthIssuesLoading'),
    reviewSummaryAbilitiesLoading: adminSelectors.selectCommunication(state, 'reviewSummaryAbilitiesLoading'),
    reviewSummaryColorsLoading: adminSelectors.selectCommunication(state, 'reviewSummaryColorsLoading'),
    reviewSummaryHealthIssuesLoading: adminSelectors.selectCommunication(state, 'reviewSummaryHealthIssuesLoading'),
    coatColorLoading: selector.selectCommunication(state, 'coatColorLoading'),
    abilitiesLoading: selector.selectCommunication(state, 'abilitiesLoading'),
    geneticVariants: selector.selectGeneticVariants(state),
    geneticVariantsLoading: selector.selectCommunication(state, 'geneticVariantsLoading'),
    breedPanels: selector.selectHorseBreedPanels(state),
    breedPanelsLoading: selector.selectCommunication(state, 'horseBreedPanelsLoading'),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions | AdminOnlineReportActions>,
  externalProps: IExternalProps
) => {
  const {reportType} = externalProps;

  const action =
    reportType !== OnlineReportType.User && reportType !== OnlineReportType.Association ? adminActions : actions;

  return {
    getHorseDetails: (horseId: number) => dispatch(action.getHorseDetails(horseId)),
    getSummaryAbilities: (horseId: number) => dispatch(action.getSummaryAbilities(horseId)),
    getSummaryColors: (horseId: number) => dispatch(action.getSummaryColors(horseId)),
    getSummaryHealthIssues: (horseId: number) => dispatch(action.getSummaryHealthIssues(horseId)),
    getReviewSummaryAbilities: (horseId: number, orderId: number) =>
      dispatch(adminActions.getReviewSummaryAbilities(horseId, orderId)),
    getReviewSummaryColors: (horseId: number, orderId: number) =>
      dispatch(adminActions.getReviewSummaryColors(horseId, orderId)),
    getReviewSummaryHealthIssues: (horseId: number, orderId: number) =>
      dispatch(adminActions.getReviewSummaryHealthIssues(horseId, orderId)),
    getGeneticVariants: (horseId: number) => dispatch(action.getGeneticVariants(horseId)),
    getBreedPanels: (horseId: number, orderId?: number) => dispatch(action.getHorseBreedPanels(horseId, orderId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(OnlineReportPrintable));

const Exported = () => {
  const {horseId, orderId, reportType = OnlineReportType.User} = useParams<RouteParams>() as RouteParams;

  return (
    <DynamicModuleLoader modules={[AdminOnlineReportModule, OnlineReportModule]}>
      <Connected horseId={horseId} orderId={orderId} reportType={reportType} />
    </DynamicModuleLoader>
  );
};

export default Exported;
