import {ChatType} from 'MessageCenter/constants/ChatType';

export const mapChatTypeToRoute: Record<ChatType, string> = {
  IncomingRequest: 'incoming-request',
  Messages: 'messages',
  OutgoingRequest: 'outgoing-request',
  RejectedIncomingRequest: 'incoming-request',
  RejectedOutgoingRequest: 'outgoing-request',
  Support: 'support',
};
