import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {withUserName} from 'Common/helpers/withUserName';
import {ChatType} from 'MessageCenter/constants/ChatType';
import {IBlockedUser} from 'MessageCenter/models/IBlockedUser';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {IUsersChatMessage} from 'MessageCenter/models/IUsersChatMessage';
import {IUserToAdminChatDetails} from 'MessageCenter/models/IUserToAdminChatDetails';
import {
  IUserToAdminChatAdminMessage,
  IUserToAdminChatMessage,
  IUserToAdminChatUserMessage,
} from 'MessageCenter/models/IUserToAdminChatMessage';
import {
  IServerBlockedUser,
  IServerReceivedUsersChatRequest,
  IServerRejectedIncomingUsersChatRequest,
  IServerRejectedOutgoingUsersChatRequest,
  IServerUsersChat,
  IServerUsersChatMessage,
  IServerUsersChatRequest,
  IServerUserToAdminChatAdminMessage,
  IServerUserToAdminChatMessage,
  IServerUserToAdminChatUserMessage,
} from '../types/messageCenter';

export function convertUsersChatToClient(value: IServerUsersChat): IMessageCenterUser {
  return {
    id: value.id,
    message: '',
    createDate: value.createDate ? convertUTCToClientDate(value.createDate) : null,
    lastMessageDate: value.lastMessageDate ? convertUTCToClientDate(value.lastMessageDate) : null,
    unreadMessagesCount: value.unreadMessagesCount,
    user: {...withUserName(withAvatar(value.target))},
    chatType: ChatType.Messages,
  };
}

export function convertReceivedUsersChatRequestToClient(value: IServerReceivedUsersChatRequest): IMessageCenterUser {
  return {
    id: value.id,
    message: value.message,
    user: {...withUserName(withAvatar(value.initiator))},
    chatType: ChatType.IncomingRequest,
  };
}

export function convertUsersChatRequestToClient(value: IServerUsersChatRequest): IMessageCenterUser {
  return {
    id: value.id,
    message: value.message,
    user: {...withUserName(withAvatar(value.target))},
    chatType: ChatType.OutgoingRequest,
  };
}

export function convertRejectedIncomingUsersChatRequestToClient(
  value: IServerRejectedIncomingUsersChatRequest
): IMessageCenterUser {
  return {
    id: value.id,
    message: value.message,
    user: {...withUserName(withAvatar(value.initiator))},
    chatType: ChatType.RejectedIncomingRequest,
  };
}

export function convertRejectedOutgoingUsersChatRequestToClient(
  value: IServerRejectedOutgoingUsersChatRequest
): IMessageCenterUser {
  return {
    id: value.id,
    message: value.message,
    user: {...withUserName(withAvatar(value.target))},
    chatType: ChatType.RejectedOutgoingRequest,
  };
}

export function convertUsersChatMessageToClient(value: IServerUsersChatMessage): IUsersChatMessage {
  return {...value, createDate: convertUTCToClientDate(value.createDate) || ''};
}

function convertUserToAdminUserMessages(value: IServerUserToAdminChatUserMessage): IUserToAdminChatUserMessage {
  return {
    ...value,
    createDate: value.createDate,
    images: value.images.map((image) => withImageBaseUrl(image)),
  };
}

function convertUserToAdminAdminMessages(value: IServerUserToAdminChatAdminMessage): IUserToAdminChatAdminMessage {
  return {
    ...value,
    createDate: value.createDate,
    images: value.images.map((image) => withImageBaseUrl(image)),
  };
}

export function convertUserToAdminChatMessageToClient(value: IServerUserToAdminChatMessage): IUserToAdminChatMessage {
  return {
    userMessages: value.userMessages.map(convertUserToAdminUserMessages),
    adminMessages: value.adminMessages.map(convertUserToAdminAdminMessages),
  };
}

export function convertUserToAdminDetailsToClient(value: IUserToAdminChatDetails): IUserToAdminChatDetails {
  return {
    createDate: convertUTCToClientDate(value.createDate) || '',
    lastMessageDate: convertUTCToClientDate(value.lastMessageDate) || '',
  };
}

export function convertBlockedUserToClient(value: IServerBlockedUser): IBlockedUser {
  return {...value, target: {...withUserName(withAvatar(value.target))}};
}
