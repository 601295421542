import React, {memo} from 'react';
import styled, {css} from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const TextCSS = css`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 20px;
`;

const EmptyChatMessage = styled.span`
  ${TextCSS}
  font-style: italic;
  color: ${ColorPalette.gray48};

  margin-left: 24px;
`;

const Text = styled.span`
  ${TextCSS}
  margin: 8px 0 0 24px;
`;

const LinkTo = styled(CommonLink)`
  cursor: pointer;
  margin-top: 24px;
  color: ${ColorPalette.red7} !important;
  ${TextCSS}
`;

function EmptyRequest() {
  const {currentVisitorType} = useVisitorTypeService();

  return (
    <div className="d-flex flex-column justify-content-start">
      <EmptyChatMessage>None</EmptyChatMessage>
      <Text>
        Please use <LinkTo to={`${PREFIX_VISITOR_TYPE[currentVisitorType]}/build-a-horse/}`}>Build-a-Horse</LinkTo> or{' '}
        <LinkTo to="/find-a-horse/">Find-a-Horse</LinkTo> for searching horse owners.
      </Text>
    </div>
  );
}

export default memo(EmptyRequest);
