import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import {
  convertProbabilityToDangerProbability,
  convertProbabilityToTypedProbability,
  sortProbabilities,
} from 'Common/helpers/ProbabilityHelper';
import {TooltipProps} from 'Common/components/Tooltip/Tooltip';
import SelectableProbabilityItem from 'Common/components/Probability/SelectableProbabilityItem';

import FoalImage from './FoalImage';
import PriorityFeatures from './PriorityFeatures';
import HealthIssuesProbability from './HealthIssuesProbability';
import {IFoalProfile} from 'FoalProfile/models/IFoalProfile';
import UnreportedVariants from './UnreportedVariants/UnreportedVariants';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';

import {breakpoints} from 'Common/constants/Breakpoints';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {IIndexed} from 'Common/models/IIndexed';
import Theme from 'Common/constants/Theme';
import {useSortedModifiers} from './shared/helpers/useSortedModifiers';
import {getIsImageExists} from 'Common/helpers/ImagesHelper';
import {usePrevious} from 'Common/helpers/hooks/usePrevious';
import {IFoalModifier} from 'Common/models/IFoal';
import {ProbabilityEntityType} from 'Common/constants/ProbabilityEntityType';

const prohibitedModifierMessage =
  'The modifier is not available in this combination. You can change your selection and then choose this option.';

interface IndexedProbabilityEntity extends IFoalModifier, IIndexed {}

const probabilityItemTooltipConfig: TooltipProps = {
  placement: 'right-start',
};

const FoalProfileContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const FoalProfileWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'features features features'
    '. . .'
    'issues issues issues'
    '. . .'
    'img img img'
    '. . .'
    'color . modifiers'
    '. . .'
    'unreported unreported unreported';
  grid-template-columns: auto minmax(0px, 45px) auto;
  grid-template-rows: auto minmax(0px, 25px) auto minmax(0px, 22px) auto minmax(0px, 37px) auto auto;
  grid-row-gap: 32px;

  justify-items: center;
  padding: 50px 16px 0 16px;
  background: ${ColorPalette.gray38};

  @media ${breakpoints.sm} {
    grid-template-areas:
      'features . issues'
      'img . color'
      'img . modifiers'
      'unreported unreported unreported';
    grid-template-columns: auto minmax(10px, 57px) auto;
    grid-template-rows: auto auto auto auto;

    justify-items: start;
    padding: 25px 32px 60px 32px;
    background: ${ColorPalette.white0};
  }

  @media ${breakpoints.md} {
    grid-template-areas:
      'img . color . features'
      'img . modifiers . features'
      'img . modifiers . issues'
      'img . modifiers . issues'
      '. . unreported unreported unreported';
    grid-template-columns:
      minmax(229px, 331px) minmax(0px, 76px)
      minmax(200px, 250px) minmax(0px, 27px) minmax(300px, 400px);
    grid-template-rows: minmax(0px, auto) minmax(0px, auto) auto auto auto;
    padding: 30px 0 60px 0;
  }
`;

const GridImgCell = styled.div`
  grid-area: img;
  display: grid;
  grid-template-areas: '.';
  justify-self: center;

  @media ${breakpoints.sm} {
    grid-template-columns: minmax(0, 229px);
    justify-self: center;
  }

  @media ${breakpoints.md} {
    grid-template-columns: auto;
    justify-self: stretch;
  }
`;

const GridFeaturesCell = styled.div`
  grid-area: features;
  height: 190px;
`;

const GridIssuesCell = styled.div`
  grid-area: issues;
`;

const GridUnreportedCell = styled.div`
  grid-area: unreported;
  justify-self: center;
`;

const GridColorCell = styled.div`
  grid-area: color;
  width: 100%;

  @media ${breakpoints.sm} {
    margin-left: 0px;
  }
`;

const GridModCell = styled.div`
  grid-area: modifiers;
`;

const Title = styled.div`
  font-family: ${Typography.family.openSans};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size23};
  letter-spacing: -0.4px;
  color: ${ColorPalette.black1};
  margin: 0 0 20px 0;

  @media ${breakpoints.sm} {
    margin: 0 0 20px 0;
  }

  @media ${breakpoints.md} {
    margin: 0 0 20px 41px;
  }

  :first-child {
    margin-top: 0;
  }
`;

const CoatTitle = styled(Title)``;

const PriorityTitle = styled(Title)`
  margin-bottom: 26px;

  @media ${breakpoints.sm} {
    margin-left: 18px;
    margin-bottom: 20px;
  }

  @media ${breakpoints.md} {
    margin-left: 20px;
  }
`;

const HealthIssuesTitle = styled(Title)`
  margin-bottom: 23px;
  margin-top: 0;

  @media ${breakpoints.sm} {
    margin-bottom: 10px;
  }
`;

const HealthIssuesContainer = styled.div`
  max-width: 400px;
  display: grid;
  margin-left: 55px;

  @media ${breakpoints.sm} {
    max-width: 100%;
    display: block;
    margin-left: 0;
  }

  @media ${breakpoints.md} {
    margin-left: 55px;
    margin-top: 12px;
  }
`;

const EmptyGenotypes = styled.div`
  display: flex;
  align-items: center;
  width: 176px;
  height: 64px;
  margin-bottom: 20px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.gray};
`;

interface IProps {
  foal: IFoalProfile;
  firstParent: IFoalParent;
  secondParent: IFoalParent;
  abilityDictionary: IAbilities[];
  isOwnFirstParent: boolean;
  isOwnSecondParent: boolean;
}

const FoalProfileDetails = (props: IProps) => {
  const {foal, firstParent, secondParent, abilityDictionary, isOwnFirstParent, isOwnSecondParent} = props;
  const {healthIssues, dangers, abilities, colors, modifiers} = foal;

  const filteredColors = useMemo(() => foal.colors.filter((x) => x.isFullInfo), [foal.colors]);
  const extColors: IProbabilityEntity[] = sortProbabilities(
    convertProbabilityToDangerProbability(filteredColors),
    true
  );
  const prevExtColors = usePrevious(extColors);

  const filteredModifiers = useMemo(() => foal.modifiers.filter((x) => x.isFullInfo), [foal.modifiers]);
  const {extModifiers, severalModifiers, requiredModifiers} = useSortedModifiers(filteredModifiers);
  const prevExtModifiers = usePrevious(extModifiers);

  const [stopColorChecking, setStopColorChecking] = useState(false);
  const [stopModifiersChecking, setStopModifiersChecking] = useState(false);
  const checkImage = useCallback(async (items: IProbabilityEntity[]) => {
    for (const item of items) {
      if (item.image) {
        item.image.isExist = await getIsImageExists(item.image.url);
      }
    }
  }, []);

  useEffect(() => {
    const checkColorImages = async () => {
      if (JSON.stringify(prevExtColors) !== JSON.stringify(extColors)) {
        try {
          await checkImage(extColors);
        } finally {
          setStopColorChecking(true);
        }
      }
    };

    checkColorImages();
  }, [checkImage, extColors, prevExtColors]);

  useEffect(() => {
    const checkModifiers = async () => {
      if (prevExtModifiers && extModifiers && JSON.stringify(prevExtModifiers) !== JSON.stringify(extModifiers)) {
        try {
          await checkImage(extModifiers);
        } finally {
          setStopModifiersChecking(true);
        }
      }
    };

    checkModifiers();
  }, [checkImage, extModifiers, prevExtModifiers]);

  const healthIssuesProbability = useMemo(
    () =>
      sortProbabilities(
        convertProbabilityToDangerProbability(
          healthIssues.filter((x) => x.isFullInfo),
          dangers.filter((x) => x.isFullInfo)
        ),
        false
      ),
    [healthIssues, dangers]
  );

  const issuesProbability = useMemo(
    () =>
      sortProbabilities(
        convertProbabilityToDangerProbability(
          convertProbabilityToTypedProbability(
            healthIssues.filter((x) => !x.isFullInfo),
            ProbabilityEntityType.HealthIssues
          )
            .concat(
              convertProbabilityToTypedProbability(
                abilities.filter((x) => !x.isFullInfo),
                ProbabilityEntityType.Abilities
              )
            )
            .concat(
              convertProbabilityToTypedProbability(
                colors.filter((x) => !x.isFullInfo),
                ProbabilityEntityType.Colors
              )
            )
            .concat(
              convertProbabilityToTypedProbability(
                modifiers.filter((x) => !x.isFullInfo),
                ProbabilityEntityType.Modifiers
              )
            ),
          convertProbabilityToTypedProbability(
            dangers.filter((x) => !x.isFullInfo),
            ProbabilityEntityType.Dangers
          )
        ),
        false
      ),
    [healthIssues, abilities, colors, modifiers, dangers]
  );

  const unreportedParentsIssues = useMemo(() => {
    const parseIssues = (parent: IFoalParent) =>
      convertProbabilityToDangerProbability(
        convertProbabilityToTypedProbability(
          parent.healthIssuesWithoutGeneticInformation,
          ProbabilityEntityType.HealthIssues
        )
          .concat(
            convertProbabilityToTypedProbability(
              parent.abilitiesWithoutGeneticInformation,
              ProbabilityEntityType.Abilities
            )
          )
          .concat(
            convertProbabilityToTypedProbability(parent.colorsWithoutGeneticInformation, ProbabilityEntityType.Colors)
          )
          .concat(
            convertProbabilityToTypedProbability(
              parent.modifiersWithoutGeneticInformation,
              ProbabilityEntityType.Modifiers
            )
          ),
        convertProbabilityToTypedProbability(parent.dangersWithoutGeneticInformation, ProbabilityEntityType.Dangers)
      );
    return {
      firstParent: parseIssues(firstParent),
      secondParent: parseIssues(secondParent),
    };
  }, [firstParent, secondParent]);

  const [isBluredFoalImage, setIsBluredFoalImage] = useState<boolean>();
  const [isShowMessageForFoalImage, setIsShowMessageForFoalImage] = useState<boolean>();
  const [messageForFoalImage, setMessageForFoalImage] = useState<string | React.ReactElement>();

  const getFoalImageMessage = useCallback((name: string) => {
    return (
      <div>
        <p>
          Sorry, but we have no picture for <b>{name}</b> in our base.
        </p>
        You can choose another option to look at coat color preview.
      </div>
    );
  }, []);

  const setNoPicture = useCallback(
    (isNoPicture: boolean, name?: string) => {
      setIsShowMessageForFoalImage(isNoPicture);
      setIsBluredFoalImage(isNoPicture);
      setMessageForFoalImage((name && getFoalImageMessage(name)) || '');
    },
    [getFoalImageMessage, setIsBluredFoalImage, setIsShowMessageForFoalImage, setMessageForFoalImage]
  );

  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [foalColorImage, setFoalColorImage] = useState(extColors[0]?.image);
  const [foalColorHoverImage, setFoalColorHoverImageBack] = useState(extColors[0]?.image);
  const [enableColorHoverImage, setEnableColorHoverImage] = useState(false);

  const [activeModifierIndexes, setActiveModifierIndexes] = useState<number[]>([]);
  const [foalModifierImages, setFoalModifierImages] = useState<IFoalModifier[]>([]);
  const [foalModifierHoverImage, setFoalModifierHoverImageBack] = useState<IFoalModifier>();
  const [enableModifierHoverImage, setEnableModifierHoverImage] = useState(false);

  const [defaultModifiers, setDefaultModifiers] = useState<IndexedProbabilityEntity[]>([]);

  useEffect(() => {
    // Set foalColorImage one more time, because after images checking for existence
    // 'default image' (first in the list with 100%) can change isExists flag to true.
    if (stopColorChecking && extColors && extColors.length > 0 && foalColorImage?.id === extColors[0]?.image?.id) {
      setFoalColorImage(extColors[0]?.image);
    }
  }, [extColors, foalColorImage?.id, stopColorChecking]);

  const setModifiers = useCallback(() => {
    const defaultGroupedModifiers = _(severalModifiers)
      .groupBy((modifier) => modifier.dependencies?.testGroup)
      .filter((group) => group.reduce((total, current) => total + current.probability, 0) === 100);

    const defaultModifiers = requiredModifiers.concat(defaultGroupedModifiers.map((item) => item[0]).value());
    const indexedDefaultModifiers = extModifiers
      .map((i, idx) =>
        defaultModifiers.find((j) => j.id === i.id) ? {positionIndex: idx, ...i} : {positionIndex: -1, ...i}
      )
      .filter((i) => i.positionIndex !== -1);

    setFoalModifierImages(defaultModifiers);
    setActiveModifierIndexes(indexedDefaultModifiers.map((i) => i!.positionIndex));
    setDefaultModifiers(indexedDefaultModifiers);
  }, [extModifiers, requiredModifiers, severalModifiers]);

  useEffect(() => {
    if (!extColors[0]?.image) {
      setNoPicture(true, extColors[0]?.name);
      return;
    }

    setModifiers();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [extModifiers, severalModifiers, requiredModifiers]);

  useEffect(() => {
    if (stopModifiersChecking) {
      setModifiers();
    }
  }, [setModifiers, stopModifiersChecking]);

  const hasHealthIssuesProbability = healthIssuesProbability.length > 0;
  const hasUnreportedParentsIssues = [firstParent, secondParent].some(
    (parent) =>
      parent.dangersWithoutGeneticInformation.length > 0 ||
      parent.healthIssuesWithoutGeneticInformation.length > 0 ||
      parent.abilitiesWithoutGeneticInformation.length > 0 ||
      parent.colorsWithoutGeneticInformation.length > 0 ||
      parent.modifiersWithoutGeneticInformation.length > 0
  );

  return (
    <FoalProfileContainer>
      <FoalProfileWrapper>
        <GridImgCell>
          <FoalImage
            colorImage={foalColorImage}
            hoverColorImage={foalColorHoverImage}
            setHoverColorImage={enableColorHoverImage}
            modifierImages={foalModifierImages?.map((i) => i?.image || null)}
            hoverModifierImage={foalModifierHoverImage?.image || null}
            setHoverModifierImage={enableModifierHoverImage}
            isBlured={isBluredFoalImage}
            messageOnHorse={isShowMessageForFoalImage ? messageForFoalImage : ''}
          />
        </GridImgCell>

        <GridColorCell>
          <CoatTitle>Color</CoatTitle>
          {(() => {
            if (!extColors || extColors.length === 0) {
              return <EmptyGenotypes>No color info for this foal</EmptyGenotypes>;
            }

            return extColors.map((value, index) => {
              const handleOnClick = () => {
                setActiveColorIndex(index);
                setFoalColorImage(value.image);
              };

              const handleMouseEnter = () => {
                setFoalColorHoverImageBack(value.image);
                setEnableColorHoverImage(true);
              };

              const handleMouseLeave = () => {
                setEnableColorHoverImage(false);
              };

              const isActive = index === activeColorIndex;
              return (
                <SelectableProbabilityItem
                  key={index}
                  probability={value}
                  higherIsBetter={true}
                  alignValueRight={true}
                  isActive={isActive}
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  tooltipConfig={probabilityItemTooltipConfig}
                />
              );
            });
          })()}
        </GridColorCell>

        <GridModCell>
          <CoatTitle>Modifiers</CoatTitle>
          {(() => {
            if (!extModifiers || extModifiers.length === 0) {
              return <EmptyGenotypes>No modifier info for this foal</EmptyGenotypes>;
            }

            return extModifiers.map((value, index) => {
              const isCombinedWithCurrentModifiers =
                foalModifierImages.length > 0
                  ? foalModifierImages
                      .map((item) => !item?.dependencies?.prohibitedList?.includes(value.id))
                      .every((i) => i)
                  : true;

              const hasRequiredGroup = defaultModifiers.find(
                (i) => i.dependencies?.testGroup === value.dependencies?.testGroup
              );

              const isRequiredModifier = value.probability === 100;

              const isNotSelectableItem = !isCombinedWithCurrentModifiers && !hasRequiredGroup;
              const isNotClickableItem = (!isCombinedWithCurrentModifiers && !hasRequiredGroup) || isRequiredModifier;

              const handleOnClick = () => {
                if (!isNotClickableItem) {
                  if (foalModifierImages?.find((item) => item?.id === value.id)) {
                    if (defaultModifiers.find((item) => item.id === value.id)) return;
                    setFoalModifierImages(foalModifierImages?.filter((item) => item?.id !== value.id));
                    setActiveModifierIndexes(activeModifierIndexes?.filter((item) => item !== index));
                  } else {
                    foalModifierImages
                      ? setFoalModifierImages(foalModifierImages?.concat([value]))
                      : setFoalModifierImages([value]);

                    const disableModifier = defaultModifiers.find(
                      (i) => i.dependencies?.testGroup === value.dependencies?.testGroup
                    );
                    setActiveModifierIndexes(
                      activeModifierIndexes?.filter((i) => disableModifier?.positionIndex !== i).concat([index])
                    );
                    setFoalModifierImages(
                      foalModifierImages?.filter((i) => disableModifier?.id !== i.id).concat([{...value}])
                    );

                    if (disableModifier) {
                      setDefaultModifiers(
                        defaultModifiers
                          .filter((i) => i.id !== disableModifier?.id)
                          .concat([{...value, positionIndex: index}])
                      );
                    }
                  }
                }
              };

              const handleMouseEnter = () => {
                if (isCombinedWithCurrentModifiers) {
                  if (!value.image) {
                    setNoPicture(true, value.name);
                    return;
                  }

                  setFoalModifierHoverImageBack(value);
                  setEnableModifierHoverImage(true);
                }
              };

              const handleMouseLeave = () => {
                if (!value.image) {
                  setNoPicture(false);
                }
                setEnableModifierHoverImage(false);
              };

              const isActive = index === activeModifierIndexes?.find((i) => i === index);

              return (
                <SelectableProbabilityItem
                  key={index}
                  isNotSelectable={isNotSelectableItem}
                  isNotSelectableDescription={!isCombinedWithCurrentModifiers ? prohibitedModifierMessage : ''}
                  probability={value}
                  higherIsBetter={true}
                  alignValueRight={true}
                  tooltipConfig={probabilityItemTooltipConfig}
                  isActive={isActive}
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              );
            });
          })()}
        </GridModCell>

        <GridFeaturesCell>
          <div className="d-flex flex-column">
            <PriorityTitle className="align-self-center">Priority features</PriorityTitle>
            <PriorityFeatures abilities={foal.abilities} abilityDictionary={abilityDictionary} />
          </div>
        </GridFeaturesCell>

        <GridIssuesCell>
          {hasHealthIssuesProbability && (
            <HealthIssuesContainer>
              <HealthIssuesTitle>Health</HealthIssuesTitle>
              {hasHealthIssuesProbability && (
                <HealthIssuesProbability healthIssuesProbability={healthIssuesProbability} />
              )}
            </HealthIssuesContainer>
          )}
        </GridIssuesCell>

        <GridUnreportedCell>
          {hasUnreportedParentsIssues && (
            <UnreportedVariants
              unreportedIssues={unreportedParentsIssues}
              unreportedProbabilities={issuesProbability}
              firstParentProp={{id: firstParent.id, name: firstParent.name, isOwnHorse: isOwnFirstParent}}
              secondParentProp={{id: secondParent.id, name: secondParent.name, isOwnHorse: isOwnSecondParent}}
            />
          )}
        </GridUnreportedCell>
      </FoalProfileWrapper>
    </FoalProfileContainer>
  );
};

export default FoalProfileDetails;
