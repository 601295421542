import * as R from 'ramda';
import {memo, useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import styled from 'styled-components';

import {FindHorsePlaceholder} from 'BuildHorse/components/shared/placeholders/placeholders';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {Pagination} from 'Common/components/Controls';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {InputField} from 'Common/components/FormFields';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {useQueryParams} from 'Common/helpers/hooks/useQueryParams';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {IAppState} from 'Common/store/IAppState';
import {HorseFilters} from 'Filters/components';
import {
  convertHorseFiltersFormValues,
  IHorseFiltersFormValues,
  initialHorseFiltersValues,
} from 'Filters/components/HorseFilters/formConfig';
import {IFindHorseRequest} from 'FindHorse/models/FindHorseRequest';
import {actions, selectors} from 'FindHorse/store';
import {FindHorseModule} from 'FindHorse/store/module';
import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import LongTimeLoading from 'Loading/components/LongTimeLoading';

import {Filters, FiltersContainer, HeaderFiltersToggleIcon, PlaceholderContainer} from 'BuildHorse/components/styled';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import Header from 'Common/components/Header/Header';
import ColorPalette from 'Common/constants/ColorPalette';
import {VisitorType} from 'Common/constants/VisitorType';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {useOnRequestCommunication} from 'Common/helpers/hooks/useOnRequestCommunication';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {IColor} from 'Dictionaries/models/IColor';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import ExcludePanel from 'ExcludeHorse/components/ExcludePanel';
import {useExcludeListModal} from 'ExcludeHorse/hooks/useExcludeListModal';
import {
  convertSelectedFiltersToChips,
  PinnedSelectedFilter,
  SelectedFilterName,
} from 'FindHorse/helpers/convertSelectedFiltersToChips';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import Chips from './Chips/Chips';
import PotentialPartners from './PotentialPartners/PotentialPartners';

const COULD_NOT_FIND_PARTNERS = `Sorry. We couldn't find matching horses`;
const UNKNOWN_ERROR = `Error on loading results`;

const RootForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
`;

const Partners = styled.div<{isHidden?: boolean}>`
  flex-grow: 1;
  width: 100%;
  padding: 32px 16px;
  display: ${(p) => (p.isHidden ? 'none' : 'block')};

  @media ${breakpoints.sm} {
    padding: 32px;
    display: block;
  }

  @media ${breakpoints.md} {
    padding: 32px 48px;
  }
`;

const SearchResult = styled.div`
  flex-grow: 1;
  margin-top: 24px;

  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const SearchSection = styled.div`
  @media ${breakpoints.sm} {
    display: grid;
    grid-template-columns: auto 168px;
    grid-gap: 16px;
  }
`;

const SearchButton = styled(PrimaryButton)`
  width: 100%;
`;

const FoalsList = styled.div`
  flex-grow: 1;
  max-width: 1030px;
`;

const EmptyFoalsMessage = styled.div`
  text-align: center;
  margin-top: 32px;
  font-size: ${Typography.size.size18};
`;

const LoaderContainer = styled.div`
  margin-top: 100px;
`;

const FixedWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 28px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 0px 8px rgba(0, 0, 0, 0.04);
  background: ${Theme.color.white};
`;

const TabletSearchSection = styled.div<{isFiltersOpen?: boolean}>`
  background: ${Theme.color.white};
  padding: 24px 32px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 40px auto 168px;
  box-shadow: ${(p) =>
    p.isFiltersOpen ? '0px 0px 4px rgba(0, 0, 0, 0.02), 0px 6px 14px rgba(0, 0, 0, 0.05)' : 'unset'};
`;

const FiltersToggle = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${Theme.color.primary};
  cursor: pointer;
`;

const SearchIcon = styled(ColoredIcon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const SelectedFilters = styled.div`
  margin-top: 16px;
`;

function convertFormValues(values: IFormValues): IFindHorseRequest {
  const {horseName, ...horseFilters} = values;
  return {
    ...convertHorseFiltersFormValues('fah', horseFilters),
    horseName: horseName || '',
  };
}

interface IUrlParams extends IHorseFiltersFormValues {
  currentPage: number;
  horseName: string;
}

interface IFormValues extends IHorseFiltersFormValues {
  horseName?: string;
  currentPage?: number;
}

interface IExternalDictionaries {
  colorDictionary: ISimpleDictionary<IColor>;
  abilityDictionary: ISimpleDictionary<IAbilities>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalDictionaries;

type Props = OuterProps & FormikProps<IFormValues>;

function FindHorseLayout(props: Props) {
  const {horses, pagination, horsesLoading, colors, abilities, colorsLoading, abilitiesLoading} = props;
  const {values, isSubmitting, setValues, submitCount, setFieldValue, submitForm} = props;
  const [params, setParams] = useQueryParams<IUrlParams>();
  const {isMobile, isTablet, isDesktop, currentSize} = useMediaQuery();
  const [isFiltersOpen, setIsFiltersOpen] = useState(true);
  const [isShowExcluded, setIsShowExcluded] = useState(values.isHideExcludedHorses);

  useOnRequestCommunication(horsesLoading, () => {
    if (isMobile && isFiltersOpen) {
      setIsFiltersOpen(false);
    }
  });

  useLayoutEffect(
    () => {
      const {currentPage, ...filters} = params;
      setValues({...initialHorseFiltersValues, ...filters, currentPage: currentPage || 1});
      if (R.isEmpty(filters)) {
        return;
      }
      submitForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setParams({...values});
  }, [values, setParams]);

  useEffect(() => {
    if (isSubmitting) {
      setIsShowExcluded(values.isHideExcludedHorses);
    }
  }, [isSubmitting, values.isHideExcludedHorses]);

  const handlePageSelect = useCallback(
    (currentPage: number) => {
      scrollToTop();
      setFieldValue('currentPage', currentPage);
      submitForm();
    },
    [setFieldValue, submitForm]
  );

  const {excludeListModal, openExcludeListModal} = useExcludeListModal({});

  const isLoading = [horsesLoading, colorsLoading, abilitiesLoading].some((i) => i.isRequesting) || isSubmitting;

  const isShowMoreColumns = !isFiltersOpen && isTablet;

  const hasSubmitted = submitCount !== 0;

  const toggleFilters = useCallback(() => {
    setIsFiltersOpen(!isFiltersOpen);
  }, [isFiltersOpen]);

  const showExcluded = useCallback(() => {
    setFieldValue('isHideExcludedHorses', true);
    submitForm();
  }, [setFieldValue, submitForm]);

  const filtersToggle = useMemo(() => {
    return (
      <FiltersToggle onClick={toggleFilters}>
        <ColoredIcon
          name={isFiltersOpen ? IconName.Close : IconName.FiltersToggle}
          color={Theme.color.white}
          size={isFiltersOpen ? 16 : 20}
          fill={true}
          stroke={false}
        />
      </FiltersToggle>
    );
  }, [toggleFilters, isFiltersOpen]);

  const headerFiltersToggle = useMemo(() => {
    return (
      <HeaderFiltersToggleIcon
        name={isFiltersOpen ? IconName.Close : IconName.FiltersToggle}
        size={isFiltersOpen ? 18 : 24}
        onClick={toggleFilters}
      />
    );
  }, [toggleFilters, isFiltersOpen]);

  const pageHeader = useMemo(() => {
    return <Header leftSection={headerFiltersToggle} />;
  }, [headerFiltersToggle]);

  const onSubmit = () => {
    setFieldValue('currentPage', 1);
    submitForm();
  };
  const submitButton = (
    <SearchButton onClick={onSubmit} isLoading={isLoading} type="button">
      Search
    </SearchButton>
  );

  const searchInput = (
    <div className="position-relative">
      <SearchIcon name={IconName.Search} size={24} stroke={false} fill={true} color={ColorPalette.gray44} />
      <InputField name="horseName" style={{marginBottom: 0}} inputStyle={{paddingLeft: 50}} placeholder="Horse name" />
    </div>
  );
  const removeFilter = useCallback(
    (fieldName: SelectedFilterName) => {
      if (fieldName === 'ages') {
        setFieldValue('ageFrom', initialHorseFiltersValues.ageFrom);
        setFieldValue('ageTo', initialHorseFiltersValues.ageTo);
        return;
      }
      if (fieldName === 'speed') {
        setFieldValue('isSpeedSelected', false);
        return;
      }
      if (fieldName === 'gait') {
        setFieldValue('isGaitSelected', false);
        return;
      }
      if (fieldName === 'temperament') {
        setFieldValue('isTemperamentSelected', false);
        return;
      }
      setFieldValue(fieldName, initialHorseFiltersValues[fieldName]);
    },
    [setFieldValue]
  );

  const selectedFilters = useMemo(() => {
    const pinnedChips: PinnedSelectedFilter[] = [
      {name: 'ageFrom', pinnedChip: !values.includeTwoYears && values.ageFrom === 2 && !values.ageTo},
    ];

    return convertSelectedFiltersToChips(values, initialHorseFiltersValues, colors, abilities, pinnedChips);
  }, [values, colors, abilities]);

  const renderContent = (
    <>
      <Helmet>
        <title>Find a horse</title>
      </Helmet>
      <RootForm>
        {excludeListModal}

        {isTablet && (
          <TabletSearchSection isFiltersOpen={isFiltersOpen}>
            {filtersToggle}
            {searchInput}
            {submitButton}
          </TabletSearchSection>
        )}
        <Content>
          <FiltersContainer isHidden={!isFiltersOpen} currentSize={currentSize}>
            <Filters>
              <HorseFilters variant="fah" onOpenExcludeModal={openExcludeListModal} />
            </Filters>
            {isMobile && isFiltersOpen && <FixedWrapper>{submitButton}</FixedWrapper>}
          </FiltersContainer>
          <Partners isHidden={isFiltersOpen}>
            {currentSize !== 'tablet' && (
              <SearchSection>
                {searchInput}
                {isDesktop && submitButton}
              </SearchSection>
            )}
            {isDesktop && (
              <SelectedFilters>
                <Chips chips={selectedFilters} onRemove={removeFilter} />
              </SelectedFilters>
            )}
            <SearchResult>
              {(() => {
                if (horsesLoading.isRequesting) {
                  return (
                    <LoaderContainer>
                      <LongTimeLoading />
                    </LoaderContainer>
                  );
                }

                if (horsesLoading.error) {
                  return <ErrorMessage>{getCommonErrors(horsesLoading.error) || UNKNOWN_ERROR}</ErrorMessage>;
                }

                if (horses.length > 0) {
                  return (
                    <FoalsList>
                      {!isShowExcluded && (
                        <ExcludePanel onShow={showExcluded} onOpenExcludeModal={openExcludeListModal} />
                      )}
                      <PotentialPartners horses={horses} showOneColumn={!isShowMoreColumns} />
                      {pagination.pageCount > 1 && (
                        <Pagination pagination={pagination} onPageSelect={handlePageSelect} />
                      )}
                    </FoalsList>
                  );
                }

                if (!hasSubmitted) {
                  return (
                    <PlaceholderContainer>
                      <FindHorsePlaceholder />
                    </PlaceholderContainer>
                  );
                }

                return <EmptyFoalsMessage>{COULD_NOT_FIND_PARTNERS}</EmptyFoalsMessage>;
              })()}
            </SearchResult>
          </Partners>
        </Content>
      </RootForm>
    </>
  );

  const {isVisitorType} = useVisitorTypeService();
  const isAssociation = isVisitorType(VisitorType.AssociationEmployee);

  return isAssociation ? (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} withoutPaddings={true} header={pageHeader}>
      {renderContent}
    </BusinessPortalLayout>
  ) : (
    <BaseLayout withoutPaddings={true} isBackButtonDenied={true} header={pageHeader}>
      {renderContent}
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState, props: IExternalDictionaries) => {
  const {colorDictionary, abilityDictionary} = props;

  const {selectors: colorSelectors} = colorDictionary;
  const {selectors: abilitySelectors} = abilityDictionary;

  return {
    colors: colorSelectors.selectItems(state),
    colorsLoading: colorSelectors.selectCommunication(state, 'itemsLoading'),
    abilities: abilitySelectors.selectItems(state),
    abilitiesLoading: abilitySelectors.selectCommunication(state, 'itemsLoading'),
    horses: selectors.selectHorses(state),
    horsesLoading: selectors.selectCommunication(state, 'horsesLoading'),
    pagination: selectors.selectPagination(state),
  };
};

const mapDispatchToProps = {
  findHorse: actions.findHorse,
};

async function handleSubmit(values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) {
  const {props, setSubmitting} = formikBag;
  const {findHorse} = props;

  scrollToTop();

  const {currentPage, ...filters} = values;
  const convertedValues = convertFormValues(filters);
  await findHorse(convertedValues, {countPerPage: 10, currentPage: currentPage || 1});
  setSubmitting(false);
}

const FindHorseLayoutFormik = withFormik<OuterProps, IFormValues>({
  handleSubmit,
})(memo(FindHorseLayout));

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(FindHorseLayoutFormik);
const Exported = () => {
  const {colors, abilities} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[FindHorseModule]}>
      <Connected colorDictionary={colors} abilityDictionary={abilities} />
    </DynamicModuleLoader>
  );
};

export default Exported;
