import {IWithUserMapFieldProps} from 'Common/components/FormFields/UserMapField/UserMapField';
import {getStringifyAddress} from 'Common/helpers/getStringifyAddress';
import {IUserUpdateRequest} from 'UserProfile/services/serverModels/IUserUpdateRequest';

type IRequiredUserMapFieldPropsServer = Omit<IWithUserMapFieldProps, 'address' | 'isManualAddressMode'>;

export function withUserMapFieldPropsToRequest<U extends IWithUserMapFieldProps>(
  userId: number,
  values: U
): IUserUpdateRequest {
  const {...rest} = values;

  const model = {
    ...rest,
    id: userId,
    location: !values.isManualAddressMode ? values.location : null,
  };

  delete model.address;
  delete model.isManualAddressMode;

  return model;
}

export function withUserMapFieldPropsToClient<
  T extends IRequiredUserMapFieldPropsServer,
  U extends IWithUserMapFieldProps
>(model: T): U {
  return {
    ...model,
    address: model.userAddress ? getStringifyAddress(model.userAddress) : '',
    isManualAddressMode: !model.location,
  } as U;
}
