import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import {actions, AdminHorsesModule, selectors} from 'Admin/AdminDashboard/store/adminHorses';
import {
  selectors as horseTransferSelectors,
  AdminHorseTransferModule,
} from 'Admin/AdminDashboard/store/adminHorseTransfer';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';

type IConnected = ConnectedProps<typeof connector>;

export type IWithHorseActionsProps = IConnected;

function withHorseActions<T>(Component: React.ComponentType<T>) {
  const wrapped = (props: any) => <Component {...props} />;
  return connector(wrapped);
}

const mapStateToProps = (state: IAppState) => ({
  horseUpdating: selectors.selectCommunication(state, 'horseUpdating'),
  horseCreating: selectors.selectCommunication(state, 'horseCreating'),
  horseOwnerChanging: horseTransferSelectors.selectCommunication(state, 'horseOwnerChanging'),
  horseDeleting: selectors.selectCommunication(state, 'horseDeleting'),
  horseArchiving: selectors.selectCommunication(state, 'horseArchiving'),
});

const mapDispatchToProps = {
  deleteHorse: actions.deleteHorse,
  setArchiveHorse: actions.setArchiveHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function getWithHorseActionsModules() {
  return [AdminHorsesModule, AdminOnlineReportModule, AdminHorseTransferModule];
}

export {getWithHorseActionsModules, withHorseActions as default};
