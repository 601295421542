import {memo} from 'react';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Loading from 'Loading/components/Loading';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {ChatRoot, ChatText} from '../../styled';
import IncomingMessage from '../Messages/IncomingMessage';

interface IProps {
  rejectedUserRequest: IMessageCenterUser;
  isLoading: boolean;

  onCancelRejectRequest(chatRequestId: number): void;
}

function RejectedIncomingRequest(props: IProps) {
  const {rejectedUserRequest, onCancelRejectRequest, isLoading} = props;

  const onCancelRejectRequestClick = () => {
    onCancelRejectRequest(rejectedUserRequest.id);
  };

  return (
    <ChatRoot className="d-flex flex-column">
      {isLoading && <Loading />}

      <IncomingMessage message={rejectedUserRequest.message} url={rejectedUserRequest.user.avatar?.url} />
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>
          You have rejected this request. If you change your mind, you may restore the message by clicking, "Restore
          Request"
        </ChatText>

        <div className="d-flex align-items-center">
          <PrimaryButton size="small" onClick={onCancelRejectRequestClick}>
            Restore request
          </PrimaryButton>
        </div>
      </div>
    </ChatRoot>
  );
}

export default memo(RejectedIncomingRequest);
