import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportCoatColorDetailed} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColorDetailed';
import {IOnlineReportHealthVariantDetailed} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariantDetailed';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IOnlineReportAbilityDetailed} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbilityDetailed';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {IServerOnlineReportHealthVariants} from 'OnlineReport/services/models/IOnlineReportHealthVariantsServer';
import {convertServerHealthVariantsToClient} from 'OnlineReport/services/converters/healthVariant';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {getApiByReportType} from 'OnlineReport/helpers/getApiByReportType';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';
import {withImage} from 'Common/helpers/withImage';
import {IServerHorseOwner} from './models/owner';
import {convertServerHorseOwnerToClient} from './converters/owner';
import {IServerOnlineReportHorseDetails} from './models/IServerOnlineReportHorseDetails';
import {convertOnlineReportHorseDetails} from './converters/horse';
import {withAvatar} from 'Common/helpers/withAvatar';

type AdminReportType = OnlineReportType.User | OnlineReportType.Association;
type ApiByType = Record<AdminReportType, string>;

const getSummaryMethod = async (horseId: number, postfix: string): Promise<IOnlineReportSummary> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Summary/${postfix}/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Summary/${postfix}/${horseId}`,
  };

  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportSummary>>(api);
  return result.data.data;
};

const getHorseInfo = async (horseId: number): Promise<IOnlineReportHorseInfo> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Horse/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Horse/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHorseInfo>>(api);
  return withAvatar(result.data.data);
};

const getHorseOwner = async (horseId: number): Promise<string> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Owner/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Owner/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IServerHorseOwner>>(api);
  return convertServerHorseOwnerToClient(result.data.data);
};

const getHorseDetails = async (horseId: number): Promise<IOnlineReportHorseDetails> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/HorseDetails/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/HorseDetails/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const response = await axiosWrapper.get<IObjectResponse<IServerOnlineReportHorseDetails>>(api);
  return convertOnlineReportHorseDetails(response.data.data);
};

const getSummaryColors = async (horseId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(horseId, 'Colors');
};

const getSummaryAbilities = async (horseId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(horseId, 'Abilities');
};

const getSummaryHealthIssues = async (horseId: number): Promise<IOnlineReportSummary> => {
  return getSummaryMethod(horseId, 'HealthIssues');
};

const getGeneticVariants = async (horseId: number): Promise<IOnlineReportGeneticVariants> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/GeneticVariants/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/GeneticVariants/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportGeneticVariants>>(api);
  return result.data.data;
};

const getHorseBreedPanel = async (horseId: number, orderId?: number): Promise<IHorseBreedPanel[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Panels/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Panels/${horseId}`,
  };
  const api = getApiByReportType(apis);

  const response = await axiosWrapper.get<IListResponse<IHorseBreedPanel>>(api);
  return response.data.data;
};

const getCoatColor = async (horseId: number): Promise<IOnlineReportCoatColor[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/PartialColors/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/PartialColors/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IOnlineReportCoatColor>>(api);
  return result.data.data.map(withImage);
};

const getCoatColorDetailed = async (colorId: number): Promise<IOnlineReportCoatColorDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportCoatColorDetailed>>(
    `/OnlineReport/PartialColorDetails/${colorId}`
  );
  return result.data.data;
};

const getHealthVariants = async (horseId: number): Promise<IOnlineReportHealthVariants[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/HealthIssues/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/HealthIssues/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IServerOnlineReportHealthVariants>>(api);
  return result.data.data.map(convertServerHealthVariantsToClient);
};

const getHealthVariantDetailed = async (
  healthIssueId: number,
  isAggregated: boolean
): Promise<IOnlineReportHealthVariantDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportHealthVariantDetailed>>(
    !isAggregated
      ? `/OnlineReportHealthIssues/${healthIssueId}`
      : `/OnlineReportHealthIssueAggregators/${healthIssueId}`
  );
  return result.data.data;
};

const getAbilities = async (horseId: number): Promise<IOnlineReportAbility[]> => {
  const apis: ApiByType = {
    [OnlineReportType.User]: `/OnlineReport/Abilities/${horseId}`,
    [OnlineReportType.Association]: `/AssociationOnlineReport/Abilities/${horseId}`,
  };
  const api = getApiByReportType(apis);
  const result = await axiosWrapper.get<IListResponse<IOnlineReportAbility>>(api);
  return result.data.data.map(withImage);
};

const getAbilityDetailed = async (abilityId: number, isAggregated: boolean): Promise<IOnlineReportAbilityDetailed> => {
  const result = await axiosWrapper.get<IObjectResponse<IOnlineReportAbilityDetailed>>(
    !isAggregated ? `/OnlineReportAbilities/${abilityId}` : `/OnlineReportAbilityAggregators/${abilityId}`
  );
  return result.data.data;
};

// TODO: delete?
const downloadDiagnosticReport = async (horseId: number): Promise<void> => {
  const response = await axiosWrapper.get(`/OnlineReport/DiagnosticReport/pdf/${horseId}`, {responseType: 'blob'});
  downloadResponse(response, 'diagnostic-report');
};

export default {
  getHorseInfo,
  getHorseOwner,
  getHorseDetails,
  getSummaryAbilities,
  getSummaryColors,
  getSummaryHealthIssues,
  getCoatColor,
  getCoatColorDetailed,
  getHealthVariants,
  getHealthVariantDetailed,
  getAbilities,
  getAbilityDetailed,
  downloadDiagnosticReport,
  getGeneticVariants,
  getHorseBreedPanel,
};
