import {IAdminActionsProps} from 'Admin/AdminDashboard/helpers/hooks/common';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';

export interface IDeleteMessageActionProps extends Omit<IAdminActionsProps, 'action'> {
  action(messageId: number): void;
}

interface IMessageActionsProps {
  deleteMessageAction: IDeleteMessageActionProps;
}

interface IProps {
  data: IMessageActionsProps;
}

export function useMessageActions(props: IProps) {
  const {
    data: {deleteMessageAction},
  } = props;

  const {confirmModal: deleteMessageConfirmModal, openConfirmModal: openDeleteMessageConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to delete the message?',
    confirmCommunication: deleteMessageAction.communication,
    successMessage: 'Message successfully deleted',
    onConfirmAction: deleteMessageAction.action,
    confirmId: 'deleteMessageConfirm',
    onSuccess: deleteMessageAction.onSuccess,
  });

  const messageActionsModal = <>{deleteMessageConfirmModal}</>;

  return {messageActionsModal, openDeleteMessageConfirmModal};
}
