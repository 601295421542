import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/diagnostic/index';
import {
  actions as adminActions,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/index';
import {Nullable} from 'Common/types';
import {OnlineReportType} from '../shared/OnlineReportType';
import {IRequiredTest} from 'Admin/AdminDashboard/models/IRequiredTest';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';

export interface IState {
  summaryAbilities: Nullable<IOnlineReportSummary>;
  summaryAbilitiesLoading: ICommunication;
  summaryColors: Nullable<IOnlineReportSummary>;
  summaryColorsLoading: ICommunication;
  summaryHealthIssues: Nullable<IOnlineReportSummary>;
  summaryHealthIssuesLoading: ICommunication;
  requiredTests: IRequiredTest[];
  requiredTestsLoading: ICommunication;
  breedPanels: IHorseBreedPanel[];
  breedPanelsLoading: ICommunication;
  geneticVariants: Nullable<IOnlineReportGeneticVariants>;
  geneticVariantsLoading: ICommunication;
}

export interface IDispatch {
  getRequiredTests(horseId: number, orderId: number): void;
  getSummaryAbilities(horseId: number, orderId?: number): void;
  getSummaryColors(horseId: number, orderId?: number): void;
  getSummaryHealthIssues(horseId: number, orderId?: number): void;
  getBreedPanels(horseId: number, orderId?: number | undefined): void;
  resetRequiredTestsResults(): void;
  getGeneticVariants(horseId: number, orderId?: number): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    summaryAbilities: userSelectors.selectSummaryAbilities(state),
    summaryAbilitiesLoading: userSelectors.selectCommunication(state, 'summaryAbilitiesLoading'),
    summaryColors: userSelectors.selectSummaryColors(state),
    summaryColorsLoading: userSelectors.selectCommunication(state, 'summaryColorsLoading'),
    summaryHealthIssues: userSelectors.selectSummaryHealthIssues(state),
    summaryHealthIssuesLoading: userSelectors.selectCommunication(state, 'summaryHealthIssuesLoading'),
    requiredTests: adminSelectors.selectRequiredTests(state),
    requiredTestsLoading: adminSelectors.selectCommunication(state, 'requiredTestsLoading'),
    breedPanels: userSelectors.selectHorseBreedPanels(state),
    breedPanelsLoading: userSelectors.selectCommunication(state, 'horseBreedPanelsLoading'),
    geneticVariants: userSelectors.selectGeneticVariants(state),
    geneticVariantsLoading: userSelectors.selectCommunication(state, 'geneticVariantsLoading'),
  }),
  dispatch: {
    getRequiredTests: adminActions.getRequiredTests,
    getSummaryAbilities: userActions.getSummaryAbilities,
    getSummaryColors: userActions.getSummaryColors,
    getSummaryHealthIssues: userActions.getSummaryHealthIssues,
    getBreedPanels: userActions.getHorseBreedPanels,
    resetRequiredTestsResults: adminActions.resetRequiredTestsResults,
    getGeneticVariants: userActions.getGeneticVariants,
  },
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    summaryAbilities: adminSelectors.selectSummaryAbilities(state),
    summaryAbilitiesLoading: adminSelectors.selectCommunication(state, 'summaryAbilitiesLoading'),
    summaryColors: adminSelectors.selectSummaryColors(state),
    summaryColorsLoading: adminSelectors.selectCommunication(state, 'summaryColorsLoading'),
    summaryHealthIssues: adminSelectors.selectSummaryHealthIssues(state),
    summaryHealthIssuesLoading: adminSelectors.selectCommunication(state, 'summaryHealthIssuesLoading'),
    requiredTests: adminSelectors.selectRequiredTests(state),
    requiredTestsLoading: adminSelectors.selectCommunication(state, 'requiredTestsLoading'),
    breedPanels: adminSelectors.selectHorseBreedPanels(state),
    breedPanelsLoading: adminSelectors.selectCommunication(state, 'horseBreedPanelsLoading'),
    geneticVariants: adminSelectors.selectGeneticVariants(state),
    geneticVariantsLoading: adminSelectors.selectCommunication(state, 'geneticVariantsLoading'),
  }),
  dispatch: {
    getRequiredTests: adminActions.getRequiredTests,
    getSummaryAbilities: adminActions.getSummaryAbilities,
    getSummaryColors: adminActions.getSummaryColors,
    getSummaryHealthIssues: adminActions.getSummaryHealthIssues,
    getBreedPanels: adminActions.getHorseBreedPanels,
    resetRequiredTestsResults: adminActions.resetRequiredTestsResults,
    getGeneticVariants: adminActions.getGeneticVariants,
  },
};

export const summaryDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: adminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: adminDistributor,
};
