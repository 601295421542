import {Reducer} from 'redux';

import {ChatType} from 'MessageCenter/constants/ChatType';

import {
  IUserMessageCenterState,
  Actions,
  getReceivedUsersChatsRequestsActionTypes,
  getUsersChatsActionTypes,
  getUsersChatRequestsActionTypes,
  getRejectedIncomingUsersChatsActionTypes,
  getUsersChatMessagesActionTypes,
  getRejectedOutgoingUsersChatsActionTypes,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatDetailsActionTypes,
  getBlockedUsersActionTypes,
  resetBlockedUsersActionTypes,
  resetUserToAdminChatDetailsActionTypes,
  getUserToAdminChatStatusActionTypes,
  getUserToAdminChatIsOnlineActionTypes,
} from '../types';
import {AdminChatStatus} from 'MessageCenter/constants/AdminChatStatus';

const initialState: IUserMessageCenterState['data'] = {
  messageCenterUsers: [],
  usersChatMessages: [],
  userToAdminChatMessages: null,
  userToAdminChatDetails: null,
  blockedUsers: [],
  userToAdminChatIsOnline: false,
  userToAdminChatStatus: AdminChatStatus.IsNotExist,
};

export const dataMessageCenterReducer: Reducer<IUserMessageCenterState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getUsersChatsActionTypes.SUCCESS: {
      const oldData = state.messageCenterUsers.filter((x) => x.chatType !== ChatType.Messages);
      return {...state, messageCenterUsers: [...oldData, ...action.payload]};
    }
    case getReceivedUsersChatsRequestsActionTypes.SUCCESS: {
      const oldData = state.messageCenterUsers.filter((x) => x.chatType !== ChatType.IncomingRequest);
      return {...state, messageCenterUsers: [...oldData, ...action.payload]};
    }
    case getUsersChatRequestsActionTypes.SUCCESS: {
      const oldData = state.messageCenterUsers.filter((x) => x.chatType !== ChatType.OutgoingRequest);
      return {...state, messageCenterUsers: [...oldData, ...action.payload]};
    }
    case getRejectedIncomingUsersChatsActionTypes.SUCCESS: {
      const oldData = state.messageCenterUsers.filter((x) => x.chatType !== ChatType.RejectedIncomingRequest);
      return {...state, messageCenterUsers: [...oldData, ...action.payload]};
    }
    case getRejectedOutgoingUsersChatsActionTypes.SUCCESS: {
      const oldData = state.messageCenterUsers.filter((x) => x.chatType !== ChatType.RejectedOutgoingRequest);
      return {...state, messageCenterUsers: [...oldData, ...action.payload]};
    }
    case getUsersChatMessagesActionTypes.SUCCESS: {
      return {...state, usersChatMessages: action.payload};
    }
    case getUserToAdminChatMessagesActionTypes.SUCCESS: {
      return {...state, userToAdminChatMessages: action.payload};
    }
    case getUserToAdminChatDetailsActionTypes.SUCCESS: {
      return {...state, userToAdminChatDetails: action.payload};
    }
    case getBlockedUsersActionTypes.SUCCESS: {
      return {...state, blockedUsers: action.payload};
    }
    case getUserToAdminChatStatusActionTypes.SUCCESS: {
      return {...state, userToAdminChatStatus: action.payload};
    }
    case getUserToAdminChatIsOnlineActionTypes.SUCCESS: {
      return {...state, userToAdminChatIsOnline: action.payload};
    }
    case resetBlockedUsersActionTypes.RESET:
      return {...state, blockedUsers: initialState.blockedUsers};
    case resetUserToAdminChatDetailsActionTypes.RESET:
      return {...state, userToAdminChatDetails: initialState.userToAdminChatDetails};

    default: {
      return state;
    }
  }
};
