import React, {memo, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components';

import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import CompositionPie from '../CompositionChart/CompositionPie';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {AncestryReportSection, SummaryTitle} from '../common/styled';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import KinshipHorses from './KinshipHorses';
import RelatedHorses from './RelatedHorses';
import {ExpansionPanel, Section, ShadowType} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {breakpoints} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';
import {IResponseError} from 'Common/models/IError';
import {PageBreak} from 'OnlineReportPrintable/common/styled';
import ColumnContainer from '../common/ColumnContainer/ColumnContainer';
import {Direction} from 'Common/constants/Direction';
import HintIcon from 'Common/components/HintIcont/HintIcon';
import {OrderReportType} from 'Common/constants/OrderReportType';
import {BorderedBlock, PanelLabel} from 'HorseProfile/components/shared/StyledComponents';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const HORSES_LIKE_ME_TOOLTIP =
  'These are horses whose genetic composition is similar to your horse, though they may be unrelated. A higher percentage of similarity may indicate either a possible relative or horse in the same “breed” classification. Lower percentages will indicate common ancestors in reference groups such as % Thoroughbred, Arabian and so on.';
const FIND_MY_HERD_TOOLTIP =
  'These are horses who are likely closely related to your horse. They may be cousins, aunts, uncles, or even parents, siblings and offspring. Welcome to your herd!';

const Root = styled(AncestryReportSection)<{isFullWidth?: boolean}>`
  display: flex;
  ${(props) => !props.isFullWidth && 'align-self: start;'}
  @media print {
    display: block;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
`;

const ElementHeader = styled.div<{isPrintable?: boolean}>`
  margin-left: 0px;
  margin-bottom: ${({isPrintable}) => (isPrintable ? 24 : 0)}px;
  page-break-inside: avoid;
  flex-direction: column;

  @media ${breakpoints.md} {
    flex-direction: row;
    margin-left: ${(props) => (props.isPrintable ? 0 : 23)}px;
  }

  @media print {
    margin-left: ${(props) => (props.isPrintable ? 0 : 23)}px;
    flex-direction: row;
  }
`;

const ElementHeaderNameStyles = css`
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: 20px;
  line-height: 32px;
  width: 100%;
  outline: none;
`;

const ElementHeaderName = styled.div`
  ${ElementHeaderNameStyles};
  color: ${Theme.color.black};
`;

const ElementHeaderNameWrapper = styled(ElementHeaderName)`
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  justify-content: center;

  @media ${breakpoints.sm} {
    text-align: left;
    width: 90%;
    margin-bottom: 0;
    justify-content: start;
  }
  @media ${breakpoints.md} {
    width: 50%;
  }
  @media print {
    width: 50%;
  }
`;

const PrintableTitle = styled(SummaryTitle)`
  margin-bottom: 28px;
`;

const PrintableWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    display: block;
  }
`;

const PrintableContainer = styled.div`
  margin-bottom: 32px;
`;

const RelatedHorsesContainer = styled.div<{count?: number}>`
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
    margin-right: 30%;
    height: 740px;
    ${(props) => {
      if (props.count && props.count > 0) {
        switch (props.count) {
          case 1:
          case 2:
          case 3:
            return 'margin-bottom: -200px';
          case 4:
            return 'margin-bottom: -60px';

          default:
            break;
        }
      }
      return '';
    }};
  }

  @media ${breakpoints.md} {
    margin-right: 50%;
  }
`;

const HorseProfileBlock = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: -20px;
`;

const SimilarCelebrityOnlineReportWrapper = styled.div`
  @media ${breakpoints.md} {
    position: absolute;
    top: 0;
    right: 125px;
  }

  @media print {
    position: absolute;
    top: 2150px !important;
    right: 100px !important;
  }
`;

const SimilarCelebrityHorseProfileWrapper = styled.div`
  margin-top: -20px;

  @media ${breakpoints.md} {
    margin-top: 0px;
  }
`;

const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

interface IProps {
  horse: IOnlineReportHorseInfo;
  population: IAncestryPopulation[];
  relatedHorses: IAncestryRelatedHorse[];
  kinshipHorses: IAncestryKinshipHorse[];
  isPrintable: boolean;
  relatedErrors?: IResponseError;
  kinshipErrors?: IResponseError;
  isHorseProfile?: boolean;
}

function HorsesLikeMe(props: IProps) {
  const {
    population,
    relatedHorses,
    horse,
    kinshipHorses,
    relatedErrors,
    kinshipErrors,
    isPrintable,
    isHorseProfile = false,
  } = props;

  const {isMobile, isDesktop} = useMediaQuery();
  const {currentVisitorType} = useVisitorTypeService();

  const {onlineReportErrors: relatedOnlineReportErrors} = useOnlineReportErrors({
    horseId: horse.id,
    error: relatedErrors,
    reportType: OrderReportType.Parentage,
  });
  const {onlineReportErrors: kinshipOnlineReportErrors} = useOnlineReportErrors({
    horseId: horse.id,
    error: kinshipErrors,
    reportType: OrderReportType.Parentage,
  });

  const sortedHorses = useMemo(() => {
    return [...relatedHorses].sort((a, b) => {
      if (a.percent > b.percent) return -1;
      return 1;
    });
  }, [relatedHorses]);

  const celebrityHorses = useMemo(() => {
    return sortedHorses.filter((horse) => horse.isCelebrity);
  }, [sortedHorses]);

  const renderHeader = useCallback(
    (header: string, tooltipValue: string) => {
      return (
        <ElementHeader className="d-flex w-100 align-items-center" isPrintable={isPrintable}>
          <ElementHeaderNameWrapper className="d-flex align-items-center">
            {header}
            <HintIcon tooltip={tooltipValue} />
          </ElementHeaderNameWrapper>
        </ElementHeader>
      );
    },
    [isPrintable]
  );

  const renderRelatedHorses = useMemo(
    () => (
      <>
        <RelatedHorsesContainer
          className="d-flex justify-content-center align-items-center"
          count={relatedHorses.length}
        >
          {isMobile && (
            <CompositionPie
              population={population}
              avatar={horse.avatar}
              title={horse.name}
              size={isHorseProfile ? 'big' : 'default'}
            />
          )}
          <ColumnContainer
            columnName={isHorseProfile ? '' : 'Horses Like Me'}
            direction={isMobile ? Direction.Column : Direction.Row}
            childrenClassName="justify-content-center align-items-center w-100"
          >
            {!isMobile && (
              <CompositionPie
                population={population}
                avatar={horse.avatar}
                title={horse.name}
                size={isHorseProfile ? 'big' : 'default'}
              />
            )}
            <RelatedHorses horses={relatedHorses?.slice(0, 5) || []} isPrintable={isPrintable} />
          </ColumnContainer>
        </RelatedHorsesContainer>
        {/* Celebrity Horses Like Me - Report Section START */}
        {!isHorseProfile && celebrityHorses.length > 0 && (
          <ColumnContainer
            columnName={!isDesktop ? 'Celebrity Horse Like Me' : ''}
            direction={!isDesktop ? Direction.Column : Direction.Row}
            childrenClassName="justify-content-center align-items-center w-100"
          >
            <SimilarCelebrityOnlineReportWrapper
              id="celeb-wrapper"
              style={isPrintable ? {top: '1350px', right: '750px'} : {}}
            >
              {(isDesktop || isPrintable) && <h3>Celebrity Horse Like Me</h3>}
              <CompositionPie
                population={celebrityHorses[0].breeds}
                avatar={celebrityHorses[0].horse?.avatar}
                title={celebrityHorses[0].horse?.name}
                size={'default'}
                profileUrl={
                  celebrityHorses[0].horse?.id
                    ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${celebrityHorses[0].horse?.id}`
                    : ''
                }
                relatedHorse={celebrityHorses[0]}
              />
            </SimilarCelebrityOnlineReportWrapper>
          </ColumnContainer>
        )}
        {/* Celebrity Horses Like Me - Report Section END */}
      </>
    ),
    [horse.avatar, horse.name, isHorseProfile, isMobile, isPrintable, population, relatedHorses]
  );

  const renderKinshipHorses = useMemo(
    () => (
      <div className="d-flex flex-column align-items-center">
        <CompositionPie
          population={population}
          avatar={horse.avatar}
          title={horse.name}
          size={isHorseProfile ? 'big' : 'default'}
        />
        <KinshipHorses horses={kinshipHorses} isPrintable={isPrintable} isHorseProfile={isHorseProfile} />
      </div>
    ),
    [horse.avatar, horse.name, isHorseProfile, isPrintable, kinshipHorses, population]
  );

  return (
    <>
      <Root className="align-items-center w-100" isFullWidth={isMobile}>
        {!isPrintable && !isHorseProfile && (
          <div className="w-100">
            <ExpansionPanel
              headerContent={renderHeader('Similar Genetic Composition', HORSES_LIKE_ME_TOOLTIP)}
              arrowSide={isMobile ? ElementPosition.Bottom : ElementPosition.Left}
              hasBodySeparator={false}
              shadowType={ShadowType.OnlyOpen}
              isShadowOnMouseHover={true}
              style={{marginBottom: 4}}
              bodyStyle={{padding: '0 4px 16px 4px'}}
              initiallyOpened={true}
            >
              {relatedErrors && <div className="d-flex align-items-center">{relatedOnlineReportErrors}</div>}
              {!relatedErrors && <Section>{renderRelatedHorses}</Section>}
            </ExpansionPanel>

            <ExpansionPanel
              headerContent={renderHeader('Find My Herd', FIND_MY_HERD_TOOLTIP)}
              arrowSide={isMobile ? ElementPosition.Bottom : ElementPosition.Left}
              hasBodySeparator={false}
              shadowType={ShadowType.OnlyOpen}
              isShadowOnMouseHover={true}
              bodyStyle={{padding: '0 4px 16px 4px'}}
              initiallyOpened={true}
            >
              {kinshipErrors && <div className="d-flex align-items-center">{kinshipOnlineReportErrors}</div>}
              {!kinshipErrors && <Section>{renderKinshipHorses}</Section>}
            </ExpansionPanel>
          </div>
        )}

        {isPrintable && (
          <PrintableWrapper>
            {!relatedErrors && (
              <>
                <PrintableContainer>
                  <PrintableTitle>Similar Genetic Composition</PrintableTitle>
                  {renderRelatedHorses}
                </PrintableContainer>
              </>
            )}

            {!kinshipErrors && (
              <>
                <PageBreak />
                <PrintableContainer>
                  <PrintableTitle>Find My Herd</PrintableTitle>
                  {renderKinshipHorses}
                </PrintableContainer>
              </>
            )}
          </PrintableWrapper>
        )}

        {isHorseProfile && (
          <div className="d-flex flex-column w-100">
            {!relatedErrors && (
              <>
                <PanelLabel>Horses Like Me Results: Similar Genetic Composition</PanelLabel>
                <BorderedBlock className={'d-flex align-items-center' + (isDesktop ? '' : ' flex-wrap')}>
                  {renderRelatedHorses}
                  {/* Celebrity Horses Like Me - Profile Section START */}
                  {celebrityHorses.length > 0 && (
                    <ColumnContainer
                      columnName={!isDesktop ? 'Celebrity Horse Like Me' : ''}
                      direction={!isDesktop ? Direction.Column : Direction.Row}
                      className="w-100"
                      childrenClassName="justify-content-center align-items-center w-100 flex-grow"
                    >
                      <SimilarCelebrityHorseProfileWrapper id="celeb-wrapper">
                        {isDesktop && <h3>Celebrity Horse Like Me</h3>}
                        <CompositionPie
                          population={celebrityHorses[0].breeds}
                          avatar={celebrityHorses[0].horse?.avatar}
                          title={celebrityHorses[0].horse?.name}
                          size={'default'}
                          profileUrl={
                            celebrityHorses[0].horse?.id
                              ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${celebrityHorses[0].horse?.id}`
                              : ''
                          }
                          relatedHorse={celebrityHorses[0]}
                        />
                      </SimilarCelebrityHorseProfileWrapper>
                    </ColumnContainer>
                  )}
                  {/* Celebrity Horses Like Me - Profile Section END */}
                </BorderedBlock>
              </>
            )}

            {!kinshipErrors && (
              <HorseProfileBlock>
                <PanelLabel>Horses Like Me Results: Find My Herd</PanelLabel>
                <BorderedBlock className="d-flex align-items-center justify-content-center">
                  {renderKinshipHorses}
                </BorderedBlock>
              </HorseProfileBlock>
            )}
          </div>
        )}
      </Root>
    </>
  );
}

export default memo(HorsesLikeMe);
