import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {TextArea} from 'Common/components/Controls';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import {Divider} from 'Common/components/StyledComponents/StyledComponents';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {actions, MessageCenterModule, selectors} from 'MessageCenter/store';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IUserMessageRequest} from 'SignalR/models/IUserMessageRequest';
import {IDeleteProps} from 'SignalR/store/userToUser';
import {convertUserChatMesssageToIMessageQueue, filterMessages} from './converters';
import IncomingMessage from './Messages/IncomingMessage';
import OutgoingMessage from './Messages/OutgoingMessage';
import {MAX_MESSAGE_LENGTH} from 'MessageCenter/constants/MaxMessageLength';
import {useMessageActions} from 'MessageCenter/hooks/useMessageActions';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const textAreaStyle = {minHeight: 48, maxHeight: 96};

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;

  padding: 0 8px;
  position: relative;

  @media ${breakpoints.sm} {
    padding: 0 24px;
  }
`;

const Footer = styled.div`
  width: 100%;
  gap: 16px;

  margin-top: 16px;
`;

const InputMessage = styled(TextArea)`
  width: 100%;
`;

const SendButton = styled(PrimaryButton)`
  min-width: 80px;

  @media ${breakpoints.sm} {
    min-width: 168px;
  }
`;

interface IProps {
  messageQueue: IMessageQueue[];
  currentUser: IChatUser;
  chatUser: IMessageCenterUser;

  onSendMessage(sendMessage: IUserMessageRequest): void;
  deleteMessagesFromQueue(deleteMessage: IDeleteProps): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IProps & IConnected;

function ChatForm(props: Props) {
  const {
    chatUser,
    currentUser,
    onSendMessage,
    messageQueue,
    getUsersChatMessages,
    usersChatMessagesLoading,
    usersChatMessages,
    deleteMessagesFromQueue,
    deleteUserToUserChatMessage,
    userToUserChatMessageDeleting,
  } = props;

  const {user} = chatUser;

  const [message, setMessage] = useState<string>('');
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageQueue[]>([]);
  const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);

  const {isMobile} = useMediaQuery();

  const reloadMessages = useCallback(() => {
    getUsersChatMessages({chatId: chatUser.id});
  }, [chatUser.id, getUsersChatMessages]);

  useEffect(() => {
    setIsScrollToBottom(false);
    reloadMessages();
  }, [reloadMessages]);

  useEffect(() => {
    // Delete messages from queue, because its are in history
    deleteMessagesFromQueue({chatUserId: user.id, currentUserId: currentUser.id});

    const historyMessages = usersChatMessages.map((x) =>
      convertUserChatMesssageToIMessageQueue(x, currentUser.id, user.id)
    );

    setMessages(historyMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersChatMessages]);

  useEffect(() => {
    const newMessages = [
      ...messages,
      ...filterMessages(messageQueue, currentUser.id, user.id),
      ...filterMessages(messageQueue, user.id, currentUser.id),
    ];
    setMessages(newMessages);
    if (newMessages.length > 0) {
      setIsScrollToBottom(true);
    }
    setIsMessageSending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  useEffect(() => {
    // Delete messages from queue, because chat is opened
    const foundId = messageQueue.findIndex(
      (x) =>
        (x.fromUserId === user.id && x.toUserId === currentUser.id) ||
        (x.fromUserId === currentUser.id && x.toUserId === user.id)
    );

    if (foundId !== -1) {
      deleteMessagesFromQueue({chatUserId: user.id, currentUserId: currentUser.id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  const {messageActionsModal, openDeleteMessageConfirmModal} = useMessageActions({
    data: {
      deleteMessageAction: {
        action: deleteUserToUserChatMessage,
        communication: userToUserChatMessageDeleting,
        onSuccess: reloadMessages,
      },
    },
  });

  const onSendClick = useCallback(() => {
    setIsMessageSending(true);
    onSendMessage({toUserId: user.id, message});
    setMessage('');
  }, [message, onSendMessage, user.id]);

  const onInputChange = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    setMessage(value);
  }, []);

  const onKeyPressInput = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault();
        onSendClick();
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `48px`;
      } else {
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
      }
    },
    [onSendClick]
  );

  const isLoading = [usersChatMessagesLoading, userToUserChatMessageDeleting].some((x) => x.isRequesting);

  return (
    <Root className="d-flex flex-column">
      {messageActionsModal}

      {isLoading && <Loading />}

      <Scrollbar
        maxHeight="100%"
        autoHeight={false}
        isScrollToBottom={isScrollToBottom}
        renderView={({style, ...scrollbarProps}) => (
          <div style={{...style, padding: isMobile ? '16px 16px 0 16px' : '16px 24px 0 24px'}} {...scrollbarProps} />
        )}
      >
        {messages.map((message, i) => {
          if (message.fromUserId === user.id && message.toUserId === currentUser.id) {
            return (
              <IncomingMessage
                key={i}
                message={message.message}
                url={user.avatar?.url}
                createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
                onDeleteMessage={() => openDeleteMessageConfirmModal(message.id)}
                isShowActions={true}
              />
            );
          }
          return (
            <OutgoingMessage
              key={i}
              message={message.message}
              url={currentUser.avatar?.url}
              createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
              onDeleteMessage={() => openDeleteMessageConfirmModal(message.id)}
              isShowActions={true}
            />
          );
        })}
      </Scrollbar>

      <Divider />
      <Footer className="ml-auto d-flex">
        <InputMessage
          maxLength={MAX_MESSAGE_LENGTH}
          value={message}
          onChange={onInputChange}
          onKeyPress={onKeyPressInput}
          inputStyle={textAreaStyle}
        />
        <SendButton onClick={onSendClick} isLoading={isMessageSending}>
          Send
        </SendButton>
      </Footer>
    </Root>
  );
}

const mapStateToProps = (state: IAppState) => ({
  usersChatMessagesLoading: selectors.selectCommunication(state, 'usersChatMessagesLoading'),
  usersChatMessages: selectors.selectUsersChatMessages(state),
  userToUserChatMessageDeleting: selectors.selectCommunication(state, 'userToUserChatMessageDeleting'),
});

const mapDispatchToProps = {
  getUsersChatMessages: actions.getUsersChatMessages,
  deleteUserToUserChatMessage: actions.deleteUserToUserChatMessage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ChatForm));
export default withDynamicModules(Connected, MessageCenterModule);
