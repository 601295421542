import {memo} from 'react';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Loading from 'Loading/components/Loading';
import {IMessageCenterUser} from 'MessageCenter/models/IMessageCenterUser';
import {ChatRoot, ChatText} from '../../styled';
import OutgoingMessage from '../Messages/OutgoingMessage';

interface IProps {
  outgoingUserRequest: IMessageCenterUser;
  isLoading: boolean;

  onCloseRequest(chatRequestId: number): void;
}

function OutgoingRequest(props: IProps) {
  const {outgoingUserRequest, onCloseRequest, isLoading} = props;

  const onCloseRequestClick = () => {
    onCloseRequest(outgoingUserRequest.id);
  };

  return (
    <ChatRoot className="d-flex flex-column">
      {isLoading && <Loading />}

      <OutgoingMessage message={outgoingUserRequest.message} url={outgoingUserRequest.user.avatar?.url} />
      <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center">
        <ChatText>You sent request to start conversation. Would you like to cancel the request?</ChatText>

        <div className="d-flex align-items-center">
          <PrimaryButton size="small" onClick={onCloseRequestClick}>
            Cancel request
          </PrimaryButton>
        </div>
      </div>
    </ChatRoot>
  );
}

export default memo(OutgoingRequest);
