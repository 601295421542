import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import OrderPaymentDataService from 'Payment/services/OrderPaymentDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  getPaymentIntentIdActions,
  getPaymentIntentIdActionTypes,
  getOrderSummaryActions,
  getOrderSummaryActionTypes,
  getPayPalOrderActions,
  getPayPalOrderActionTypes,
} from './types';
import {IPayPalOrder, IPayPalOrderRequest} from 'Payment/services/models/payment';

export const getPaymentIntentId =
  (
    token: string,
    captchaToken: string,
    isBusiness?: boolean
  ): ActionResult<IAppState, Promise<void>, getPaymentIntentIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPaymentIntentIdActionTypes.REQUEST));
      const id = await OrderPaymentDataService.getPaymentIntentId(token, captchaToken, isBusiness);
      dispatch(action(getPaymentIntentIdActionTypes.SUCCESS, id));
    } catch (error) {
      dispatch(action(getPaymentIntentIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOrderSummaryByToken =
  (token: string): ActionResult<IAppState, Promise<void>, getOrderSummaryActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOrderSummaryActionTypes.REQUEST));
      const res = await OrderPaymentDataService.getOrderSummaryByToken(token);
      dispatch(action(getOrderSummaryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getOrderSummaryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getPayPalOrder =
  (request: IPayPalOrderRequest): ActionResult<IAppState, Promise<IPayPalOrder>, getPayPalOrderActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPayPalOrderActionTypes.REQUEST));
      const res = await OrderPaymentDataService.getPayPalOrder(request);
      dispatch(action(getPayPalOrderActionTypes.SUCCESS, res));
      return res;
    } catch (error) {
      dispatch(action(getPayPalOrderActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };
