import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/diagnostic/index';
import {
  actions as adminActions,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/index';
import {Nullable} from 'Common/types';
import {OnlineReportType} from '../shared/OnlineReportType';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';

export interface IState {
  coatColor: Nullable<IOnlineReportCoatColor[]>;
  coatColorLoading: ICommunication;
}

export interface IDispatch {
  getCoatColor(horseId: number, orderId?: number): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    coatColor: userSelectors.selectCoatColors(state),
    coatColorLoading: userSelectors.selectCommunication(state, 'coatColorLoading'),
  }),
  dispatch: {
    getCoatColor: userActions.getCoatColor,
  },
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    coatColor: adminSelectors.selectCoatColors(state),
    coatColorLoading: adminSelectors.selectCommunication(state, 'coatColorLoading'),
  }),
  dispatch: {
    getCoatColor: adminActions.getCoatColor,
  },
};

export const coatColorDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: adminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: adminDistributor,
};
