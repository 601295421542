import {combineReducers} from 'redux';

import {IUserMessageCenterState} from '../types';
import {communicationMessageCenterReducer} from './communications';
import {dataMessageCenterReducer} from './data';

export const reducer = combineReducers<IUserMessageCenterState>({
  communications: communicationMessageCenterReducer,
  data: dataMessageCenterReducer,
});
