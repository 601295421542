import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  acceptUsersChatsRequestActionTypes,
  Actions,
  addBlockedUserActionTypes,
  cancelRejectUsersChatsRequestActionTypes,
  closeUsersChatsRequestActionTypes,
  createUserToAdminChatsRequestActionTypes,
  deleteBlockedUserActionTypes,
  deleteUserToUserChatActionTypes,
  deleteUserToUserChatMessageActionTypes,
  getBlockedUsersActionTypes,
  getReceivedUsersChatsRequestsActionTypes,
  getRejectedIncomingUsersChatsActionTypes,
  getRejectedOutgoingUsersChatsActionTypes,
  getUsersChatMessagesActionTypes,
  getUsersChatRequestsActionTypes,
  getUsersChatsActionTypes,
  getUserToAdminChatDetailsActionTypes,
  getUserToAdminChatIsOnlineActionTypes,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatStatusActionTypes,
  IUserMessageCenterState,
  rejectUsersChatsRequestActionTypes,
} from '../types';

export const communicationMessageCenterReducer = combineReducers<IUserMessageCenterState['communications'], Actions>({
  receivedUsersChatsRequestsLoading: makeCommunicationReducerFromEnum(getReceivedUsersChatsRequestsActionTypes),
  usersChatLoading: makeCommunicationReducerFromEnum(getUsersChatsActionTypes),
  usersChatRequestsLoading: makeCommunicationReducerFromEnum(getUsersChatRequestsActionTypes),
  rejectedIncomingUsersChatRequestsLoading: makeCommunicationReducerFromEnum(getRejectedIncomingUsersChatsActionTypes),
  rejectedOutgoingUsersChatRequestsLoading: makeCommunicationReducerFromEnum(getRejectedOutgoingUsersChatsActionTypes),
  usersChatRequestAccepting: makeCommunicationReducerFromEnum(acceptUsersChatsRequestActionTypes),
  usersChatRequestClosing: makeCommunicationReducerFromEnum(closeUsersChatsRequestActionTypes),
  usersChatRequestRejecting: makeCommunicationReducerFromEnum(rejectUsersChatsRequestActionTypes),
  usersChatMessagesLoading: makeCommunicationReducerFromEnum(getUsersChatMessagesActionTypes),
  userToAdminChatMessagesLoading: makeCommunicationReducerFromEnum(getUserToAdminChatMessagesActionTypes),
  usersChatRequestRejectCancelling: makeCommunicationReducerFromEnum(cancelRejectUsersChatsRequestActionTypes),
  userToAdminChatDetailsLoading: makeCommunicationReducerFromEnum(getUserToAdminChatDetailsActionTypes),
  userToAdminChatCreating: makeCommunicationReducerFromEnum(createUserToAdminChatsRequestActionTypes),
  blockedUsersLoading: makeCommunicationReducerFromEnum(getBlockedUsersActionTypes),
  blockedUserAdding: makeCommunicationReducerFromEnum(addBlockedUserActionTypes),
  blockedUserDeleting: makeCommunicationReducerFromEnum(deleteBlockedUserActionTypes),
  userToAdminChatIsOnlineLoading: makeCommunicationReducerFromEnum(getUserToAdminChatIsOnlineActionTypes),
  userToAdminChatStatusLoading: makeCommunicationReducerFromEnum(getUserToAdminChatStatusActionTypes),
  userToUserChatDeleting: makeCommunicationReducerFromEnum(deleteUserToUserChatActionTypes),
  userToUserChatMessageDeleting: makeCommunicationReducerFromEnum(deleteUserToUserChatMessageActionTypes),
});
