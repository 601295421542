import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';

import {IAppState} from 'Common/store/IAppState';
import {OnlineReportActions} from 'OnlineReport/store/diagnostic/index';
import Loading from 'Loading/components/Loading';
import {OnlineReportGroupType} from 'OnlineReport/components/shared/OnlineReportGroupType';
import Group from 'OnlineReport/components/shared/Group';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic/adminOnlineReportModule';
import {OnlineReportModule} from 'OnlineReport/store/diagnostic/onlineReportModule';
import {AdminOnlineReportActions} from 'Admin/AdminDashboard/store/adminOnlineReport/diagnostic';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {healthVariantsDistributor} from './distributor';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IOnlineReportExternalProps;

function HealthVariants(props: AllProps) {
  const {
    phenotypeFilter,
    isPrintable,
    reportType,
    horseId,
    getHealthVariants,
    healthVariants,
    healthVariantsLoading,
    orderId,
  } = props;

  useEffect(() => {
    if (!healthVariants && horseId && !healthVariantsLoading.isRequesting && !isOnlineReportError) {
      getHealthVariants(+horseId, +orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horseId, getHealthVariants, healthVariants]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const {onlineReportErrors, isOnlineReportError} = useOnlineReportErrors({
    horseId: +horseId,
    error: healthVariantsLoading.error,
  });

  if (healthVariantsLoading.isRequesting) {
    return <Loading />;
  }

  return (
    <div>
      {onlineReportErrors}

      {healthVariants
        ?.sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
        .map((section, idx) => {
          return (
            section.healthVariants.length > 0 && (
              <Group
                key={idx}
                sectionId={idx}
                group={section}
                onPrivacyChange={console.log}
                isPrintable={isPrintable}
                phenotypeFilter={phenotypeFilter}
                elements={section.healthVariants}
                type={OnlineReportGroupType.HealthVariant}
                reportType={reportType}
              />
            )
          );
        })}
    </div>
  );
}

const mapStateToProps = (state: IAppState, externalProps: IOnlineReportExternalProps) => {
  const {reportType} = externalProps;

  const distributor = healthVariantsDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, OnlineReportActions | AdminOnlineReportActions>,
  externalProps: IOnlineReportExternalProps
) => {
  const {reportType} = externalProps;

  const distributor = healthVariantsDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(HealthVariants));

export default withDynamicModules(Connected, [AdminOnlineReportModule, OnlineReportModule]);
