import React, {memo, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import {connect, ConnectedProps} from 'react-redux';

import PrintSelectionFormInner from 'OnlineReport/components/PrintSelectionForm/PrintSelectionFormInner';

import {IFormValues, ITraitVisibilities} from './validation';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {OnlineReportType} from '../shared/OnlineReportType';
import {OnlineReportModule} from 'OnlineReport/store/diagnostic/onlineReportModule';
import {UserPrintableReportPreferencesModule} from 'OnlineReportPrintable/store/userPrintableReportPreferences/userPrintableReportPreferencesModule';
import {
  actions as preferencesActions,
  selectors as preferencesSelectors,
  UserPrintableReportPreferencesActions,
} from 'OnlineReportPrintable/store/userPrintableReportPreferences';
import {objectToQueryParams} from 'Common/helpers/objectToQueryParams';
import {IAppState} from 'Common/store/IAppState';
import {AdminReportsModule} from 'Admin/AdminDashboard/store/adminReports/adminReportsModule';
import {composeCommunication} from 'Common/store/utils/communication';
import {getServerFormDelta} from './converters';

interface IExternalProps {
  horseId: number;
  reportType: OnlineReportType;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IExternalProps & IConnected;

const flattenTraitVisibilities = (input: ITraitVisibilities) => {
  const values = Object.entries(input.traits).reduce<number[]>(
    (acc, [key, {isVisible}]) => (isVisible ? [...acc, +key] : acc),
    []
  );

  return values.length === 0 ? false : values;
};

const PrintSelectionForm = (props: AllProps) => {
  const {
    horseId,
    userPrintableReportPreferences,
    updateUserPrintableReportSummaryVisibility,
    hideUserPrintableReportPreferences,
    showUserPrintableReportPreferences,
    ...rest
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSuccess = useCallback(
    async (fields: IFormValues) => {
      const queryData = {
        summary: {
          variantSummary: fields.summary.variantSummary.isVisible,
          abilities: fields.summary.abilities.isVisible,
          coatColors: fields.summary.coatColors.isVisible,
          healthVariants: fields.summary.healthVariants.isVisible,
        },
        partialColors: flattenTraitVisibilities(fields.partialColors),
        aggregatedHealthIssues: flattenTraitVisibilities(fields.aggregatedHealthIssues),
        advancedHealthIssues: flattenTraitVisibilities(fields.advancedHealthIssues),
        aggregatedAbilities: flattenTraitVisibilities(fields.aggregatedAbilities),
        advancedAbilities: flattenTraitVisibilities(fields.advancedAbilities),
      };

      const query = objectToQueryParams(queryData);

      navigate({
        pathname: location.pathname.replace('online-report', 'online-report-printable'),
        search: query,
      });
    },
    [location.pathname, navigate]
  );

  const onSubmit = useCallback(
    async (fields: IFormValues) => {
      if (!fields.rememberChoices) return;

      const {summary, newHidden, newShow} = getServerFormDelta(fields, userPrintableReportPreferences);

      updateUserPrintableReportSummaryVisibility(summary);
      hideUserPrintableReportPreferences(newHidden);
      showUserPrintableReportPreferences(newShow);
    },
    [
      userPrintableReportPreferences,
      updateUserPrintableReportSummaryVisibility,
      hideUserPrintableReportPreferences,
      showUserPrintableReportPreferences,
    ]
  );

  return <PrintSelectionFormInner horseId={horseId} onSubmit={onSubmit} onSuccess={onSuccess} {...rest} />;
};

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => ({
  userPrintableReportPreferences: preferencesSelectors.selectUserPrintableReportPreferences(state),
  updateUserPrintableReportPreferencesCommunication: composeCommunication([
    [preferencesSelectors, 'updateUserPrintableReportSummaryVisibilitySubmitting'],
    [preferencesSelectors, 'hideUserPrintableReportPreferencesSubmitting'],
    [preferencesSelectors, 'showUserPrintableReportPreferencesSubmitting'],
  ]),
});

const userMapDispatchToProps = {
  updateUserPrintableReportSummaryVisibility: preferencesActions.updateUserPrintableReportSummaryVisibility,
  hideUserPrintableReportPreferences: preferencesActions.hideUserPrintableReportPreferences,
  showUserPrintableReportPreferences: preferencesActions.showUserPrintableReportPreferences,
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, UserPrintableReportPreferencesActions>,
  externalProps: IExternalProps
) => bindActionCreators(userMapDispatchToProps, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(PrintSelectionForm));
export default withDynamicModules(Connected, [
  OnlineReportModule,
  AdminReportsModule,
  UserPrintableReportPreferencesModule,
]);
