import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import Typography from 'Common/constants/Typography';
import {IconName} from 'Icon/components/Icon';

const Root = styled.div<{color?: string}>`
  cursor: pointer;
  color: ${(props) => props.color || Theme.color.black};
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-size: ${Typography.size.size14};
  font-weight: ${Typography.weight.medium500};
  gap: 4px;
  :hover * {
    color: ${Theme.color.primary} !important;
    fill: ${Theme.color.primary} !important;
  }
  :active * {
    color: ${Theme.color.control.active} !important;
    fill: ${Theme.color.control.active} !important;
  }
`;

interface IProps {
  label?: string;
  iconProps?: Partial<IColoredIconProps>;
  color?: string;
  onClick?(): void;
}

function BlockButton(props: IProps) {
  const {onClick, label = 'Block user', iconProps, color} = props;

  return (
    <Root className="d-flex align-items-center" color={color} onClick={onClick}>
      <ColoredIcon name={IconName.AccountBlock} stroke={false} fill={true} {...iconProps} />
      <span>{label}</span>
    </Root>
  );
}

export default memo(BlockButton);
