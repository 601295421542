import {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {actions, MessageCenterModule, selectors} from 'MessageCenter/store';
import BlockedUsertem from './BlockedUsertem';

interface IProps {
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;
type OuterProps = IConnected & IProps;
type Props = OuterProps;

function BlockedUsers(props: Props) {
  const {
    blockedUsers,
    addBlockedUser,
    blockedUserDeleting,
    blockedUsersLoading,
    deleteBlockedUser,
    getBlockedUsers,
    resetBlockedUsers,
    blockedUserAdding,
    onSuccess,
  } = props;

  useEffect(() => {
    getBlockedUsers();

    return () => resetBlockedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = [blockedUsersLoading, blockedUserAdding, blockedUserDeleting].some((x) => x.isRequesting);

  return (
    <>
      <ModalWindowHeader>Blocked users</ModalWindowHeader>
      <ModalWindowFormContent
        scrollable={true}
        minHeight={120}
        style={{marginLeft: '-16px', paddingTop: 0}}
        renderView={({style, ...scrollbarProps}) => <div style={{...style, paddingRight: 0}} {...scrollbarProps} />}
      >
        {isLoading && <Loading />}
        {(!blockedUsers || blockedUsers.length === 0) && 'None'}

        {blockedUsers.map((x, ind) => (
          <BlockedUsertem
            key={ind}
            blockedUser={x.target}
            onDelete={deleteBlockedUser}
            onReload={addBlockedUser}
            onSuccess={onSuccess}
          />
        ))}
      </ModalWindowFormContent>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  blockedUsers: selectors.selectBlockedUsers(state),
  blockedUsersLoading: selectors.selectCommunication(state, 'blockedUsersLoading'),
  blockedUserDeleting: selectors.selectCommunication(state, 'blockedUserDeleting'),
  blockedUserAdding: selectors.selectCommunication(state, 'blockedUserAdding'),
});

const mapDispatchToProps = {
  getBlockedUsers: actions.getBlockedUsers,
  addBlockedUser: actions.addBlockedUser,
  deleteBlockedUser: actions.deleteBlockedUser,
  resetBlockedUsers: actions.resetBlockedUsers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(memo(BlockedUsers)), MessageCenterModule);
