import React, {memo} from 'react';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import support from './img/support.svg';
import {breakpoints} from 'Common/constants/Breakpoints';

const Root = styled.div`
  margin: 6px 0;

  @media ${breakpoints.sm} {
    margin: 8px 0;
  }
`;

const UserAvatar = styled(Avatar)`
  margin: 0 8px;
`;

const UserName = styled.span`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  color: ${ColorPalette.black1};
`;

interface IProps {
  user: IChatUser;
  isOnline: boolean;
  isSupport?: boolean;
}

function ChatUser(props: IProps) {
  const {user, isOnline, isSupport = false} = props;

  return (
    <Root className="d-flex">
      <UserAvatar
        avatarSize={AvatarSize.Custom}
        customSizeAvatar={40}
        customSizeIcon={24}
        defaultAvatarProfile={DefaultAvatarProfile.User}
        url={isSupport ? support : user.avatar?.url}
        isOnline={isOnline}
      />
      <UserName className="align-self-center">{user.name}</UserName>
    </Root>
  );
}

export default memo(ChatUser);
